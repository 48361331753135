import Attachment from '@icons/attachment.svg'
import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { IMetaValue } from 'src/document/types/IMetaData'
import history from 'src/history'
import { useFreezePlan } from 'src/query/planning/freezePlan'
import { filterDelivery } from 'src/service/DeliveryService'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import { getProjectMilestones } from 'src/service/MilestoneService'
import { IDelivery } from 'src/service/OrgTypes'
import {
  getDependentKeyPoints,
  getDependentOnKeyPoints,
  getKeypointDetails,
  getProjectProcesses,
  IModalOrigin,
  IProjectKeypointResponse,
  removeKeypointDependency,
  updateKeypoint,
} from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getErrorMessage,
  getKeyPointErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter, classNames } from 'src/utility/utils'
import InspectorSections, {
  IInspectorSection,
} from '../../document/components/Inspector/InspectorSections'
import { useSystemTypeGroups } from '../../query/systemTypeGroups'
import FixedPane from '../../ui-elements/fixed-pane/FixedPane'
import CopyUrl from '../copy/CopyUrl'
import ImprovementPanel from '../improvement/improvement-panel/ImprovementPanel'
import AttachmentsInspectorSectionTableWithUpload from '../inspector-section-tables/AttachmentsInspectorSectionTableWithUpload'
import MilestoneInspectorPanel from '../milestones/MilestoneInspectorPanel'
import DeliveryInspectorPanel from '../process/delivery/DeliveryInspectorPanel'
import KeypointChangeLogModal from '../process/main-process/KeypointChangeLogModal'
import QrModal from '../qr/QrModal'
import { getMetaDataValues, loadMetaValues } from '../system/SystemUtil'

interface IKeypointInspectorPanel {
  keyPointId: number
  open: boolean
  onClose: () => void
  origin: IModalOrigin
  notFound?: (id: number) => void
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  onUpdate?: () => void
  defaultIndex?: number
  readonly?: boolean
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
}

const KeypointInspectorPanel: React.FC<IKeypointInspectorPanel> = ({
  keyPointId,
  open,
  onClose,
  notFound,
  origin,
  onOpenItem,
  onUpdate,
  defaultIndex = 0,
  readonly,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [keypointData, setKeypointData] = useState<IProjectKeypointResponse>()
  const [dependentOnKeypoints, setDependentOnKeypoints] = useState<
    IProjectKeypointResponse[]
  >([])
  const [dependentKeypoints, setDependentKeypoints] = useState<
    IProjectKeypointResponse[]
  >([])
  const [deliveries, setDeliveries] = useState<IDelivery[]>([])
  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<IProjectKeypointResponse>(
      setKeypointData,
      keypointData,
    )
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id
  const { isBaselineAdmin } = projectContext.state
  const { addAlert } = useAlert()
  const [qrString, setQrString] = useState('')
  const [showQrModal, setShowQrModal] = useState(false)
  const [showImprovementPanel, setShowImprovementPanel] = useState(false)
  const [changeLogModal, setChangeLogModal] = useState(false)
  const [changeLogData, setChangeLogData] = useState<any>('')
  const [showDeliveryModal, setShowDeliveryModal] = useState(false)
  const [selectedDeliveryId, setSelectedDeliveryId] = useState<
    number | undefined
  >(undefined)
  const [showKeypointModal, setShowKeypointModal] = useState(false)
  const [selectedKeypointId, setSelectedKeypointId] = useState(0)
  const endDateRef = useRef(moment())
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [showMilestoneInspector, setShowMilestoneInspector] = useState(false)

  const parentMilestoneId = useMemo(() => {
    if (keypointData?.mile_stone_id) {
      return keypointData.mile_stone_id
    }
    return null
  }, [keypointData])

  const { data: systemTypeGroup } = useSystemTypeGroups('KeyPoint')
  const { data: planFreezeDuration } = useFreezePlan('key_point')
  const loadKeypoint = () => {
    getKeypointDetails(keyPointId, projectId).then((res) => {
      if (res.status === 404 && notFound) {
        notFound(keyPointId)
        return
      }
      setKeypointData(res)
      setDependentOnKeypoints(
        res.dependent_key_points
          ? res.dependent_key_points.map(
              (dependentKeypoint: any) =>
                dependentKeypoint.dependent_on_key_point,
            )
          : [],
      )
      if (systemTypeGroup) {
        const metaData = getMetaDataValues(res.meta_data)
        setOptionalFields(
          loadMetaValues(
            keyPointId,
            'KeyPoint',
            systemTypeGroup?.optional_fields,
            metaData,
          ),
        )
      }
      setLoading(false)
    })
  }

  const loadDependentKeyPoints = () => {
    getDependentKeyPoints(projectId, keyPointId).then((res) => {
      setDependentKeypoints(res)
    })
  }

  const loadDependentOnKeyPoints = () => {
    getDependentOnKeyPoints(projectId, keyPointId).then((res) => {
      setDependentOnKeypoints(res)
    })
  }

  const loadDeliveries = () => {
    const activeFilters: Parameters<typeof filterDelivery>[1] = {}
    activeFilters['key_point'] = [keyPointId]

    filterDelivery(projectId, activeFilters, 1, 50).then((res) => {
      setDeliveries(res.items)
    })
  }

  const removeDependency = async (id: number) => {
    await removeKeypointDependency(keyPointId, {
      dependent_on_key_point: [id],
    })
    loadDependentOnKeyPoints()
  }

  const onDeliveryClick = (id: number) => {
    setSelectedDeliveryId(id)
    setShowDeliveryModal(true)
  }

  const onKeypointClick = (id: number) => {
    setSelectedKeypointId(id)
    setShowKeypointModal(true)
  }

  const getRows = (): IInspectorSection[] => [
    {
      name: t('details'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: getMainContent(),
      onClick: loadKeypoint,
    },
    {
      name: t('additional_information'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      hidden: optionalFields?.length === 0,
      content: getMetaDataContent(),
      onClick: loadKeypoint,
      overflowVisible: true,
    },
    {
      name: capFirstLetter(t('incoming_dependencies')),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      onClick: loadDependentOnKeyPoints,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('title'),
            t('status'),
            t('deadline'),
            t('responsible'),
          ]}
          rowsData={dependentOnKeypoints?.map((dependentKeypoint) => {
            return {
              cells: [
                dependentKeypoint?.record_id,
                dependentKeypoint?.name,
                t(dependentKeypoint?.status ?? ''),
                dependentKeypoint?.endTime
                  ? moment(dependentKeypoint.endTime).format('L')
                  : '',
                dependentKeypoint?.responsible
                  ? `${dependentKeypoint?.responsible?.firstName} ${dependentKeypoint?.responsible?.lastName}`
                  : '',
              ],
              id: dependentKeypoint?.id,
            }
          })}
          handleClick={onKeypointClick}
          actionMenu={{
            icon: 'delete',
            onClick: removeDependency,
            title: t('delete'),
            className: 'text-red-500',
          }}
        />
      ),
    },
    {
      name: capFirstLetter(t('outgoing_dependencies')),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      onClick: loadDependentKeyPoints,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('title'),
            t('status'),
            t('deadline'),
            t('responsible'),
          ]}
          rowsData={dependentKeypoints?.map((dependentKeypoint) => {
            return {
              cells: [
                dependentKeypoint?.record_id,
                dependentKeypoint?.name,
                t(dependentKeypoint?.status),
                dependentKeypoint?.endTime
                  ? moment(dependentKeypoint.endTime).format('L')
                  : '',
                dependentKeypoint?.responsible
                  ? `${dependentKeypoint?.responsible?.firstName} ${dependentKeypoint?.responsible?.lastName}`
                  : '',
              ],
              id: dependentKeypoint?.id,
            }
          })}
          handleClick={onKeypointClick}
          actionMenu={{
            icon: 'delete',
            onClick: removeDependency,
            title: t('delete'),
            className: 'text-red-500',
          }}
        />
      ),
    },
    {
      name: capFirstLetter(t('deliveries')),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      onClick: loadDeliveries,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('title'),
            t('status'),
            t('deadline'),
            t('responsible'),
          ]}
          rowsData={deliveries?.map((delivery) => {
            return {
              cells: [
                delivery?.record_id,
                delivery?.name,
                t(delivery.status),
                delivery?.endTime ? moment(delivery.endTime).format('L') : '',
                delivery?.responsible
                  ? `${delivery?.responsible?.firstName} ${delivery?.responsible?.lastName}`
                  : '',
              ],
              id: delivery.id,
            }
          })}
          handleClick={onDeliveryClick}
        />
      ),
    },
    {
      name: capFirstLetter(t('attachments')),
      icon: <Attachment />,
      content: (
        <AttachmentsInspectorSectionTableWithUpload
          parentId={keyPointId}
          parentType="KeyPoint"
          readonly={false}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={keyPointId} parentType="KeyPoint" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={keyPointId} parentType="KeyPoint" />,
    },
  ]

  const onChangeInput = (update: Partial<IProjectKeypointResponse>) => {
    const freezeUntil =
      planFreezeDuration > 0
        ? moment()
            .startOf('week')
            .add(planFreezeDuration - 1, 'weeks')
            .endOf('week')
        : moment().startOf('day')
    const movedToTheFuture: boolean = moment(update?.endTime).isAfter(
      moment(keypointData?.endTime),
      'day',
    )
    if (
      !!update.endTime &&
      movedToTheFuture &&
      moment(keypointData?.endTime).isBefore(freezeUntil, 'day')
    ) {
      setChangeLogModal(true)
      endDateRef.current = moment(update.endTime)
    } else {
      const allUpdates = getChangesForUpdate(update)
      updateKeypoint(keyPointId, {
        ...convertUndefinedToNull(allUpdates),
        id: keypointData?.id,
      }).then(() => {
        onKeypointUpdate()
      })
    }
  }

  const onKeypointUpdate = () => {
    loadKeypoint()
    onUpdate?.()
  }

  const onChangeLogSubmit = () => {
    toggleChangelogModal()
    updateKeypoint(keyPointId, {
      id: keypointData?.id,
      endTime: endDateRef.current,
      change_reason: changeLogData,
    }).then(() => {
      loadKeypoint()
      onUpdate?.()
    })
  }

  const onChangelogReasonChange = (comment: string) => {
    setChangeLogData(comment)
  }

  const toggleChangelogModal = () => {
    setChangeLogModal(!changeLogModal)
  }

  const isEndTimeValid = (endTimeValue: moment.Moment) => {
    if (!endTimeValue || !moment(endTimeValue).isValid()) {
      return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
    }

    if (endTimeValue) {
      if (keypointData?.mile_stone_id && keypointData?.mile_stone) {
        if (
          moment(endTimeValue).isAfter(
            moment(keypointData?.mile_stone?.deadline),
            'day',
          )
        ) {
          return getKeyPointErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE,
            t,
            moment(keypointData?.mile_stone?.deadline).format('L'),
          )
        }
      }

      if (keypointData?.improvement) {
        if (
          moment(endTimeValue).isAfter(
            moment(keypointData?.improvement.deadline),
            'day',
          )
        ) {
          return getKeyPointErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
            moment(keypointData.improvement.deadline).format('L'),
          )
        }
      }
    }
  }

  const showAlart = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const onStatusSelect = () => {
    if (!keypointData?.update_access) {
      showAlart('error', t('access_limited'), t('do_not_have_access_to_edit'))
      return
    }

    if (keypointData?.open_children && keypointData.open_children > 0) {
      showAlart(
        'error',
        t('an_error_occurred'),
        getKeyPointErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    const updatedKeypoint = { ...keypointData, status: 'done' }
    updateKeypoint(updatedKeypoint.id, updatedKeypoint).then(() => {
      loadKeypoint()
    })
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const { currentProject } = projectContext.state
    const qrString =
      url +
      '?modal=keypoint&id=' +
      keypointData?.id +
      '&project=' +
      currentProject.id
    setQrString(qrString)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setShowQrModal(true)
  }

  const toggleImprovementPanel = () => {
    setShowImprovementPanel(!showImprovementPanel)
  }

  const extraButtons = () => {
    return (
      <div className="flex flex-row justify-end items-center">
        {parentMilestoneId && (
          <Button
            type={ButtonType.DEFAULT}
            size={Button.ButtonSize.SMALL}
            onClick={() => setShowMilestoneInspector((prev) => !prev)}
          >
            {capFirstLetter(t('show_milestone_details'))}
          </Button>
        )}
        {keypointData?.status && keypointData?.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {capFirstLetter(t('done'))}
          </Button>
        )}
        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <CopyUrl
            params={{
              modal: 'keypoint',
              id: keyPointId,
            }}
          />
        </span>
        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <a title={t('generate_qr_code')} className="flex items-center">
            <Icon
              style={{ width: 20, height: 20 }}
              icon={Icon.IconType.QR}
              onClick={toggleQrModal}
            />
          </a>
        </span>
        <Link
          to={`/main-process/keypoint/${keyPointId}?rootUrl=${
            origin.url
          }&rootName=${
            origin.name
          }&showHome=${!!origin.showHome}${addProjectToUrl()}`}
        >
          <Button size={Button.ButtonSize.SMALL}>
            {t('view_or_plan_deliveries')}
          </Button>
        </Link>
        {keypointData?.improvement_id ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={toggleImprovementPanel}
          >
            {t('see_improvement_measures')}
          </Button>
        ) : null}
      </div>
    )
  }

  const addProjectToUrl = (): string => {
    const params = new URLSearchParams(history.location.search)
    if (params.has('project')) {
      return `&project=${params.get('project')}`
    }
    return ''
  }

  const getModalTitle = (): string => {
    return keypointData?.record_id
      ? keypointData.record_id + ' - ' + keypointData.name
      : 'Loading...'
  }

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4 -mt-2'}>
      {optionalFields.length > 0 && (
        <MetaDataFieldsForPage
          onFieldsUpdate={onKeypointUpdate}
          fields={optionalFields}
          required={false}
          editMode={false}
          inspectorPanel={true}
        />
      )}
    </div>
  )

  const getMainContent = () => {
    return (
      <InlineComponentsWrapper
        padding="left"
        border={undefined}
        loading={loading}
        inputWidth="w-[480px]"
      >
        <TextInlineInputCompontent
          disabled={readonly}
          label={t('title')}
          value={keypointData?.name}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ name: newValue })
          }}
          validate={(value) => {
            if (value === undefined || value === '')
              return getErrorMessage(ValidationError.MISSING_TITLE, t)
            return
          }}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          items={statusTypes(t)}
          label={t('status')}
          getItemLabel={(stat) => stat?.name}
          initialItem={{
            id: keypointData?.status ?? '',
            name:
              statusTypes(t).find(
                (statData) => statData.id === keypointData?.status,
              )?.name ?? '',
          }}
          validate={(value) => {
            if (value === undefined)
              return getErrorMessage(ValidationError.MISSING_STATUS, t)

            if (keypointData?.open_children && value === 'done') {
              return getKeyPointErrorMessage(
                StructureValidationError.HAS_OPEN_CHILDREN,
                t,
              )
            }
            if (
              value &&
              keypointData?.mile_stone &&
              keypointData.mile_stone.status === 'done' &&
              value !== 'done'
            ) {
              return getKeyPointErrorMessage(
                StructureValidationError.PARENT_IS_CLOSED,
                t,
              )
            }
            return
          }}
          selectedId={keypointData?.status}
          onValueSubmitted={(stat) => {
            onChangeInput({ status: stat })
          }}
          inspectorPanel={true}
        />

        <DateTimeInlineInputComponent
          disabled={readonly}
          label={t('deadline')}
          selectedTime={keypointData?.endTime}
          onValueSubmitted={(deadline) => {
            onChangeInput({ endTime: deadline })
          }}
          validate={(value) => {
            if (value) {
              return isEndTimeValid(value)
            }
            return
          }}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="closed_date"
          selectedTime={keypointData?.closed_date}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          disabled={!isBaselineAdmin}
          label={t('baseline_date')}
          selectedTime={keypointData?.baseline}
          onValueSubmitted={(baselinedate) => {
            onChangeInput({ baseline: baselinedate })
          }}
          inspectorPanel={true}
        />

        <TextInlineInputCompontent
          disabled={readonly}
          label={t('duration_days')}
          value={`${keypointData?.duration ?? 0}`}
          onValueSubmitted={(newValue) => {
            if (newValue)
              onChangeInput({ duration: parseInt(`${newValue}`, 10) })
          }}
          validate={(newValue) => {
            if (newValue?.length && isNaN(+newValue)) {
              return t('must_be_a_number')
            }
            return undefined
          }}
        />
        <TextInlineInputCompontent
          label={t('delay_days')}
          disabled={true}
          value={`${keypointData?.delay ?? 0}`}
        />
        <TextInlineInputCompontent
          label={t('deliveries')}
          disabled={true}
          value={`${keypointData?.done_children || 0}/${
            (keypointData?.open_children || 0) +
            (keypointData?.done_children || 0)
          }`}
        />
        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() => getProjectMilestones(projectId)}
          label={t('milestone')}
          getItemLabel={(milestone) =>
            `${milestone?.record_id} - ${milestone?.name}`
          }
          initialItem={keypointData?.mile_stone}
          selectedId={keypointData?.mile_stone_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ mile_stone_id: val })
          }}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() => getProjectDisciplines(projectId)}
          label="discipline"
          initialItem={keypointData?.discipline}
          getItemLabel={(discipline) =>
            `${discipline?.shortName} - ${discipline?.name}`
          }
          validate={(value) => {
            if (value === undefined)
              return t('fill_out_w_param', {
                param: t('discipline'),
              })
            return
          }}
          selectedId={keypointData?.discipline_id}
          onValueSubmitted={(discipline_id) => {
            addChangesToAppendWhenKeyUpdates('responsible_id', {
              discipline_id,
            })
          }}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() =>
            keypointData?.discipline_id
              ? getDisplineUsers(keypointData?.discipline_id)
              : getProjectUsersWithDisciplines(projectId)
          }
          label="responsible"
          getItemLabel={(responsible) =>
            `${responsible?.firstName} ${responsible?.lastName}`
          }
          initialItem={keypointData?.responsible}
          validate={(value) => {
            if (value === undefined)
              return t('fill_out_w_param', {
                param: t('responsible'),
              })
            return
          }}
          selectedId={keypointData?.responsible_id ?? 0}
          onValueSubmitted={(responsible_id) => {
            onChangeInput({ responsible_id })
          }}
          dependencies={[keypointData?.discipline_id]}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          label={'contract'}
          disabled={true}
          selectedId={keypointData?.contract_id ?? ''}
          getItemLabel={(contract) =>
            `${contract?.contractNumber} - ${contract?.contractName}`
          }
          initialItem={keypointData?.contract}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() => getProjectProcesses(projectId)}
          label={t('main_process')}
          getItemLabel={(process) => `${process?.record_id} - ${process?.name}`}
          initialItem={keypointData?.main_process}
          validate={(value) => {
            if (!value || parseInt(`${value}`, 10) === 0) {
              return getErrorMessage(ValidationError.MISSING_MAIN_PROCESS, t)
            }
            return
          }}
          selectedId={keypointData?.main_process_id}
          onValueSubmitted={(val) => {
            onChangeInput({ main_process_id: val, team_id: undefined })
          }}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() =>
            keypointData?.main_process_id
              ? getMainprocessTeams(keypointData?.main_process_id)
              : getProjectTeams(projectId)
          }
          label={t('team')}
          getItemLabel={(team) => team?.name}
          initialItem={keypointData?.team}
          selectedId={keypointData?.team_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ team_id: val })
          }}
          cancelButton={true}
          dependencies={[keypointData?.main_process_id]}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() => getProjectTags(projectId)}
          label={t('type')}
          getItemLabel={(tag) => tag?.name}
          initialItem={keypointData?.tag}
          selectedId={keypointData?.tag_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ tag_id: val })
          }}
          cancelButton={true}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() =>
            getFilteredProjectImprovementsWithPagination(projectId).then(
              (res) => res.improvements,
            )
          }
          label={t('improvement')}
          getItemLabel={(improvement) => improvement?.title}
          initialItem={keypointData?.improvement}
          selectedId={keypointData?.improvement_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ improvement_id: val })
          }}
          cancelButton={true}
          inspectorPanel={true}
        />

        <TextInlineInputCompontent
          disabled={readonly}
          label={t('description')}
          textArea={true}
          value={keypointData?.description}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ description: newValue })
          }}
        />

        <TextInlineInputCompontent
          disabled={readonly}
          label={t('assumptions')}
          textArea={true}
          value={keypointData?.assumptions}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ assumptions: newValue })
          }}
        />
        <TextInlineInputCompontent
          label={t('reporter')}
          value={`${keypointData?.user?.firstName} ${keypointData?.user?.lastName}`}
          disabled={true}
        />
        <DateTimeInlineInputComponent
          label="created_at"
          selectedTime={`${keypointData?.created_at}`}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="updated_at"
          selectedTime={`${keypointData?.updated_at}`}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
      </InlineComponentsWrapper>
    )
  }
  return (
    <>
      <FixedPane
        title={
          keypointData?.name
            ? `${keypointData?.record_id} - ${keypointData?.name}`
            : t('loading...')
        }
        show={open}
        onClose={() => onClose()}
        className={'w-[700px]'}
        disableOutsideClose={true}
        detailPageData={{
          key: DetailPageKeys.KEYPOINT,
          ids: { keypointId: keyPointId },
        }}
      >
        <div className={styleClass.root}>
          {!readonly && extraButtons()}
          <InspectorSections defaultIndex={defaultIndex} sections={getRows()} />
          {showQrModal && (
            <QrModal
              show={showQrModal}
              close={() => setShowQrModal(false)}
              title={getModalTitle()}
              value={qrString}
            />
          )}
          {showImprovementPanel && keypointData?.improvement_id && (
            <ImprovementPanel
              projectId={projectContext.state.currentProject.id}
              improvementId={keypointData?.improvement_id}
              onClose={toggleImprovementPanel}
              show={showImprovementPanel}
            />
          )}
          {changeLogModal && (
            <KeypointChangeLogModal
              show={changeLogModal}
              toggleChangeLogModal={toggleChangelogModal}
              onChangeLogSubmit={onChangeLogSubmit}
              onChangeReasonLog={onChangelogReasonChange}
            />
          )}
        </div>
      </FixedPane>
      {selectedDeliveryId && showDeliveryModal && (
        <DeliveryInspectorPanel
          readonly={readonly}
          deliveryId={selectedDeliveryId}
          open={showDeliveryModal}
          onClose={() => {
            setSelectedDeliveryId(0)
            setShowDeliveryModal(false)
          }}
          projectId={projectId}
          onUpdate={onUpdate}
        />
      )}
      {selectedKeypointId && showKeypointModal && (
        <KeypointInspectorPanel
          readonly={readonly}
          keyPointId={selectedKeypointId}
          open={showKeypointModal}
          onClose={() => {
            setShowKeypointModal(false)
            setSelectedKeypointId(0)
          }}
          origin={origin}
          onOpenItem={onOpenItem}
          onUpdate={onUpdate}
        />
      )}
      {showMilestoneInspector && parentMilestoneId && (
        <MilestoneInspectorPanel
          readonly={readonly}
          milestoneId={parentMilestoneId}
          origin={{
            name: t('visual_overview_by_discipline'),
            url: '/milestone?tabIndex=2',
          }}
          open={showMilestoneInspector}
          onClose={() => {
            setShowMilestoneInspector(false)
          }}
          onUpdate={onUpdate}
        />
      )}
    </>
  )
}
export default KeypointInspectorPanel
