import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ITimelineItem,
  typeHasDependencies,
  typeIsTopLevel,
} from 'src/components/timeline/types'
import { classNames } from 'src/utility/utils'
import ContextMenu, { IContextMenuItem } from '../context-menu/ContextMenu'
import useLongPress from '../hooks/onLongPress'
import GantTimelineItemIcon from './GantTimelineItemIcon'

export interface IGanttTimelineItemProps {
  getItemProps: any
  item: ITimelineItem
  openModal: (data: ITimelineItem, timelineContext: any) => void
  showDependentOn: (parent: ITimelineItem) => void
  itemContext: any
  timelineContext: any
  showChildren?: (item: ITimelineItem) => void
}

const styleClass = {
  item: classNames(
    'flex',
    'justify-center',
    'overflow-visible',
    'cursor-pointer',
    'flex-col',
    'whitespace-nowrap',
    'h-full',
  ),
  itemTextRow: classNames(
    'flex',
    'cursor-pointer',
    'no-underline',
    'leading-4',
    'justify-end',
  ),
  itemProgressRow: classNames('flex', 'cursor-pointer', 'h-2/4'),
  floatLabel: classNames(
    'fixed',
    'bg-white',
    'text-sm',
    'shadow',
    'p-3',
    'flex',
    'z-30',
    'flex-col',
  ),
  floatLabelSmallText: classNames('text-xxs', 'py-1'),
}

export const planningTypeKey = {
  MainProcess: 'P',
  MileStone: 'M',
  KeyPoint: 'K',
  Delivery: 'D',
  Task: 'T',
}

const GanttTimelineItem = ({
  getItemProps,
  item,
  openModal,
  timelineContext,
  showDependentOn,
  showChildren,
}: IGanttTimelineItemProps) => {
  const { t } = useTranslation()
  const { done_children: doneChildren, open_children: openChildren } = item
  const longPress = useLongPress((e: any) => showMenu(e))
  const [showLabel, setShowLabel] = useState<boolean>(false)
  const [floatLabel, setFloatLabel] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  })
  const totalChildren = doneChildren + openChildren

  const hasDependencies = item.type && typeHasDependencies(item.type)

  const childrenStatus = hasDependencies
    ? `(${doneChildren}/${totalChildren})`
    : ''

  const showMenu = (e: any) => {
    const target = e.target.getBoundingClientRect()
    const tmpFloatLabel = { ...floatLabel }
    tmpFloatLabel.left = target.right
    tmpFloatLabel.top = target.top
    setShowLabel(true)
    setFloatLabel(tmpFloatLabel)
  }

  const dependencyContextItems = [
    {
      title: t('view_all_dependency_items', {
        param: `${Number(item.dependent_on?.length + item.dependent?.length)}`,
      }),
      active: !!item.dependent_on?.length || !!item.dependent?.length,
      onItemClick: () => showDependentOn(item),
      disableMessage: t('no_items_found'),
    },
  ]

  const contextMenu: IContextMenuItem[] = [
    {
      title: t('underlying_elements'),
      active: totalChildren > 0,
      onItemClick: () => showChildren?.(item),
      disableMessage: t('the_element_has_no_underlying_elements'),
    },
    ...(hasDependencies ? dependencyContextItems : []),
  ]

  const st = moment(timelineContext.visibleTimeStart)

  const showDuration = item.type && typeIsTopLevel(item.type)

  return (
    <>
      <div className={'flex flex-col'}>
        <div
          {...getItemProps({
            style: {
              backgroundColor: 'transparent',
              color: item.color,
              zIndex: 15,
              borderStyle: 'solid',
              minWidth: '20px',
              minHeight: '20px',
              border: `.01px solid transparent`,
              background: 'transparent',
              borderWidth: 0,
            },
          })}
        >
          <div
            onContextMenu={showMenu}
            onDoubleClick={() => openModal(item, timelineContext)}
            {...longPress}
            className={styleClass.item}
          >
            {showDuration ? (
              <>
                <div
                  className={classNames(
                    item.type === 'Project'
                      ? 'h-1/2 bg-sky-600'
                      : 'h-1/4 bg-sky-500',
                    'flex',
                  )}
                />
                {item.type !== 'Project' && (
                  <span className="absolute top-[-0.6rem]">
                    {item.record_id}
                  </span>
                )}
              </>
            ) : (
              <div className="flex cursor-pointer no-underline leading-4 justify-end absolute right-0">
                <span title={item.name} className="cursor-pointer">
                  {item.record_id} {childrenStatus}
                </span>
                <div
                  id={item.id}
                  className={classNames(item.type !== 'KeyPoint' && 'pt-1')}
                >
                  <GantTimelineItemIcon date={st} item={item} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {
        <ContextMenu
          show={showLabel}
          pos={floatLabel}
          menus={contextMenu}
          closeMenu={() => setShowLabel(false)}
        />
      }
    </>
  )
}

export default GanttTimelineItem
