import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DisciplinesColumn,
  SystemStatusColumn,
  SystemTypeColumn,
  TestSystemGroupsColumn,
  TestWorkGroupsColumn,
} from 'src/components/TableColumns/Columns'
import { ContractsColumn } from 'src/components/TableColumns/DisciplineResponsibleColumns'
import { IPaginateResult, ISystem } from 'src/service/OrgTypes'
import { getSystemsWithPage } from 'src/service/SystemService'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import {
  filterType,
  IListColumns,
  ISorting,
} from 'src/ui-elements/list/ListTypes'
import {
  classNames,
  constructFilterJson,
  IActiveFilter,
} from 'src/utility/utils'
import SystemInspectorPanel from '../../components/system/SystemInspectorPanel'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import Modal from '../../ui-elements/modal/Modal'
import ModalFooter from '../../ui-elements/modal/ModalFooter'

export enum Target {
  YES = 'yes',
  NO = 'no',
}

interface ISelectSystemsListProps {
  projectId: number
  title?: string
  parentSystemId?: number
  testSystemGroupId?: number
  selectSystemIds?: number[]
  onSelectSystems?: (ids: number[]) => void
  closeModal: () => void
  systemStatusId?: number
  systemCategoryId?: number
  disciplineId?: number
  requirementInfoId?: string
  targetSystem?: Target
}

const SelectSystemsList: FC<ISelectSystemsListProps> = ({
  projectId,
  title,
  parentSystemId,
  testSystemGroupId,
  selectSystemIds,
  onSelectSystems,
  closeModal,
  systemStatusId,
  systemCategoryId,
  disciplineId,
  requirementInfoId,
  targetSystem,
}) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'p-2', 'flex-col', 'min-h-[700px]'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  const [systems, setSystems] = useState<ISystem[]>([])
  const [selectedSystem, setSelectedSystem] = useState<ISystem | undefined>(
    undefined,
  )
  const [pages, setPages] = useState<number>(1)
  const [showInspector, setShowInspector] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [selectedSystems, setSelectedSystems] = useState<number[]>([
    ...(selectSystemIds ?? []),
  ])

  const { t } = useTranslation()

  const pageSize = 20

  useEffect(() => {
    setReload((n) => !n)
  }, [projectId, parentSystemId])

  const handleSelectedSystems = (ids: number[]) => {
    setSelectedSystems(ids)
  }

  const handlePreviewClick = (e: any, data: ISystem) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedSystem(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedSystem(undefined)
    setReload((n) => !n)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    if (parentSystemId) {
      activeFilters['parent_system'] = []
      activeFilters['parent_system'].push(parentSystemId)
    }
    if (testSystemGroupId) {
      activeFilters['test_system_group'] = []
      activeFilters['test_system_group'].push(testSystemGroupId)
    }
    if (systemStatusId) {
      activeFilters['system_status'] = []
      activeFilters['system_status'].push(`${systemStatusId}`)
    }
    if (systemCategoryId) {
      activeFilters['system_syntax_category'] = []
      activeFilters['system_syntax_category'].push(`${systemCategoryId}`)
    }
    if (disciplineId) {
      activeFilters['discipline'] = []
      activeFilters['discipline'].push(`${disciplineId}`)
    }

    if (requirementInfoId) {
      activeFilters['requirement_info_ids'] = []
      activeFilters['requirement_info_ids'].push(requirementInfoId)
    }
    filterSystems(activeFilters, page)
  }

  const filterSystems = (activeFilters: IActiveFilter, page: number) => {
    getSystemsWithPage(projectId, page, pageSize, activeFilters).then(
      (res: IPaginateResult<ISystem>) => {
        setSystems(res.items)
        setPages(res.pages)
      },
    )
  }

  const columns: IListColumns[] = [
    {
      name: 'system_number',
      id: 'recordId',
      size: '150',
      dataField: 'record_id||has_children',
      sortingField: 'record_id',
      filter: [],
      filterType: filterType.TEXT,
      cell: (data: any) => (
        <>
          <MaterialIcon
            icon={data.has_children ? 'workspaces' : 'circle'}
            className={
              data.has_children
                ? 'text-d-blue material-icons-outlined text-base h-6 w-6 mt-1'
                : 'text-d-blue material-icons-outlined text-xxs h-2 w-2 mr-3'
            }
          />
          <span className={data.has_children ? 'font-bold' : ''}>
            {data.record_id}
          </span>
        </>
      ),
    },
    {
      name: 'system_name',
      id: 'name',
      size: '250',
      dataField: 'name',
      sortingField: 'name',
      filter: [],
      filterType: filterType.TEXT,
    },
    SystemTypeColumn(),
    SystemStatusColumn(),
    {
      name: 'location',
      id: 'location',
      size: '200',
      dataField: 'location',
      sortingField: 'location',
      filter: [],
      filterType: filterType.TEXT,
    },
    TestSystemGroupsColumn(() => {}, true),
    TestWorkGroupsColumn(!testSystemGroupId),
    DisciplinesColumn(),
    ContractsColumn(),
  ]

  const onSubmit = (e: any) => {
    e.preventDefault()
    onSelectSystems?.(selectedSystems)
  }

  return (
    <Modal
      show={true}
      title={
        title ??
        t('select_items_you_want_to_add_w_param', {
          param:
            targetSystem === 'yes'
              ? t('target_systems')
              : targetSystem === 'no'
                ? t('supporting_systems')
                : t('systems').toLowerCase(),
        })
      }
      closeModal={closeModal}
      size={'w-9/10'}
      minHeight={'800px'}
      maxWidth={1700}
    >
      <div className={styleClass.root}>
        <List
          columns={columns}
          data={systems}
          tableName={'SystemsSelectorList'}
          itemsPerPage={pageSize}
          pagination={true}
          totalPages={pages}
          reload={reload}
          selectedRows={selectedSystems}
          filterResetOption={true}
          sortBackend={true}
          onSelectRow={handleSelectedSystems}
          getFilteredData={getFilteredData}
          isRowSelectable={true}
          onPreviewClick={handlePreviewClick}
        />

        {selectedSystem && showInspector && (
          <SystemInspectorPanel
            onUpdate={() => setReload((n) => !n)}
            systemId={selectedSystem.id}
            open={showInspector}
            onClose={onCloseInspector}
          />
        )}
      </div>
      <ModalFooter>
        <Button onClick={closeModal}>{t('cancel')}</Button>
        <Button type={Button.ButtonType.PRIMARY} onClick={onSubmit}>
          {t('save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SelectSystemsList
