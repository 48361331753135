import CheckBox from '@icons/check_box-fill.svg'
import CheckBoxOutlineBlank from '@icons/check_box_outline_blank.svg'
import { Column, RowData } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { Optional } from 'utility-types'
import { classNames } from 'src/utility/utils'
import { ISingleFilter } from '../list/ListContextProvider'

interface IFilterItem {
  filter: Optional<ISingleFilter, 'active'>
  column: Column<RowData>
  active: boolean
  onSelect?: () => void
}

const styleClass = {
  filterItem: classNames(
    'w-full',
    'flex',
    'flex-row',
    'items-center',
    'px-2',
    'py-1',
    'text-sm',
    'leading-5',
    'text-gray-500',
    'hover:bg-gray-100',
    'hover:text-gray-900',
    'focus:bg-gray-100',
    'focus:text-gray-900',
    'w-full',
    'filterItemScroll',
  ),
}

const FilterItem = ({ column, filter, active, onSelect }: IFilterItem) => {
  const onItemClick = () => {
    if (onSelect) {
      onSelect()
      return
    }

    if (
      (column.getFilterValue() as (string | boolean)[] | undefined)?.includes(
        filter.value,
      )
    ) {
      column.setFilterValue((val: string[]) => [
        ...val.filter((v) => v !== filter.value),
      ])
    } else
      column.setFilterValue((val: string[] | undefined) => [
        ...(val ?? []),
        filter.value,
      ])
  }

  const { name } = filter
  const { t } = useTranslation()
  return (
    <div
      className={styleClass.filterItem}
      style={{ cursor: 'pointer' }}
      onClick={onItemClick}
    >
      {active ? (
        <CheckBox fontSize={18} className="fill-blue-root min-w-6"></CheckBox>
      ) : (
        <CheckBoxOutlineBlank
          fontSize={19}
          className="fill-dark-grey min-w-6"
        ></CheckBoxOutlineBlank>
      )}
      <span className="ml-1 first-capitalize">{t(name)}</span>
    </div>
  )
}

export default FilterItem
