import ExitToApp from '@icons/exit_to_app.svg'
import Close from '@material-symbols/svg-500/outlined/close.svg'
import { capitalize } from 'lodash'
import moment from 'moment'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import {
  CreatedAtColumn,
  EditableDateColumn,
  FileNameColumn,
  SystemNumberColumn,
  UpdatedAtColumn,
} from 'src/components/TableColumns/Columns'
import TableStatusLabel, {
  IStatusCell,
} from 'src/components/status-dropdown/TableStatusLabel'
import AddFileModal from 'src/components/upload-item/AddFileModal'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import DocumentForm from 'src/document/components/DocumentCreateModal/DocumentForm'
import DocListStage from 'src/document/components/FolderDocument/DocListStatus'
import { IFileContainer } from 'src/document/types/IFileContainer'
import SelectFileContainersList from 'src/page/system/SelectFileContainerForm'
import { ITestDocument, TestDocumentStatuses } from 'src/service/OrgTypes'
import { updateTestDocument } from 'src/service/TestService'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize, ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import List from 'src/ui-elements/list/List'
import Modal from 'src/ui-elements/modal/Modal'
import {
  capFirstLetter,
  classNames,
  getOrganizationBaseUrl,
} from 'src/utility/utils'
import TestDocumentForm from './TestDocumentForm'

interface ItestDocumentTableProps {
  projectId: number
  testDocuments: ITestDocument[]
  reload: () => void
}

export const testDocumentBadgeColorMapper: {
  [status in TestDocumentStatuses]: BadgeColor
} = {
  [TestDocumentStatuses.NOT_UPLOADED]: BadgeColor.YELLOW,
  [TestDocumentStatuses.UPLOADED]: BadgeColor.BLUE,
  [TestDocumentStatuses.NEEDS_REVISION]: BadgeColor.RED,
  [TestDocumentStatuses.NOT_APPLICABLE]: BadgeColor.DEFAULT,
  [TestDocumentStatuses.APPROVED]: BadgeColor.GREEN,
}

const TestDocumentTable = ({
  projectId,
  testDocuments,
  reload,
}: ItestDocumentTableProps) => {
  const { t } = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [fileUploadModal, setFileUploadModal] = useState(false)
  const [createDocumentModal, setCreateDocumentModal] = useState(false)
  const [showFileContainerModal, setShowFileContainerModal] = useState(false)
  const [selectedTestDocumentId, setSelectedTestDocumentId] = useState(0)
  const projectContext = useContext(ProjectContext)

  const history = useHistory()

  const openCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
  }

  const uploadURL = `${getOrganizationBaseUrl()}/projects/${projectId}/upload_file/test_document`

  const updateFileURL = (fileUrl: string) => {
    const testDocumentData: NullablePartial<ITestDocument> = {
      file_url: fileUrl,
      status: TestDocumentStatuses.UPLOADED,
    }
    updateTestDocument(
      projectId,
      selectedTestDocumentId,
      testDocumentData,
    ).then(() => {
      reload()
    })
  }

  const selectedTestDocument = testDocuments.find(
    (document) => document.id === selectedTestDocumentId,
  )

  const onFileModalOpen = (selectedTestDocumentId: number) => {
    setFileUploadModal(true)
    setSelectedTestDocumentId(selectedTestDocumentId)
  }

  const TestDocumentStatusList = Object.values(TestDocumentStatuses).map(
    (status) => ({
      id: status,
      name: capFirstLetter(t(status)),
    }),
  )

  const usingDocumentModule =
    projectContext.state.currentProject.document_control_module

  const fileContainerColumns = [
    {
      name: 'document',
      size: '100',
      id: 'file_container',
      dataField: 'id||file_container',
      sortingField: 'name',
      cell: ({
        id,
        file_container,
      }: {
        id: number
        file_container?: IFileContainer
      }) =>
        file_container ? (
          <span
            className={
              'flex items-center w-full h-full cursor-pointer truncate'
            }
          >
            {`${file_container.record_id ?? ''} - ${
              file_container.name ?? ''
            } `}
            <Close
              onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
                e.preventDefault()
                e.stopPropagation()
                updateTestDocument(projectId, id, {
                  file_container_id: null,
                }).then(reload)
              }}
              className={'fill-red font-medium w-5 h-5 cursor-pointer'}
            />
            <Link
              to={`/documents/documents?folderId=${file_container.folder_id}&documentId=${file_container.id}`}
            >
              <Button
                toolTipText={capitalize(t('go_to_connected_document'))}
                type={ButtonType.SECONDARY}
                size={ButtonSize.NARROW}
                onClick={() => undefined}
              >
                <ExitToApp className="fill-blue-root" />
              </Button>
            </Link>
          </span>
        ) : (
          <>
            <span
              className="text-blue-root underline cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setCreateDocumentModal(true)
                setSelectedTestDocumentId(id)
              }}
            >
              {capitalize(t('create_new'))}
            </span>
            <span className="mx-1"> {t('or')} </span>
            <span
              className="text-blue-root underline cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                setShowFileContainerModal(true)
                setSelectedTestDocumentId(id)
              }}
            >
              {t('choose_existing')}
            </span>
          </>
        ),
    },
    {
      size: '200',
      id: 'step',
      name: 'step',
      dataField: 'file_container',
      cell: (file_container?: IFileContainer) =>
        file_container?.current_step ? (
          <div className="truncate">
            <DocListStage stage={file_container.current_step} />
          </div>
        ) : (
          <span />
        ),
    },
    {
      size: '200',
      id: 'revision_name',
      name: 'revision_name',
      dataField: 'file_container',
      cell: (file_container?: IFileContainer) => (
        <span>{file_container?.current_revision?.name ?? ''}</span>
      ),
    },
    {
      size: '200',
      id: 'revision_date',
      name: 'revision_date',
      dataField: 'file_container',
      cell: (file_container?: IFileContainer) => (
        <span>
          {file_container?.current_revision
            ? moment(file_container.current_revision.start_time).format('L')
            : ''}
        </span>
      ),
    },
  ]

  const baseDocumentColumns = [
    {
      name: 'upload',
      size: '100',
      id: 'documentFile',
      dataField: 'id||is_active',
      sortingField: 'document',
      cell: (cell: { is_active: boolean; id: number }) => {
        return cell.is_active ? (
          <div className="flex flex-row items-center w-full">
            <Icon
              icon={Icon.IconType.UPLOAD}
              styleClass={classNames('w-6', 'h-6')}
              className="pr-2 w-full flex justify-center"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onFileModalOpen(cell.id)
              }}
            />
          </div>
        ) : (
          <>{t('inactive_document')}</>
        )
      },
    },
  ]

  const columns = [
    SystemNumberColumn(),
    FileNameColumn(
      'category',
      'document_name',
      'document_name||file_url',
      '150',
    ),
    ...(usingDocumentModule ? fileContainerColumns : baseDocumentColumns),
    {
      name: 'status',
      size: '200',
      id: 'status',
      sortingField: 'status',
      dataField: 'status||id',
      TestDocumentStatusList,
      cell: (cell: IStatusCell, key: number) => (
        <TableStatusLabel
          statusTypes={TestDocumentStatusList}
          cell={cell}
          rowId={key}
          onStatusSelect={(status, key) => {
            updateTestDocument(projectId, key, {
              status: status.id as TestDocumentStatuses,
            }).then(() => reload())
          }}
          showDropdownAlways={true}
          selectColor={(cell) =>
            testDocumentBadgeColorMapper[cell.status as TestDocumentStatuses]
          }
        />
      ),
    },
    CreatedAtColumn(),
    UpdatedAtColumn(),
    EditableDateColumn(
      'deadline',
      'deadline',
      'deadline',
      (date, id) =>
        updateTestDocument(projectId, id, {
          deadline: date && date.toLocaleString(),
        }).then(reload),
      {
        deadline: true,
        nullable: true,
      },
    ),
  ]

  return (
    <div>
      <List
        data={testDocuments}
        columns={columns}
        tableName={'testDocumentList'}
        itemsPerPage={0}
        onRowClick={(row) => {
          if (!row.file_container) return
          history.push(
            `/documents/documents?documentId=${row.file_container.id}&folderId=${row.file_container.folder_id}`,
          )
        }}
        actionButton={
          <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
            {t('new_category')}
          </Button>
        }
      />
      <AddFileModal
        title={t('test_document')}
        show={fileUploadModal}
        setShow={setFileUploadModal}
        uploadURL={uploadURL}
        onUpload={updateFileURL}
      />
      <Modal
        size={'w-1/3'}
        show={showCreateModal}
        closeModal={closeCreateModal}
        title={t('new_document')}
        maxWidth={600}
      >
        <TestDocumentForm
          editingMode={false}
          testDocument={{ document_name: '', is_active: true }}
          projectId={projectId}
          closeModal={closeCreateModal}
        />
      </Modal>
      {showFileContainerModal && (
        <SelectFileContainersList
          selectSingle={true}
          projectId={projectId}
          selectedFileContainerIds={
            selectedTestDocument?.file_container
              ? [selectedTestDocument.file_container.id]
              : []
          }
          onSelectFileContainers={async (fileContainerIds: number[]) => {
            if (!selectedTestDocumentId) return
            await updateTestDocument(projectId, selectedTestDocumentId, {
              file_container_id: fileContainerIds[0],
            })
            reload()
            setShowFileContainerModal(false)
          }}
          closeModal={() => {
            setShowFileContainerModal(false)
          }}
        />
      )}
      <DocumentForm
        show={createDocumentModal}
        close={() => setCreateDocumentModal(false)}
        folder={selectedTestDocument?.file_container?.folder}
        afterCreate={(document: IFileContainer) =>
          updateTestDocument(projectId, selectedTestDocumentId, {
            file_container_id: document.id,
          }).then(reload)
        }
      />
    </div>
  )
}

export default TestDocumentTable
