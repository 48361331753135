import Close from '@icons/close.svg'
import Filter from '@icons/filter_list.svg'
import North from '@icons/north.svg'
import South from '@icons/south.svg'
import { Column, ColumnOrderState, Table } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import moment, { Moment } from 'moment'
import { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import FilterItem from 'src/ui-elements/Table/FilterItem'
import {
  ColumnFilterValue,
  DateRangeFilterType,
} from 'src/ui-elements/Table/useTable'
import Input from 'src/ui-elements/input/Input'
import { ISingleFilter } from 'src/ui-elements/list/ListContextProvider'
import { filterType } from 'src/ui-elements/list/ListTypes'
import { useDebouncer } from 'src/utility/Debouncer'
import { renderDayWithoutTimezone } from 'src/utility/Utility'
import { classNames } from 'src/utility/utils'

interface IListColumnProps {
  column: Column<any>
  table: Table<any>
}

const styleClass = {
  headerColumn: () =>
    classNames(
      'flex',
      'flex-row',
      'justify-between',
      'cursor-move',
      'tracking-wider',
      'flex-no-wrap',
      'leading-6 h-6',
      'text-gray-500',
      'text-left',
    ),
  filterButton: (isActive: boolean) =>
    classNames(
      'flex items-center',
      'cursor-pointer',
      isActive ? 'fill-blue-root opacity-100' : 'opacity-30',
      'hover:fill-blue-root hover:opacity-100',
      'text-lg',
    ),
  filterBox: (type: boolean) =>
    classNames(
      'absolute z-30',
      'bg-white',
      'font-normal',
      'flex-no-wrap',
      'top-7 right-0',
      'border border-gray-300 rounded-b',
      'shadow-xl',
      'flex',
      !type ? 'flex-col' : 'flex-row',
      !type ? 'justify-start' : 'justify-center',
    ),
}

const reorderColumns = (
  dragged: string,
  current: string,
  order: ColumnOrderState,
) => {
  order.splice(
    order.indexOf(current),
    0,
    order.splice(order.indexOf(dragged), 1)[0],
  )

  return [...order]
}

const ColumnFilter = ({
  close,
  colFilterType,
  column,
  table,
}: {
  close: () => void
  colFilterType: filterType
  column: Column<any>
  table: Table<any>
}) => {
  const { t } = useTranslation()

  const [startDate, setStartDate] = useState<Moment>(moment())
  const [endDate, setEndDate] = useState<Moment>(moment().add(1, 'days'))
  const [focusedInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >('startDate')
  const [filterOptions, setFilterOptions] = useState<
    ISingleFilter[] | undefined
  >(undefined)

  const defaultFilterValue = useRef('')
  const freeTextFilterValue = useRef('')

  const [freeText, setFreeText] = useState<string>()

  const [searchValue, setSearchValue] = useState('')

  const startDefaultDebouncer = useDebouncer({
    delay: 700,
    callback: () => {
      setSearchValue(defaultFilterValue.current)
    },
  })

  const startFreeTextDebouncer = useDebouncer({
    delay: 700,
    callback: () => {
      column.setFilterValue(freeTextFilterValue.current)
    },
  })

  const onFilterSearch = (e: ChangeEvent<HTMLInputElement>) => {
    defaultFilterValue.current = e.target.value
    startDefaultDebouncer()
  }

  const onDateRangeSelect = (range: { startDate: Moment; endDate: Moment }) => {
    setStartDate(range.startDate)
    setEndDate(range.endDate)
    column.setFilterValue({ start: range.startDate, end: range.endDate })
  }

  const onFreeTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    freeTextFilterValue.current = text
    setFreeText(text)
    startFreeTextDebouncer()
  }

  useEffect(() => {
    const fetchFilterOptions = async () => {
      const filterOptions = await table.options.meta?.getFilterOptions(column)
      setFilterOptions(filterOptions)
    }

    fetchFilterOptions()
  }, [setFilterOptions, table.options.meta, column])

  const activeFilters = column.getFilterValue() as ColumnFilterValue

  const activeDates =
    colFilterType === filterType.RANGEDATE
      ? (column.getFilterValue() as DateRangeFilterType)
      : undefined

  const filterOptionsToShow = filterOptions?.filter((filter: ISingleFilter) => {
    const name = filter.name ? filter.name.toLowerCase() : ''
    return (
      name.includes(searchValue.toLowerCase()) || ['select_all'].includes(name)
    )
  })

  const selectAllActive = !filterOptionsToShow?.some(
    (o) => !activeFilters?.includes(o.value),
  )

  return (
    <CloseClickOutside onClose={close}>
      <div
        className={`${styleClass.filterBox(
          colFilterType === filterType.RANGEDATE,
        )}`}
      >
        {colFilterType === filterType.DEFAULT && (
          <Input
            type="search"
            autoFocus={true}
            value={searchValue}
            onChange={onFilterSearch}
            noBorder
            noPadding
            block={true}
            placeholder={t('search')}
          />
        )}
        {(colFilterType === filterType.NUMBER ||
          colFilterType === filterType.TEXT) && (
          <Input
            type={colFilterType === filterType.NUMBER ? 'number' : 'search'}
            autoFocus={true}
            value={
              freeText ??
              (colFilterType === filterType.NUMBER
                ? (column.getFilterValue() as number)
                : (column.getFilterValue() as string))
            }
            noBorder
            noPadding
            onChange={onFreeTextChange}
            block={true}
            placeholder={t('search')}
          />
        )}
        {colFilterType === filterType.DEFAULT &&
          (filterOptionsToShow ? (
            <>
              <FilterItem
                filter={{ name: t('select_all'), value: 'SELECT_ALL' }}
                active={selectAllActive}
                column={column}
                onSelect={() =>
                  column.setFilterValue(
                    selectAllActive
                      ? []
                      : filterOptionsToShow.map((o) => o.value),
                  )
                }
              />
              <FilterItem
                filter={{ name: t('select_no_value'), value: 'null' }}
                active={activeFilters?.includes('null')}
                column={column}
              />
              <div className="border-b border-gray-300" />
              {filterOptionsToShow.map((filter: ISingleFilter) => (
                <FilterItem
                  key={filter.value}
                  filter={filter}
                  active={activeFilters?.includes(filter.value)}
                  column={column}
                />
              ))}
            </>
          ) : (
            <div className="mx-2 animate-pulse">
              <div className="w-full h-4 bg-gray-300 rounded my-1" />
              <div className="w-full h-4 bg-gray-300 rounded my-1" />
              <div className="w-full h-4 bg-gray-300 rounded my-1" />
              <div className="w-full h-4 bg-gray-300 rounded my-1" />
            </div>
          ))}
        {colFilterType === filterType.RANGEDATE && (
          <DateRangePicker
            small={true}
            startDate={
              activeDates?.start ? moment(activeDates?.start) : startDate
            }
            startDateId="date-start"
            endDate={activeDates?.end ? moment(activeDates?.end) : endDate}
            endDateId="date-end"
            onDatesChange={onDateRangeSelect}
            renderDayContents={renderDayWithoutTimezone}
            firstDayOfWeek={1}
            focusedInput={focusedInput}
            onFocusChange={(fi) => setFocusedInput(fi)}
            displayFormat={() => moment.localeData('no').postformat('DD.MM.YY')}
            noBorder
            isOutsideRange={() => false}
          />
        )}
        {colFilterType === filterType.BOOLEAN && (
          <div className="w-full">
            <FilterItem
              key={'yes'}
              filter={{ name: 'yes', value: 'true', active: false }}
              column={column}
              active={activeFilters?.includes('true')}
            />
            <FilterItem
              key={'no'}
              filter={{ name: 'no', value: 'false', active: false }}
              column={column}
              active={activeFilters?.includes('false')}
            />
          </div>
        )}
      </div>
    </CloseClickOutside>
  )
}

const TableColumn = (props: IListColumnProps) => {
  const { column, table } = props
  const [filterOpen, setIsFilterOpen] = useState(false)

  const onDragStart = (ev: DragEvent) => {
    ev.dataTransfer.setData('id', column.id)
  }

  const onDragOver = (ev: any) => {
    ev.preventDefault()
  }

  const onDrop = (ev: DragEvent<HTMLDivElement>) => {
    const draggedId = ev.dataTransfer.getData('id')
    if (!draggedId) return

    const { columnOrder } = table.getState()
    table.setColumnOrder(reorderColumns(draggedId, column.id, columnOrder))
  }

  const onFilterOpen = () => {
    setIsFilterOpen(!filterOpen)
  }

  const removeFilters = () => {
    column.setFilterValue(undefined)
  }

  const colFilterType = column.columnDef.meta?.filterType

  const filterIsActive = column.getIsFiltered()

  return (
    <div onDragOver={onDragOver} onDrop={onDrop}>
      <div onDragStart={onDragStart} draggable={true}>
        <div className={styleClass.headerColumn()}>
          <div className={'flex gap-2 truncate'}>
            {column.getCanSort() && (
              <div
                className={styleClass.filterButton(!!column.getIsSorted())}
                onClick={column.getToggleSortingHandler()}
              >
                {column.getIsSorted() === 'asc' ? <North /> : <South />}
              </div>
            )}
            <span
              className={twMerge(
                'flex truncate',
                !column.getCanSort() && 'ml-3',
              )}
            >
              {capitalize(column.columnDef.meta?.name)}
            </span>
          </div>
          {column.getCanFilter() && (
            <>
              {filterOpen && colFilterType && (
                <ColumnFilter
                  close={() => setIsFilterOpen(false)}
                  colFilterType={colFilterType}
                  column={column}
                  table={table}
                />
              )}
              <div className={'inline-flex flex-row mx-2 items-center'}>
                {filterIsActive && (
                  <Close
                    className={twMerge(styleClass.filterButton(false))}
                    onClick={removeFilters}
                  />
                )}
                <Filter
                  className={twMerge(styleClass.filterButton(filterIsActive))}
                  onClick={onFilterOpen}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TableColumn
