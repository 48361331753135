import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckBox from 'src/components/switchHoc/CheckBox'
import { ITestDocument } from 'src/service/OrgTypes'
import {
  createTestDocumentTypes,
  updateTestDocumentTypes,
} from 'src/service/TestService'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import Spinner from '../../../ui-elements/loader/Spinner'
import { classNames } from '../../../utility/utils.js'

interface ITestDocumentFromProps {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  testDocument: Omit<ITestDocument, 'id'>
}

const TestDocumentForm = ({
  projectId,
  closeModal,
  editingMode,
  testDocument,
}: ITestDocumentFromProps) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  const { t } = useTranslation()
  const [name, setName] = useState(
    testDocument ? testDocument.document_name : '',
  )
  const [isActive, setIsActive] = useState(!!testDocument.is_active)
  const [loading, setLoading] = useState(false)
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onSubmit = (e: any) => {
    let error = false
    e.preventDefault()

    setLoading(true)
    setNameErrorMessage('')

    if (!name) {
      setNameErrorMessage(t('fill_in_the_name'))
      error = true
    }

    if (!error) {
      if (editingMode) onConfirm()
      else setShowConfirmModal(true)
    }

    setLoading(false)
  }

  const onConfirm = () => {
    if (editingMode) {
      const updatedTestDocument = {
        ...testDocument,
        document_name: name,
        is_active: isActive,
      }
      updateTestDocumentTypes(updatedTestDocument, projectId).then(() =>
        closeModal(),
      )
    } else {
      const createThisTestDocumentType = {
        document_name: name,
        is_active: isActive,
      }
      createTestDocumentTypes(createThisTestDocumentType, projectId).then(
        () => {
          closeModal()
        },
      )
    }
  }

  const onNameChange = (e: any) => {
    setName(e.target.value)
    setNameErrorMessage('')
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('name')}
          block={true}
          value={name}
          onChange={onNameChange}
          required={true}
          errorMessage={nameErrorMessage}
          autoFocus={true}
        />
      </div>
      <div className={styleClass.inputGroup}>
        <div
          className={'pl-2 flex items-center w-1/2'}
          onClick={() => setIsActive(!isActive)}
        >
          <CheckBox
            disableTab={true}
            onChange={() => setIsActive(!isActive)}
            valueProp={isActive}
          />
          <p className={`pl-2  text-gray-500 text-sm`}>{t('is_active')}</p>
        </div>
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        {editingMode ? (
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : t('update')}
          </Button>
        ) : (
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : t('add')}
          </Button>
        )}
      </ModalFooter>
      <Modal
        show={showConfirmModal}
        title={t('confirm')}
        closeModal={() => setShowConfirmModal(false)}
        maxWidth={600}
      >
        <div className="m-2">
          <span>
            {t(
              'creating_a_new_test_document_will_apply_for_all_systems_within_the_project',
            )}
          </span>
        </div>
        <ModalFooter>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={() => setShowConfirmModal(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading}
            onClick={onConfirm}
          >
            {loading ? <Spinner /> : t('confirm')}
          </Button>
        </ModalFooter>
      </Modal>
    </form>
  )
}

export default TestDocumentForm
