import { flexRender, RowData, Table as ReactTable } from '@tanstack/react-table'
import { twMerge } from 'tailwind-merge'
import Pagination from 'src/ui-elements/Table/Pagination'
import { classNames } from 'src/utility/utils'

export type TableBodyProps<T> = {
  table: ReactTable<T>
  pageCount: number
  totalItems: number
  adjustableHeight?: boolean
}

const TableBody = <T extends RowData>({
  table,
  pageCount,
  totalItems,
  adjustableHeight = false,
}: TableBodyProps<T>) => {
  return (
    <>
      <div className={classNames('flex-col relative')}>
        <table style={{ width: table.getCenterTotalSize() }}>
          <thead className={'z-9 sticky justify-start'}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={classNames('flex text-gray-500 bg-gray-100')}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                    className={classNames(
                      'relative',
                      'border-t border-b border-gray-300 first:border-l border-r',
                      'first:rounded-tl last:rounded-tr',
                      'hover:bg-blue-50',
                      'text-sm',
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    <div
                      className={twMerge(
                        'absolute top-0 right-0 h-full w-1 cursor-col-resize select-none touch-none opacity-30 hover:bg-blue-root',
                        header.column.getIsResizing() &&
                          'bg-blue-root opacity-50',
                      )}
                      onDoubleClick={() => header.column.resetSize()}
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={'whitespace-nowrap'}>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={twMerge(
                  'flex',
                  'pl-0',
                  'hover:bg-blue-50 cursor-pointer',
                  'whitespace-nowrap',
                  'odd:bg-white even:bg-slate-50',
                  !adjustableHeight && 'h-7s',
                  row.getIsSelected() && 'bg-blue-50',
                )}
                onClick={() =>
                  table.getIsSomeRowsSelected()
                    ? row.toggleSelected()
                    : table.options.meta?.onRowClick?.(row)
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{ width: cell.column.getSize() }}
                    className={classNames(
                      'flex items-center leading-5',
                      'whitespace-nowrap',
                      'border-gray-300 first:border-l border-r border-b',
                      'font-normal text-sm text-gray-700',
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChangePage={table.setPageIndex}
        pageSize={table.getState().pagination.pageSize}
        onChangePageSize={table.setPageSize}
        totalPages={pageCount}
        totalItems={totalItems}
        currentPage={table.getState().pagination.pageIndex}
      />
    </>
  )
}

export default TableBody
