import DropDown from '@icons/keyboard_arrow_down.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import SelectSingleSystemsList from 'src/page/system/SelectSingleSystemsList'
import { ISystem } from 'src/service/OrgTypes'
import InlineCompontent from 'src/ui-elements/page-display/inline-components/InlineComponent'
import { capFirstLetter } from 'src/utility/utils'

interface SystemSelectorWithListProps {
  currentSystemId?: number
  projectId: number
  selectedSystemId?: number | null | undefined
  onSystemSelect: (id: number) => void
  label: string
  systemSettingId?: number
  onPage?: boolean
  intialSystem?: ISystem
  className?: string
  labelWidth?: string
  disabled?: boolean
}

const SystemSelectorWithList = ({
  currentSystemId,
  projectId,
  selectedSystemId,
  onSystemSelect,
  label,
  systemSettingId,
  onPage,
  intialSystem,
  className,
  labelWidth,
  disabled,
}: SystemSelectorWithListProps) => {
  const { t } = useTranslation()
  const [showReplacedByModal, setShowReplacedByModal] = useState(false)
  const [system, setSystem] = useState<ISystem | undefined>(intialSystem)

  return (
    <>
      {onPage ? (
        <InlineCompontent
          label={label}
          labelWidth={labelWidth ?? 'w-48'}
          content={
            <div
              className={`flex flex-row mx-2 group items-center max-h-[20px] truncate`}
            >
              <p
                className={`max-w-[350px] min-w-[350px] items-center truncate text-sm leading-5 ${
                  selectedSystemId ? 'text-gray-700' : 'text-gray-400'
                } ${className}`}
                onClick={() => !disabled && setShowReplacedByModal(true)}
              >
                {system?.record_id
                  ? `${system?.record_id ?? ''} ${system?.name ?? ''}`
                  : `____`}
              </p>
              <DropDown
                className={twMerge(
                  'invisible',
                  !disabled &&
                    'group-hover:visible w-5 h-5 fill-gray-400 ml-0.5',
                )}
              />
            </div>
          }
          disabled={disabled}
        />
      ) : (
        <div className="flex flex-col ml-2 space-y-2 mt-2">
          <div className="flex flex-row  items-center">
            <p className="min-w-[150px] font-medium text-sm leading-5 text-gray-700 ">
              {capFirstLetter(t(label))}
            </p>
          </div>
          <p
            className={`flex justify-between cursor-pointer items-center truncate text-sm leading-5 hover:bg-gray-100 border border-gray-300 px-2 mr-2 py-1 font-normal ${
              selectedSystemId ? 'text-gray-700' : 'text-gray-400'
            }`}
            onClick={() => setShowReplacedByModal(true)}
          >
            {selectedSystemId
              ? `${system?.record_id ?? ''} ${system?.name ?? ''}`
              : `${capFirstLetter(t('select'))} ${t(label)}`}
            <DropDown className="w-6 h-6 fill-gray-500" />
          </p>
        </div>
      )}

      {showReplacedByModal && (
        <SelectSingleSystemsList
          projectId={+projectId}
          key={projectId}
          selectedSystemId={selectedSystemId}
          currentSystemId={currentSystemId}
          onSelectSystem={(item) => {
            onSystemSelect(item.id)
            setSystem(item)
            setShowReplacedByModal(false)
          }}
          systemSettingId={systemSettingId}
          closeModal={() => setShowReplacedByModal(false)}
        />
      )}
    </>
  )
}

export default SystemSelectorWithList
