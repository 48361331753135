import { ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  Link,
  match,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { ImprovedRedirect } from 'src/components/tab-view/ImprovedRedirect'
import { useQuery } from 'src/hooks/useQuery'
import NotFound from 'src/page/dashboard/error/NotFound'
import { ProTabsStyles } from 'src/ui-elements/tabs/Utils'
import { capFirstLetter, classNames } from 'src/utility/utils'

type TabLevels = 1 | 2 | 3

interface IRedirect {
  to: string
  from: string
}

interface ITabHeader {
  tabHeader: ReactNode
  showHeader: (key: string) => boolean
}

export interface ITabs {
  [key: string]: {
    title: string
    content: ReactNode
    show: boolean
    path?: string | string[]
    exact?: boolean
    link?: string
  }
}

const tabKeys = ['tabIndex', 'subTabIndex']

const isActiveTab = (path: string | string[], match: match): boolean => {
  const paths = Array.isArray(path) ? path : [path]
  return paths.some((path) => {
    if (path === '') {
      return (
        null !== window.location.pathname.match(RegExp(`^${match.path}/?$`))
      )
    }
    return (
      null !==
      window.location.pathname.match(RegExp(`^${match.path}\/${path}(/.*|$)`))
    )
  })
}

const TabList = ({
  tabs,
  match,
  level = 1,
}: {
  tabs: ITabs
  match: match
  level?: TabLevels
  tabHeader?: ITabHeader
}) => {
  const [domReady, setDomReady] = useState<boolean>(false)

  useEffect(() => {
    setDomReady(true)
  }, [])

  const { selectedTab: selectedTabStyle, tab: tabStyle } = ProTabsStyles

  const el: any = document.getElementById('navigation-row')

  if (!domReady) {
    return null
  }

  return ReactDOM.createPortal(
    <ul
      className={classNames(
        level === 1 && 'sticky',
        'bg-d-grey-background pb-1 mb-1',
      )}
    >
      {Object.entries(tabs)
        .filter(([_, tab]) => tab.show)
        .map(([key, tab], index) => {
          const path = tab.link ?? tab.path ?? key
          return (
            <li key={`tabview${key}-${index}`} className={'inline'}>
              <Link
                to={`${match.path}/${Array.isArray(path) ? path[0] : path}`}
                key={key}
                className={classNames(
                  tabStyle,
                  isActiveTab(tab.path ?? key, match) && selectedTabStyle,
                  level === 1 && 'pt-3',
                )}
              >
                {capFirstLetter(tab.title)}
              </Link>
            </li>
          )
        })}
    </ul>,
    el,
  )
}

const useTabIndexRedirect = (tabs: ITabs, level: TabLevels) => {
  const tabIndex = level - 1
  const history = useHistory()
  const { location, searchParams } = useQuery()
  const { path } = useRouteMatch()
  const tabsKey = tabKeys[tabIndex]

  const tabParams = searchParams.get(tabsKey)?.split(',') || []

  useEffect(() => {
    if (tabParams[0]) {
      const tab = Object.entries(tabs)[Number(tabParams[0])]
      searchParams.delete(tabsKey)
      if (tab) history.replace(`${path}/${tab[0]}?${searchParams.toString()}`)
    }
  }, [location])
}

const TabView = ({
  tabs,
  level = 1,
  redirects,
  tabHeader,
}: {
  tabs: ITabs
  level?: TabLevels
  redirects?: IRedirect[]
  tabHeader?: ITabHeader
}) => {
  const match = useRouteMatch()
  const [firstKey, firstTab] = Object.entries(tabs)[0]
  const firstPath: string =
    firstTab.link ??
    (firstTab.path
      ? Array.isArray(firstTab.path)
        ? firstTab.path.slice(-1)[0]
        : firstTab.path
      : firstKey)

  useTabIndexRedirect(tabs, level)

  const activeTabKey =
    Object.entries(tabs).find(([key, tab]) =>
      isActiveTab(tab.path ?? key, match),
    )?.[0] ?? firstKey

  return (
    <>
      <TabList tabs={tabs} match={match} level={level} />
      {tabHeader?.showHeader(activeTabKey) && tabHeader?.tabHeader}
      <div
        className={
          tabHeader?.showHeader(activeTabKey)
            ? 'h-[calc(100%-3rem)] mt-1'
            : 'h-full'
        }
      >
        <Switch>
          {ImprovedRedirect(match.path, '', firstPath, true)}
          {Object.entries(tabs)
            .filter(([_, tab]) => tab.show)
            .map(([key, tab]) => {
              const pathVar = tab.path ?? key
              const path = Array.isArray(pathVar)
                ? pathVar.map((path) => `${match.path}/${path}`)
                : `${match.path}/${pathVar}`
              return (
                <Route
                  key={key}
                  path={path}
                  exact={tab.exact ?? tab.path === '' ? true : false}
                >
                  {tab.content}
                </Route>
              )
            })}
          {redirects?.map(({ from, to }) => {
            return ImprovedRedirect(match.path, from, to)
          })}
          <Route path="" component={NotFound} />
        </Switch>
      </div>
    </>
  )
}

export default TabView
