import { useState, useEffect } from 'react'
import Switch from 'react-switch'

export interface ISwitchHOCProps {
  valueProp: boolean | null
  className?: string
  onChange: (v: boolean) => void
  defaultColor?: boolean
  disabled?: boolean
  height?: number
  width?: number
  controlValueInParent?: boolean
  id?: string
}
const SwitchHOC = ({
  valueProp,
  onChange,
  className,
  defaultColor,
  disabled,
  width,
  height,
  controlValueInParent,
  id,
}: ISwitchHOCProps) => {
  const [value, setValue] = useState<boolean>(
    valueProp === null ? false : valueProp,
  )
  const onValueChange = (va: boolean) => {
    setValue(va)
    onChange(va)
  }
  useEffect(() => {
    setValue(valueProp === null ? false : valueProp)
  }, [valueProp])
  return (
    <Switch
      height={height ? height : 20}
      width={width ? width : 40}
      uncheckedIcon={false}
      checkedIcon={false}
      checked={controlValueInParent ? valueProp ?? false : value}
      className={className}
      onChange={(v: any, e: any) => {
        e.stopPropagation()
        controlValueInParent ? onChange(v) : onValueChange(v)
      }}
      onColor={!defaultColor ? '#269BF7' : undefined}
      offColor={!defaultColor ? '#CBD5E0' : undefined}
      disabled={disabled}
      id={id}
    />
  )
}
export default SwitchHOC
