import * as React from 'react'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import FolderService from 'src/document/services/FolderService'
import { IFileContainer } from 'src/document/types/IFileContainer'
import { IFolderData } from 'src/document/types/IFolderData'
import { IMetaValue } from 'src/document/types/IMetaData'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Selector from '../../../components/selectors/Selector'
import Checkbox from '../../../components/switchHoc/CheckBox'
import {
  getMetaDataValues,
  initializeMetaValues,
  setExistingValues,
} from '../../../components/system/SystemUtil'
import { getDisciplines } from '../../../service/DisciplineService'
import {
  createDocument,
  editDocument,
  getDocument,
  massUpdateDocument,
} from '../../../service/FileContainerService'
import { IDiscipline, IUserData } from '../../../service/OrgTypes'
import {
  getProjectDisplineUsers,
  getProjectUsersWithDisciplines,
} from '../../../service/UserService'
import Button from '../../../ui-elements/button/Button'
import DocumentTypeService from '../../services/DocumentTypeService'
import { BASE_METADATA_TYPES } from '../../types/FolderMetadataTypes'
import { IDocumentType } from '../../types/IFlow'
import { IFolderTreeData } from '../../types/IFolderTreeData'
import DocumentMetaDataFields from './DocumentMetaDataFields'

interface IDocumentFormProps {
  show: boolean
  close: () => void
  folder?: IFolderTreeData
  documentId?: number
  documentIds?: number[]
  afterCreate?: (document: IFileContainer) => void
}

const DocumentForm: React.FC<IDocumentFormProps> = ({
  show,
  close,
  folder,
  documentId,
  documentIds,
  afterCreate,
}) => {
  const massEditing = !!documentIds
  const projectContext = useContext(ProjectContext)
  const [selectedFolder, setSelectedFolder] = useState<IFolderData>()
  const currentFolder = folder ?? selectedFolder
  const isBlueFolder = !!currentFolder?.flow_id
  const projectId = projectContext.state.currentProject.id
  const [title, setTitle] = useState<string>()
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [metaDataErrorMessage, setMetaDataErrorMessage] = useState<string>('')
  const [documentTypeId, setDocumentTypeId] = useState<number>()
  const [resetRecordId, setResetRecordId] = useState<boolean>(false)
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([])
  const [disciplineId, setDisciplineId] = useState<number>()
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [displineLoading, setDisplineLoading] = useState<boolean>(false)
  const [responsibleId, setResponsibleId] = useState<number>()
  const [users, setUsers] = useState<IUserData[]>([])
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const [contractId, setContractId] = useState<number>()
  const [folders, setFolders] = useState<IFolderData[]>([])
  const [documentTypeError, setDocumentTypeError] = useState<string>()
  const [requiredFields, setRequiredFields] = useState<IMetaValue[]>(
    initializeMetaValues(
      currentFolder?.required_fields ?? [],
      'FileContainer',
      undefined,
    ),
  )

  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>(
    initializeMetaValues(
      currentFolder?.optional_fields ?? [],
      'FileContainer',
      undefined,
    ),
  )
  const [document, setDocument] = useState<IFileContainer>()

  const { state } = useContext(ProjectContext)
  const { state: userState } = useContext(UserContext)

  const { t } = useTranslation()

  useEffect(() => {
    DocumentTypeService.getProjectDocumentTypes().then((res) => {
      setDocumentTypes(res)
    })
    if (!currentFolder) {
      FolderService.getFolders().then(setFolders)
    }

    if (!documentIds && documentId && documentId > 0) {
      getDocument(projectId, documentId).then((res) => {
        if (res) {
          setTitle(res.name ?? '')
          setDocumentTypeId(res.document_type_id)
          setDisciplineId(res.discipline_id)
          setResponsibleId(res.responsible_id)
          const meta_data = getMetaDataValues(res.meta_data)
          setRequiredFields(setExistingValues(meta_data, requiredFields))
          setOptionalFields(setExistingValues(meta_data, optionalFields))
          setDocument(res)
        }
      })
    }
  }, [documentId, currentFolder?.id])

  const onDisplineOpen = () => {
    setDisplineLoading(true)
    getDisciplines(projectId).then((res) => {
      setDisciplines(res)
      setDisplineLoading(false)
    })
  }

  const onDisciplineChange = async (value: number) => {
    const discipline = disciplines?.find((v) => v.id === value)
    if (!responsibleId || responsibleId <= 0) {
      const usersList = await getProjectDisplineUsers(projectId, value)
      setResponsibleId(undefined)
      setUsers(usersList)
    }

    setDisciplineId(value)
    setContractId(
      discipline && discipline.contract_id ? discipline.contract_id : undefined,
    )
  }

  const onDisplineUserClear = async () => {
    setDisciplineId(undefined)
    setResponsibleId(undefined)
    const allProjectDis = await getDisciplines(projectId)
    const allUsers = await getProjectUsersWithDisciplines(projectId)
    setDisciplines(allProjectDis)
    setUsers(allUsers)
  }

  const getUsers = () => {
    const getCall = disciplineId
      ? getProjectDisplineUsers(projectId, disciplineId)
      : getProjectUsersWithDisciplines(projectId)
    getCall.then((res) => {
      setUsers(res)
      setUsersLoading(false)
    })
  }

  const onResponsibleChange = (userId: number) => {
    const selectedUser = users.filter((u) => u.id === userId).pop()
    const userDisciplines =
      selectedUser && selectedUser.disciplines
        ? selectedUser.disciplines.filter((d) => d.project_id === projectId)
        : undefined
    const discipline =
      userDisciplines && userDisciplines.length === 1
        ? userDisciplines[0]
        : undefined

    setResponsibleId(userId)
    setDisciplines(userDisciplines ? userDisciplines : [])
    if (discipline) {
      setDisciplineId(discipline.id)
      setContractId(discipline.contract_id)
    }
  }

  const onMassEdit = (e: any) => {
    e.preventDefault()
    if (documentIds && currentFolder?.id) {
      const updatedDocument: Omit<
        IFileContainer,
        | 'id'
        | 'history'
        | 'current_step_id'
        | 'created_by_id'
        | 'steps_metadata'
      > = {
        folder_id: currentFolder.id,
        required_fields: requiredFields.filter((item) => item.data_set),
        optional_fields: optionalFields.filter((item) => item.data_set),
        document_type_id: documentTypeId,
        responsible_id: responsibleId,
        discipline_id: disciplineId,
        contract_id: contractId,
        name: title ? title : undefined,
        reset_record_id: resetRecordId,
      }

      massUpdateDocument(
        projectId,
        currentFolder.id,
        documentIds,
        updatedDocument,
      )
        .then(() => {
          close()
        })
        .catch(() => close())
    }
  }

  const saveDocument = async () => {
    setTitleErrorMessage('')
    setMetaDataErrorMessage('')
    let error = false
    if (!title) {
      setTitleErrorMessage(t('required'))
      error = true
    }

    if (isBlueFolder && !documentTypeId) {
      setDocumentTypeError(t('required'))
      error = true
    }

    const validatedRequiredFields = requiredFields.map((item) => {
      const noValue =
        item.data_type === BASE_METADATA_TYPES.multiEnum
          ? !item.data_value_list || item.data_value_list?.length === 0
          : !item.data_value
      if (noValue) {
        error = true
        return {
          ...item,
          error: t('required'),
        }
      } else {
        return item
      }
    })

    setRequiredFields(validatedRequiredFields)

    if (!error) {
      if (
        state?.currentProject?.id &&
        userState?.user.id &&
        currentFolder?.id
      ) {
        const newDocument: Omit<
          IFileContainer,
          'id' | 'history' | 'current_step_id'
        > = {
          document_type_id: documentTypeId,
          folder_id: currentFolder.id,
          name: title,
          required_fields: requiredFields,
          optional_fields: optionalFields,
          created_by_id: userState.user.id,
          contract_id: contractId,
          responsible_id: responsibleId,
          discipline_id: disciplineId,
          project_id: projectId,
        }

        const updatedDocument: Omit<
          IFileContainer,
          'id' | 'history' | 'current_step_id'
        > = document
          ? {
              ...document,
              required_fields: requiredFields,
              optional_fields: optionalFields,
              created_by_id: userState.user.id,
              document_type_id: documentTypeId,
              folder_id: currentFolder.id,
              name: title,
              reset_record_id: resetRecordId,
            }
          : newDocument

        const createdDocument =
          documentId && documentId > 0
            ? await editDocument(projectId, documentId, updatedDocument)
            : await createDocument(projectId, currentFolder.id, newDocument)

        afterCreate?.(createdDocument)
        close()
      }
    }
  }

  const onFieldsRequiredUpdate = (values: IMetaValue[]) => {
    setRequiredFields(values)
    setMetaDataErrorMessage('')
  }

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setOptionalFields(values)
  }

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitleErrorMessage('')
    setTitle(e.target.value)
  }

  const onDocumentTypeChange = (value: number) => {
    setDocumentTypeError(undefined)
    setDocumentTypeId(value)
  }

  const onFolderChange = (newFolderId: number) => {
    FolderService.getSingleFolder(newFolderId).then((folder) => {
      setSelectedFolder(folder)
      setRequiredFields(initializeMetaValues(folder.required_fields, 'Folder'))
      setOptionalFields(initializeMetaValues(folder.optional_fields, 'Folder'))
    })
  }

  return (
    <div>
      <Modal
        title={
          massEditing
            ? t('edit_multiple_documents')
            : documentId && documentId > 0
              ? t('edit_item', { item: t('document') })
              : t('new_document')
        }
        closeModal={close}
        show={show}
        maxWidth={800}
      >
        <Input
          block={true}
          label={t('title')}
          onChange={onTitleChange}
          value={title}
          required={true}
          errorMessage={titleErrorMessage}
        />
        <Selector
          items={documentTypes}
          selectedItemId={documentTypeId ? documentTypeId : 0}
          onSelect={onDocumentTypeChange}
          label={t('document_type')}
          dataFields={['name']}
          required={isBlueFolder}
          fontSize={'sm'}
          fontWeight={'bold'}
          errorMessage={documentTypeError}
        />
        {(massEditing || documentId) && (
          <div className={'pl-2 mt-2 flex items-center w-1/2'}>
            <Checkbox
              disableTab
              onChange={() => setResetRecordId(!resetRecordId)}
              valueProp={resetRecordId}
              id={'reset_record_id'}
            />
            <label
              className={`pl-2 font-medium text-sm leading-5 text-gray-700 first-capitalize`}
              htmlFor={'reset_record_id'}
            >
              {t('reset_record_id_of_document')}
            </label>
          </div>
        )}

        {resetRecordId && (
          <p className="first-capitalize text-red text-sm px-2">
            {t('document_number_update_confirm')}
          </p>
        )}
        <div className={'flex flex-row flex-wrap'}>
          <div className={'w-full lg:w-1/2'}>
            <Selector
              items={disciplines}
              selectedItemId={disciplineId}
              onOpenSelectFunction={onDisplineOpen}
              loading={displineLoading}
              onSelect={onDisciplineChange}
              label={t('discipline')}
              dataFields={['shortName', 'name']}
              fontSize={'sm'}
              fontWeight={'bold'}
              onCancel={onDisplineUserClear}
              cancelButton={true}
            />
          </div>
          <div className={'w-full lg:w-1/2'}>
            <Selector
              items={users}
              selectedItemId={responsibleId}
              onOpenSelectFunction={getUsers}
              onSelect={onResponsibleChange}
              label={t('responsible')}
              dataFields={['firstName', 'lastName']}
              fontSize={'sm'}
              userSelector={true}
              fontWeight={'bold'}
              loading={usersLoading}
              onCancel={onDisplineUserClear}
              cancelButton={true}
            />
          </div>
        </div>
        {!folder && (
          <Selector
            items={folders}
            selectedItemId={selectedFolder ? selectedFolder.id : 0}
            onSelect={onFolderChange}
            label={t('folder')}
            dataFields={['name']}
            required
            fontSize={'sm'}
            fontWeight={'bold'}
          />
        )}
        {currentFolder && (
          <div className="pr-4 pb-4 mt-4">
            {currentFolder.required_fields &&
              currentFolder.required_fields.length > 0 && (
                <DocumentMetaDataFields
                  title={''}
                  onFieldsUpdate={onFieldsRequiredUpdate}
                  fields={requiredFields}
                  errorMessage={metaDataErrorMessage}
                  required={true}
                />
              )}
            {currentFolder.optional_fields &&
              currentFolder.optional_fields.length > 0 && (
                <DocumentMetaDataFields
                  title={''}
                  onFieldsUpdate={onOptionalFieldsUpdate}
                  fields={optionalFields}
                  required={false}
                />
              )}
          </div>
        )}
        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={close}>
            {t('cancel')}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            onClick={massEditing ? onMassEdit : saveDocument}
          >
            {massEditing || (documentId && documentId > 0)
              ? t('update')
              : t('create')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DocumentForm
