import { UseQueryResult } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import moment from 'moment'
import { ReactElement } from 'react'
import { Highlight } from 'react-pdf-highlighter-extended'
import { Optional } from 'utility-types'
import { IRecurringEvent } from 'src/components/event/RecurringEvent'
import { ISystemUploadSummary } from 'src/components/system/SystemUploadSummary'
import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'

import { IDocumentUploadSummery } from 'src/document/components/FileUpload/docUpload/DocumentUploadSummery'
import { IValidationResponse } from 'src/document/components/FileUpload/types'
import { IFileContainer } from 'src/document/types/IFileContainer'
import { IDocumentType, IFlowData } from 'src/document/types/IFlow'
import { InvitationStatus } from 'src/page/systematic-completion/tests/TestAcceptanceModal'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import { ImportDataFiled } from '../document/components/FileUpload/selectors/DataSelectors'
import { IMetaValue } from '../document/types/IMetaData'
import {
  IMainProcessResponse,
  IProjectKeypointResponse,
} from './ProcessService'

export type Languages = 'no' | 'en' | 'is'

export interface IInputErrorMessage {
  for: string
  errorMessage: string
}
export interface IMenu {
  title: string
  activeTab: number
  activeSubmenu?: number
}

export interface IInterval {
  startTime: moment.Moment
  endTime: moment.Moment
  labelWidth: number
  left: number
}

export interface INotfication {
  titile: string
  body: string
  link?: string
  log?: IActivityLog
}

export interface IUser {
  user: IUserData
  organization: IOrganizationData
}

export interface IUserData {
  id: number
  firstName: string
  lastName: string
  email: string
  telephoneNumber: string
  key: string
  isSystemAdmin: boolean
  contracts?: IContract[]
  projects?: IProjectData[]
  disciplines?: IDiscipline[]
  roles?: IRole[]
  project_id?: number
  contract_id?: number
  discipline_id?: number
  role_id?: number
  isQualityAdmin?: boolean
  isProjectAdmin?: boolean
  isContractAdmin?: boolean
  isProjectRiskAdmin?: boolean
  isBaselineAdmin?: boolean
  meetingModuleAccess?: boolean
  statusManager?: boolean
  image_url?: string
  isActive?: boolean
  accessExpire?: moment.Moment | null
  responsible_tasks?: ITask[]
  user_project_mappers?: any[]
  user_contract_mappers?: any[]
  email_notification?: boolean
  language?: string
  role_permissions?: { [module in Module]: IRolePermission }
}

export type Module = 'object' | 'document'

export interface ISingleUser {
  id: number
  firstName: string
  lastName: string
  email: string
  telephoneNumber: string
  image_url?: string
}

export interface IQuestion {
  id: number
  parent_type: string
  parent_id?: number
  project_id?: number
  question: string
  response_type: string
  created_at: moment.Moment
  updated_at: moment.Moment
  current_user_response?: any
  average_rating?: string
  poll_responses?: any[]
}

export interface IQuestionResponse {
  rate?: number
  description?: string
  question_id?: number
}

export interface IUserAccess {
  user_id: number
  isSystemAdmin: boolean
  isProjectAdmin: boolean
  isContractAdmin: boolean
  isProjectRiskAdmin: boolean
  isQualityAdmin: boolean
  isBaselineAdmin: boolean
  meetingModuleAccess: boolean
  status_manager: boolean
  isConstructionManager: boolean
  role_id: number
  contract_id: number
  discipline_id: number
  project_mapper_id?: number
  contract_mapper_id?: number
  accessExpire?: moment.Moment
}

export interface IProjectData {
  project: IProject
  isProjectAdmin: boolean
  isProjectRiskAdmin: boolean
  isBaselineAdmin: boolean
  meetingModuleAccess: boolean
  status_manager: boolean
  isConstructionManager: boolean
}

export interface IProject {
  id: number
  projectName: string
  projectNumber: string
  organization_id?: number
  areal: string
  gjennomforingsstrategi: string
  budsjett: string
  oppdragsgiver: string
  image_url: string
  logo?: string
  startDate: moment.Moment
  endDate: moment.Moment
  planning_module: boolean
  construction_module: boolean
  systematic_completion_module: boolean
  systems_module: boolean
  document_control_module: boolean
  risk_module: boolean
  improvement_module: boolean
  meeting_module: boolean
  fdvu_module: boolean
  task_module: boolean
  is_infrastructure_project: boolean
  new_dashboard: boolean
}

export interface IShareToken {
  id: number
  project_id: number
  resource: string
  expiry: number
  expiration_time: string
  token: string
  comment: string
  active: boolean

  created_at: string
  updated_at: string
  created_by: IUserData
}

export interface IContract {
  id: number
  contractName: string
  contractNumber: string
  company_name: string
  contractSize: string
  created_at?: string
  updated_at?: string
  disciplines?: IDiscipline[]
}

export interface IDisciplineData {
  id: number
  name: string
  shortName: string
  color: string
  shape: string
  sequence: number
  contract_id: number
  project_id?: number
}

export interface IDiscipline {
  id: number
  name: string
  shortName: string
  color: string
  shape: string
  sequence: number
  contract_id: number
  project_id?: number
  created_at?: string
  updated_at?: string
  contract?: IContract
}

export interface ITaskType {
  id: number
  taskTypeName: string
  description: string
  flow_id?: number
  flow?: IFlowData
  system_type_group_id?: number | null
  system_type_group?: ISystemTypeGroup
  optional_fields?: IMetaField[]
  has_tasks?: boolean
  created_at?: string
  updated_at?: string
}

export interface ITaskTypeTreeResponse {
  data_type: string
  icon: string
  key: number
  label: string
  nodes: any[]
  numbers: number
}

export interface ITeam {
  id: number
  name: string
  sequence: number
  main_process_id: number | undefined
  main_process?: IMainProcess
  deliveries?: IDelivery[]
  key_points?: IProjectKeypointResponse[]
}

export interface IPhase {
  id: number
  phaseName: string
  description: string
  sequence?: number
}

export interface IRolePermission {
  module: string
  permission: string
}

export interface IRole {
  id: number
  roleName: string
  description: string
  role_permissions: IRolePermission[]
}

export interface ITaskData {
  id: number
  title: string
  description: string
  status: string
  taskType: string
  deadline: moment.Moment
  endDate?: moment.Moment | null
  closed_date?: moment.Moment
  responsible_id: number
  parent_id: number
  parent_type: string
  discipline_id: number
  contract_id?: number | undefined
  system_id: number | undefined | null
  test_id?: number | null
  test_case_id?: number | null
  location_id: number | undefined | null
  construction_locomotive_id?: number
  control_area_id?: number
  control_area?: IControlArea
  main_process_id?: number
  main_process?: IMainProcess
  project_id: number
  task_type_id: number | null | undefined
  task_step_id?: number
  is_subtask?: boolean
  task_type?: ITaskType
  reporter_id?: number
  update_access?: boolean
  delete_access?: boolean
  record_id?: string
  parent?: any
  responsible?: IUserData
  reporter?: IUserData
  discipline?: IDiscipline
  contract?: IContract
  system?: ISystem
  location?: IRoom
  documents?: IDocument[]
  duration?: number
  startTime?: number | null
  improvement_id?: number | undefined
  improvement?: IImprovement
  category?: string
  created_at?: string
  updated_at?: string
  tag_id?: number | null
  tag?: ITag
  construction_locomotive?: IConstructionWagon
  optional_fields?: IMetaValue[]
  system_type_group_id?: number | null | undefined
  delivery_id?: number
  delivery?: IDelivery
  related_tasks?: ITask[]
  related_task_ids?: number[]
  related_systems?: ISystem[]
  related_system_ids?: number[]
  isActive?: boolean
}

export interface ITaskDataStep extends ITaskData {
  task_steps: ITaskStep[]
}
export interface ITaskStep {
  id: number
  activity_logs: IActivityLog[]
  comments: IComment[]
  created_at: string
  deadline: string
  documents: IDocument[]
  end_time: string
  name: string
  project_id: number
  responsible: ISingleUser
  responsible_id: number
  responsible_users: ISingleUser[]
  closed_by: ISingleUser
  conclusion: string
  sequence: number
  start_time: string
  step_id: number
  task_id: number
  tasks: ITaskData[]
  updated_at: string
}

export interface ITestApprovals {
  comment: string
  created_at?: string
  updated_at?: string
  documents: IDocument[]
  id: number
  user: IUserData
  status: string
  test_execution_id: number
}

export interface ITest {
  id: number
  name: string
  status: string
  description: string
  record_id?: string
  test_type_id?: number
  execution_date?: moment.Moment
  test_type?: ITestType
  discipline_id?: number | null
  discipline?: IDiscipline
  contract?: IContract
  contract_id?: number | null
  responsible_id?: number | null
  responsible?: IUserData
  systems?: ISystem[]
  system_ids?: number[]
  dependent_on_tests?: any[]
  invitation_text?: string
  invitation_subject?: string
  test_documents?: ITestDocument[]
  location?: string
  single_test_participants?: ITestParticipant[]
  dependent_ids?: number[]
  dependent_on_ids?: number[]
  created_at?: string
  updated_at?: string
  system_type_group_id?: number
  system_type_group?: ISystemTypeGroup
  user_defined_values?: IMetaValue[]
  optional_fields: IMetaValue[]
}

export interface ITestDocument {
  id: number
  project_id?: number
  test_id?: number
  test_type_id?: number
  document_name: string
  is_active: boolean
  is_uploaded?: boolean
  document?: IDocument
  file_url?: string
  created_at?: string
  updated_at?: string
  test_document_type_id?: number
  system_id?: number
  status?: TestDocumentStatuses
  system?: ISystem
  file_container?: IFileContainer
  file_container_id?: number
  deadline?: string | null
}

export enum TestDocumentStatuses {
  NOT_UPLOADED = 'not_uploaded',
  UPLOADED = 'uploaded',
  NEEDS_REVISION = 'needs_revision',
  APPROVED = 'approved',
  NOT_APPLICABLE = 'not_applicable',
}

export interface IImage {
  created_at?: string
  description?: string
  document_type_id?: number
  source?: string
  id: number
  name?: string
  parent_id: number
  parent_type: string
  project_id: number
  updated_at?: string
  url: string
  documents?: any[]
  document_type?: IDocumentType
}

export interface ITestTasksStat {
  closedTasks: number
  expiredTasks: number
  openTasks: number
  totalTasks: number
}

export enum TestSetupStatuses {
  APPROVED = 'approved',
  FAILED = 'failed',
  NOT_CHECKED = 'not_checked',
  NOT_APPLICABLE = 'not_applicable',
}

export interface ITestSetup {
  id?: number
  project_id?: number
  test_id?: number
  test_type_id?: number
  setup_name: string
  setup_status_color?: string
  is_active: boolean
  is_completed?: boolean
  tasks?: ITask[]
  created_at?: string
  updated_at?: string
  test_setup_type_id?: number
  status?: TestSetupStatuses
}

export interface ICheckListItem {
  id?: number
  checklist_id?: number
  project_id?: number
  name: string
  status?: string
  description?: string
  order?: number
  status_color?: string
  is_active?: boolean
  is_completed?: boolean
  tasks?: ITask[]
}

export interface ITestType {
  id?: number
  name: string
  description: string
  record_id?: string | null
  test_documents?: ITestDocument[]
  test_setups?: ITestSetup[]
  is_default?: boolean
  has_test_documents?: boolean
  has_test_setups?: boolean
  test_checklist_ids?: number[]
  created_at?: string
  updated_at?: string
}

export interface IPaginatedTestCases {
  test_cases: ITestCase[]
  pages: number
}

export interface ITestCase {
  id?: number
  record_id?: string
  test_id?: number
  name: string
  description: string
  occurrence: string
  order?: number
  requirement: string
  comment: string
  reference: string
  result: string
  test_case_group_id?: number
  approved?: boolean | null
  is_ready_to_be_used?: boolean
  is_template?: boolean
  test?: ITest
  tasks?: ITaskData[]
  test_case_executions?: ITestCaseExecution[]
  last_test_case_execution?: ITestCaseExecution
  created_at?: string
  updated_at?: string
}

export interface ITestCaseExecutionCounts {
  failed_test_cases: number
  irrelevant_test_cases: number
  passed_test_cases: number
  total_test_cases: number
  unchecked_test_cases: number
}

export interface ITasksCounts {
  done_tasks: number
  expired_tasks: number
  open_tasks: number
  total_tasks: number
}

export interface ITestExecution {
  id?: number
  record_id?: string
  status: string
  step: string
  test_planned_start?: moment.Moment
  test_planned_end?: moment.Moment
  test_started: moment.Moment
  test_ended: moment.Moment
  closed_date?: moment.Moment
  location?: string
  completed_date?: moment.Moment
  comment: string
  revision?: string
  test_id: number
  project_id: number
  test: ITest
  created_at?: string
  updated_at?: string
  documents: IDocument[]
  user_approval?: TestApprovalStatus
  test_case_execution_counts?: ITestCaseExecutionCounts
  responsible?: IUserData
  single_test_participants?: ITestParticipant[]
  tasks_counts: ITasksCounts
  signatures: Signature[]
  tasks: ITask[]
  test_case_executions: ITestCaseExecution[]
  executed_externally?: boolean
  closed_by_id?: number | null
  closed_by?: ISingleUser
}

export interface ITestCaseExecution {
  id?: number
  comment: string
  description: string
  name: string
  order: number
  requirement: string
  executed_date: moment.Moment
  test_case: ITestCase
  tasks: ITask[]
  status: string
  project_id: number
  test_execution_id: number
  test_case_group_execution_id: number
  test_case_id: number
  created_at: string
  updated_at: string
  documents?: IDocument[]
  images?: IImage[]
}

export interface TestApprovalStatus {
  can_approve: boolean
  has_approved?: any
  user_approval?: any
  total_approval_requests: number
  completed_approval_requests: number
  test_approved_participants: number
  test_rejected_participants: number
}

export interface TestApproval {
  id: number
  comment?: string
  status: string
  status_date?: string
  test_execution_id: number
  test_id: number
  user_id: number
  project_id: number
  created_at: string
  updated_at: string
  signatures: Signature[]
  test_execution: ITestExecution
  documents: IDocument[]
  user: IUserData
}

export interface Signature {
  id: number
  comment?: any
  signature: string
  signed_by_id: number
  signature_date: Date
  parent_id: number
  parent_type: string
  project_id: number
  created_at: string
  updated_at: string
}

export interface IPaginatedTasks {
  pages: number
  tasks: ITask[]
}

export interface IPaginatedTests {
  pages: number
  tests: ITest[]
}

export interface IPaginatedTestTypes {
  pages: number
  test_types: ITestType[]
}

export interface IPaginatedRooms {
  pages: number
  rooms: IRoom[]
}

export interface IPaginatedFloors {
  pages: number
  floors: IFloor[]
}

export interface IPaginatedControlAreas {
  pages: number
  control_areas: IControlArea[]
}

export interface IPaginatedConstructionTrains {
  pages: number
  construction_trains: IConstructionTrain[]
}

export interface IPaginatedConstructionWagons {
  total_items: number
  pages: number
  construction_locomotives: IConstructionWagon[]
}

export interface IStaffingGraphResponse {
  week: number
  year: number
  planned_staffing: number
  actual_staffing: number
}

export interface ITask {
  id: number
  record_id: string
  title: string
  description: string
  status: string
  taskType: string
  deadline: moment.Moment
  endDate: moment.Moment | null
  closed_date: moment.Moment | null
  parent_id: number
  parent_type: string
  discipline: IDiscipline
  discipline_id?: number
  task_type: ITaskType
  responsible: IUserData
  responsible_id?: number
  reporter: IUserData
  update_access: boolean
  delete_access: boolean
  parent?: any
  isActive: boolean
  suggested?: boolean
  approved?: boolean
  improvement?: IImprovement
  optional_fields?: IMetaValue[]
  system_type_group_id?: number
  delivery_id?: number
  delivery?: IDelivery
  first_meeting_id?: number | null
  last_meeting_id?: number | null
  task_type_id?: number
}

export interface ITaskDataList {
  tasks: ITask[]
  chart_data: any[]
  bar_data: any[]
}

export interface IRoom {
  id?: number
  function_number: string
  function_name: string
  sub_function_number: string
  sub_function_name: string
  functional_room_number: string
  room_name: string
  geographical_room_number: string
  planned_area: number
  built_area: number
  floor_id: number
  floor?: IFloor
  section?: ISection
  project_id?: number
  section_id?: number
  room_status?: string
  main_function_name?: string
  part_function_name?: string
  control_area?: IControlArea
  control_area_id?: number
  room_function_number?: string
}

export interface ISystemConnectionType {
  id?: number
  name: string
  code: string
  description: string
  project_id: number
  created_at?: string
  updated_at?: string
}

export interface ISystemType {
  id?: number
  name: string
  description: string
  type_code: string
  system_syntax_category_id?: number
  system_type_group_id?: number
  system_setting_id: number
  project_id: number
  system_type_group?: ISystemTypeGroup
}

export interface ISystemTypeUnique {
  id: number
  name: string
  description: string
  type_code: string
}

export interface ISystemTask {
  id: number
  task_type: SystemTaskTypes
  comment: string
  is_auxiliary: boolean
  discipline_id: number
  system_id: number
}

export interface ISystem {
  error_code?: string
  id: number
  record_id: string
  name: string
  description: string
  room_id?: number
  discipline_ids: number[]
  parent_system_id?: number
  system_type_id?: number
  system_type_unique_id?: number
  file_url?: string
  file_container_ids: number[]
  is_active: boolean
  system_type?: ISystemType
  system_type_unique?: ISystemTypeUnique
  system_type_group?: ISystemTypeGroup
  disciplines?: IDisciplineData[]
  contracts?: IContract[]
  room?: IRoom
  tasks?: ITask[]
  children_systems?: ISystem[]
  connected_systems?: ISystem[]
  images?: IImage[]
  replaced_by_id?: number
  system_status_id?: number
  system_mmi_id?: number
  system_fmi_group_id?: number
  system_fmi_status_mapper_id?: number
  test_work_group?: ITestWorkGroup
  test_work_group_id?: number
  number_of_systems?: number
  test_system_group?: ITestSystemGroup
  test_system_group_id?: number
  test_system_groups?: ITestSystemGroup[]
  test_work_groups?: ITestWorkGroup[]
  test_system_group_ids?: number[]
  replaced_by?: ISystem
  control_area?: IControlArea
  system_status?: IStatus
  system_mmi?: ISystemMMI
  system_fmi_group?: ISystemFMIGroup
  system_fmi_status_mapper?: ISystemFMIStatusMapper
  system_tasks?: ISystemTask[]
  is_in_bim?: boolean
  is_testable: boolean
  is_main_system?: boolean
  location?: string
  location_floor?: string
  location_room?: string
  coverage_floor?: string
  coverage_room?: string
  parent_system?: ISystem
  meta_data?: Record<string, IMetaValue>
  optional_fields: IMetaValue[]
  has_children?: boolean
  file_containers: { id: number; name: string; record_id: string }[]
  is_last_node?: boolean
  is_valid?: boolean
  created_at?: string
  updated_at?: string
  path: string
  starting_from?: number

  level?: number
  test_documents?: ITestDocument[]
  test_setups?: ITestSetup[]
  system_setting_id: number
  user_defined_fields?: IMetaField[]
  user_defined_values?: IMetaValue[]
  open_tasks?: number
  done_tasks?: number
}

export interface ISystemCompact
  extends Pick<ISystem, 'id' | 'record_id' | 'name'> {}

export interface ISystemTypeGroup {
  id?: number
  name: string
  project_id: number
  required_fields: IMetaField[]
  optional_fields: IMetaField[]
  domain: string
}

export interface ISystemRecordUpdate {
  errors: string[]
  is_last_node?: boolean
  is_component?: boolean
  has_child_systems?: boolean
  system_type_id?: number
  parent_system_id?: number
  system_syntax_category_id?: number
  system_record_id?: string
}

export interface ITestChecklist {
  id: number
  name: string
  description?: string
  project_id?: number
  domain?: string
  is_active?: boolean
  is_complete?: boolean
  checklist_items?: ICheckListItem[]
  created_at?: string
  updated_at?: string
}

export interface IValidateResponse {
  parent_system_id?: number
  system_type_id?: number
  system_syntax_category_id?: number
}
export interface IPaginatedSystems {
  pages: number
  systems: ISystem[]
}

export interface IPaginatedSystemTypes {
  pages: number
  system_types: ISystemType[]
}

export interface IPaginatedSystemTypeUniques {
  pages: number
  system_type_uniques: ISystemTypeUnique[]
}

export interface IPaginatedSystemImportHistories {
  pages: number
  system_import_histories: ISystemImportHistory[]
}

export interface ISystemImportHistory {
  created_at?: string
  id: number
  imported_by: IUserData
  imported_by_id: number
  number_of_new_systems: number
  number_of_updated_systems: number
  project_id: number
  updated_at: string
  new_systems?: ISystem[]
  updated_systems?: ISystem[]
}

export interface ISystemMMI {
  id: number
  name: string
  description?: string
  created_at?: string
  updated_at?: string
}

export interface ISystemFMI {
  id: number
  value: number
  description?: string
  created_at?: string
  updated_at?: string
}

export interface ISystemFMIGroup {
  id: number
  name: string
  description: string
  created_at?: string
  updated_at?: string
  system_fmis?: ISystemFMI[]
  system_fmi_ids?: number[]
}

export type StatusNumberCategory =
  | 'tasks'
  | 'deliveries'
  | 'key_points'
  | 'mile_stones'
  | 'risks'
  | 'discipline_tasks'
  | 'discipline_deliveries'
  | 'discipline_key_points'
  | 'discipline_mile_stones'
  | 'discipline_risks'
  | 'contract_tasks'
  | 'contract_deliveries'
  | 'contract_key_points'
  | 'contract_mile_stones'
  | 'contract_risks'
  | 'project_tasks'
  | 'project_deliveries'
  | 'project_key_points'
  | 'project_mile_stones'
  | 'project_risks'

export type IStatusNumber = {
  [category in StatusNumberCategory]: {
    open: number
    expired: number
    done: number
    behind_schedule?: number
    closed_risk_occured?: number
    closed_risk_not_occured?: number
    not_relevant?: number
  }
}

export interface ISystemFMIStatusMapper {
  id: number
  approved_fmi?: number
  suggested_fmi?: number
  created_at?: string
  updated_at?: string
  approved_by_id?: number
  suggested_by_id?: number
  system_id?: number
}

export interface ISystemSyntaxHierarchy {
  id: number
  localId?: string
  syntax_string: string
  system_regex?: string
  system_type_regex?: string
  level?: number
  system_setting_id: number
  file_uploaded?: string
  created_at?: string
  updated_at?: string
  name: string
  spacer: string
  number_of_digits: number | null
  category_count: number
  sequence?: number
  constant_string?: string
  constant_string_regex?: string
  variant: string
  is_component?: boolean
  use_full_counter?: boolean
  use_full_category?: boolean
  is_optional?: boolean
  mid_spacer?: string
}
export interface ISystemSetting {
  id: number
  system_syntax: string
  project_id: number
  created_at?: string
  updated_at?: string
  name: string
  is_active: boolean
  has_no_syntax?: boolean
  system_syntax_categories: ISystemSyntaxHierarchy[]
}

export interface IOrganizationData {
  created_at: string
  id: number
  organizationNumber: number
  organizationName: string
  updated_at: string
}

export interface IDynamicListColumn {
  columnIds: string[]
  columnTitles: string[]
}

export interface IChangeLog {
  reason: string
  newEndTime: string
  oldEndTime: string
}

export interface IMeetingTopic {
  id: number
  record_id?: string
  title: string
  status: string
  description: string
  meeting_series_id?: number
  meeting_id?: number | null
  first_meeting_id?: number | null
  last_meeting_id?: number | null
  closed_date?: string
  created_at?: string
  updated_at?: string
  tasks?: ITask[]
  done_children?: number
  expired_children?: number
  suggested_children?: number
}

export interface IMeetingTasksStat {
  totalTasksPerMeeting: number
  totalActionsPerMeeting: number
  totalDeciesionsPerMeeting: number
  totalInformationPerMeeting: number
  openTasksPerMeeting: number
  closedTasksPerMeeting: number
  expiredTasksPerMeeting: number
}

export interface IMeetingSeriesTasksStat {
  totalTasksPerMeetingSeries: number
  openTasksPerMeetingSeries: number
  closedTasksPerMeetingSeries: number
  expiredTasksPerMeetingSeries: number
  totalActionsPerMeetingSeries: number
  totalDecisionsPerMeetingSeries: number
  totalInformationPerMeetingSeries: number
}

export interface IMeeting {
  id: number
  record_id?: string
  title: string
  description: string
  status: string
  caseCatagory: string
  meetingPlace: string
  isConfidential: boolean
  additionalInformation: string
  updated_at: moment.Moment
  created_at: moment.Moment
  meetingDate: moment.Moment
  meetingEndDate: moment.Moment
  meetingArchiveDate: moment.Moment
  discipline_id: number
  nextMeeting?: moment.Moment
  createdBy: ISingleUser
  meetingLeader?: ISingleUser
  meetingRecorder?: ISingleUser
  meetingLeader_id?: number
  meetingRecorder_id?: number
  meeting_series_id?: number
  project_id: number
  meetingSeries?: IMeetingSeriesData
  meeting_series?: IMeetingSeriesData
  single_meeting_participants: IMeetingUser[]
  topics: IMeetingTopic[]
  meeting_access?: boolean
  documents?: IDocument[]
  invitation_text?: string
  invitation_subject?: string
  discipline?: IDiscipline
}

export interface IMeetingData {
  id: number
  record_id?: string
  title: string
  description: string
  status: string
  meetingPlace: string
  isConfidential: boolean
  meetingDate: moment.Moment
  meetingEndDate: moment.Moment
  meetingArchiveDate?: moment.Moment
  nextMeeting?: moment.Moment
  project_id: number
  createdBy_id: number
  meetingLeader_id?: number
  meetingRecorder_id?: number
  meeting_series_id?: number
  single_meeting_participants: IMeetingUser[]
  recurrence_rule?: IRecurringEvent
  discipline_id: number
}

export interface IMeetingUser {
  id: number
  user_id?: number
  firstName: string
  lastName: string
  isPresent: boolean
  email: string
  image_url?: string
}

export interface ITestParticipant {
  id?: number
  user_id?: number
  firstName: string
  lastName: string
  invitation_sent?: InvitationStatus
  is_present?: boolean
  email: string
  test_execution_id?: number
  user?: ISingleUser
  comment?: string
}

export interface IMeetingSeries {
  id: number
  title: string
  description: string
  created: string
  updated_at: moment.Moment
  created_at: moment.Moment
  last_meeting: any
  next_meeting: any
  discipline_id: number
  createdBy_id: number
  status: string
  record_id: string
  isConfidential: boolean
  createdBy: ISingleUser
  users: ISingleUser[]
  meeting_series_access?: boolean
  discipline: IDisciplineData
  recurrence_rule?: IRecurringEvent
  meetingPlace?: string
  meetingLeader?: ISingleUser
  meetingLeader_id?: number
  meetingRecorder?: ISingleUser
  meetingRecorder_id?: number
  invitation_subject?: string
  invitation_text?: string
}

export interface IMeetingSeriesData {
  id: number
  title: string
  description: string
  status: string
  record_id?: string
  isConfidential: boolean
  createdBy_id: number
  users: ISingleUser[]
  recurrence_rule?: IRecurringEvent
  meetingRecorder_id?: number
  meetingLeader_id?: number
  first_meeting_date?: string
  meeting_duration?: number
  meetingPlace?: string
  update_future_meetings?: boolean
}

export interface IRiskData {
  id: number
  deadline: moment.Moment
  title: string
  risk_owner_id: number
  main_process_id: number
  phase_id?: number
  discipline_id: number
  contract_id: number
  team_id?: number
  risk_group_id?: number
  key_point_id?: number
  status: string
  incident: string
  cause: string
  impact: string
  documents?: IDocument[]
  is_top_ten?: boolean
  expired_children?: number
  done_children?: number
  open_children?: number
  update_access?: boolean
  delete_access?: boolean
  project_id?: number
  risk_owner?: any
  main_process?: any
  category?: string
  tag_id?: number | null
  tag?: ITag
}

export interface IRiskEstimate {
  id?: number
  upper_value: number
  lower_value: number
  avarage_value: number
  comment: string
  expected_value?: number
  expected_value_with_probability?: number
  user_id?: number
  user?: ISingleUser
}

export interface IRiskArea {
  id?: number
  name: string
  description: string
  quantity_unit: string
  include_risk_estimation: boolean
  risk_possibilities?: IRiskPossibility[]
  risk_probabilities?: IRiskProbability[]
  risk_threats?: IRiskThreat[]
}

export interface IRiskPossibility {
  id: number
  name: string
  from: string
  to: string
  order?: number
}

export interface IRiskProbability {
  id: number
  name: string
  from: number
  to: number
  expected: number
  order?: number
}

export interface IRiskThreat {
  id: number
  name: string
  from: string
  to: string
  order?: number
}

export interface IRiskGroup {
  title: string
  project_id: number
  is_project_risk_group: boolean
  is_active: boolean
  id: number
  created_at: string
  updated_at: string
  risks: IRisk[]
  record_id?: string
}

export interface IRiskStatus {
  id: number
  name: string
  order: number
  isActive: boolean
}

export interface IMainProcess {
  id?: number
  record_id?: string
  name: string
  is_production_process?: boolean
}

export interface ITag {
  id?: number
  record_id?: string
  name: string
  description?: string
  sequence?: number
}

export interface ITestSystemGroup {
  id?: number
  record_id?: string
  title?: string
  description?: string
  test_work_group_id?: number
  percent_completed?: number
  discipline_id?: number
  discipline?: IDiscipline
  contract_id?: number
  responsible_id?: number
  test_system_group_status_id?: number
  test_system_group_status?: IStatus
  responsible?: IUserData
  contract?: IContract
  system_ids?: number[]
  created_by?: IUserData
  updated_by?: IUserData
  test_work_group?: ITestWorkGroup
  testSystemGroups?: ITestSystemGroup
  meta_data?: Record<string, IMetaValue>
  optional_fields?: IMetaValue[]
  system_type_group_id?: number
  created_at?: string
  updated_at?: string
  open_tasks?: number
  done_tasks?: number
  planned_start?: moment.Moment
  planned_end?: moment.Moment
  actual_start?: moment.Moment
  actual_end?: moment.Moment
}

export interface ITestWorkGroup {
  id?: number
  record_id: string
  title: string
  description: string
  test_work_group_status_id?: number
  location: string
  priority: string
  percent_completed: number
  discipline_id: number
  system_ids?: number[]
  contract_id: number
  test_work_group_status?: IStatus
  discipline?: IDiscipline
  contract?: IContract
  created_by?: IUserData
  updated_by?: IUserData
  meta_data?: Record<string, IMetaValue>
  optional_fields?: IMetaValue[]
  system_type_group_id?: number
  created_at?: string
  updated_at?: string
  open_tasks?: number
  done_tasks?: number
  planned_start?: moment.Moment
  planned_end?: moment.Moment
  actual_start?: moment.Moment
  actual_end?: moment.Moment
}

export interface IStatus {
  id?: number
  name: string
  description: string
  status: string
  parent_type: string
  created_at?: string
  updated_at?: string
}

export interface IRiskGroupIds {
  id: number
  name: string
  recordId: string
}

export interface IRisk {
  id: number
  record_id: string
  deadline: moment.Moment
  title: string
  risk_owner: ISingleUser
  main_process: IMainProcess
  risk_owner_id: number
  main_process_id: number
  discipline_id: number
  contract_id: number
  risk_status_id: number
  key_point_id: number
  phase_id?: number
  team_id?: number
  status: string
  incident: string
  cause: string
  impact: string
  phase: IPhase
  risk_group_id: number
  assessments: IRiskAssessmentData[]
  tasks: ITask[]
  is_top_ten: boolean
  is_project_risk: boolean
  update_access?: boolean
  delete_access?: boolean
  initial_assessment_id?: number
  initial_assessment?: IRiskAssessmentData
  last_assessment_id?: number
  last_assessment?: IRiskAssessmentData
  main_processe?: any
  team?: any
  key_point?: any
  risk_group?: any
  open_children?: number
  category?: string
  tag_id?: number
  tag?: ITag
  created_at?: string
  updated_at?: string
  discipline?: IDiscipline
  contract?: IContract
  is_active: boolean
}

export interface IFilteredRisksResponse {
  pages: number
  risks: IRisk[]
  total_expected_value: number
  total_expected_value_with_probability: number
}

export interface IRiskAssessmentData {
  id: number
  risk_id?: number
  description: string
  risk_area_id: number
  risk_probability_id: number
  risk_possibility_id: number | null
  risk_threat_id: number | null
  risk_area?: IRiskArea
  risk_possibility?: IRiskPossibility
  risk_threat?: IRiskThreat
  risk_probability?: IRiskProbability
  isInitialAssessment: boolean
  color?: string
  possibilityIndex?: number
  messureIndex?: number
  riskIndex?: number
  upper_value?: number
  lower_value?: number
  average_value?: number
  expected_value?: number
  expected_value_with_probability?: number
  include_risk_estimation: boolean
  record_id?: string
  user?: IUserData
  created_at?: string
}

export enum IDeliveryStatus {
  OPEN = 'open',
  DONE = 'done',
}

export enum CanvasType {
  DISCIPLINE,
  MAIN_PROCESS,
  TEMA,
  PLANNING_DISCIPLINE,
  PLANNING_MAIN_PROCESS,
  RESPONSIBLE,
  TEST_TYPE,
  TEST_DISCIPLINE,
}

export enum TimelineFilterTypes {
  KEY_POINT_DISCIPLINE = 'keypointDiscipline',
  KEY_POINT_MAIN_PROCESS = 'keypointMainprocess',
  KEY_POINT_TEMA = 'keypointTema',
  DELIVERY_DISCIPLINE = 'deliveryDiscipline',
  DELIVERY_MAIN_PROCESS = 'deliveryMainprocess',
  DELIVERY_TEMA = 'deliveryTema',
  TASK_DISCIPLINE = 'taskDiscipline ',
  TASK_MAIN_PROCESS = 'taskMainprocess',
  TASK_RESPONSIBLE = 'taskResponsible',
  TASK_GRID = 'taskGrid',
  MILESTONE_DETAIL = 'milestoneDetail',
  MAIN_PROCESS_MAIN_PROCESS = 'mainprocess',
  MILESTONE_TIMELINE = 'milestoneTimeline',
  MILESTONE_TIMELINE_TEMA = 'milestoneTimelineTema',
  PROCESS_TIMELINE = 'processtimeline',
  CONSTRUCTION_TIMELINE = 'constructionTimeline',
  CONSTRUCTION_GRAPH = 'constructionGraph',
  TEST_TYPE_CANVAS = 'testTypeCanvas',
  TEST_DISCIPLINE_CANVAS = 'testDisciplineCanvas',
}

export enum KPIFilterTypes {
  KPI_LANDING_PAGE = 'KPILandingPage',
  KPI_STAT_PAGE = 'KPIStatPage',
  COMMON_PPU_PAGE = 'commonPPUPage',
  PROJECT_BASELINE_CHARTS_PAGE = 'ProjectBaselineChartsPage',
  CASE_TYPES_STAT_PAGE = 'CaseTypesStatPage',
}

export enum ComponentToRenderTypes {
  DEFAULT = '',
  RELOAD = 'RELOAD',
  TASKS = 'user_tasks',
  DELIVERIES = 'user_deliveries',
  KEYPOINTS = 'user_key_points',
  RISKS = 'user_risks',
  MILESTONE = 'user_mile_stones',
  DISCIPLINE_TASKS = 'discipline_tasks',
  DISCIPLINE_DELIVERIES = 'discipline_deliveries',
  DISCIPLINE_KEYPOINTS = 'discipline_key_points',
  DISCIPLINE_RISK = 'discipline_risks',
  DISCIPLINE_MILESTONE = 'discipline_mile_stones',
  CONTRACT_TASKS = 'contract_tasks',
  CONTRACT_DELIVERIES = 'contract_deliveries',
  CONTRACT_KEYPOINTS = 'contract_key_points',
  CONTRACT_RISKS = 'contract_risks',
  CONTRACT_MILESTONE = 'contract_mile_stones',
  PROJECT_TASKS = 'project_tasks',
  PROJECT_DELIVERIES = 'project_deliveries',
  PROJECT_KEYPOINTS = 'project_key_points',
  PROJECT_RISK = 'project_risks',
  PROJECT_MILESTONE = 'project_mile_stones',
  NOTIFICATIONS = 'notifications',
}

export interface IStatusTypes {
  id: string
  name: string
}

export interface ISfranConfig {
  id?: number
  base_url: string
  username: string
  password: string
  network_id: string
}

export interface IProjectConfig {
  id?: number
  current_period: string
  plan_freeze_duration: number
}

export interface IMilestone {
  id: number
  deadline: moment.Moment
  name: string
  status: string
  description: string
  main_process_id: number
  main_process?: IMainProcessResponse
  discipline_id: number
  contract_id?: number | null
  contract?: IContract
  responsible_id: number
  team_id?: number | null
  project_id?: number
  record_id?: string
  baseline?: moment.Moment
  delay?: number
  update_access?: boolean
  delete_access?: boolean
  responsible?: any
  discipline?: any
  team?: any
  tag_id?: number | null
  tag?: ITag
  done_children?: number
  open_children?: number
  expired_children?: number
  behind_schedule_children?: number
  has_meetings?: boolean
  duration?: number
  startTime?: moment.Moment
  created_at?: moment.Moment
  updated_at?: moment.Moment
  closed_date?: moment.Moment
  optional_fields?: IMetaValue[]
  system_type_group_id?: number
  user_defined_values?: IMetaValue[]
  meta_data?: Record<string, IMetaValue>
  documents?: IDocument[]
}

export interface IDelivery {
  baseline?: string
  change_reason?: string
  delay?: number
  id: number
  name: string
  description: string
  assumptions: string
  notes: string
  key_point_id?: number
  main_process_id?: number
  team_id?: number
  discipline_id?: number
  contract_id?: number | null
  contract?: IContract
  improvement_id?: number | null
  improvement?: IImprovement
  startTime?: string
  endTime?: string
  record_id?: string
  status: string
  responsible?: IUserData
  responsible_id: number | null
  user_id?: number
  discipline?: IDiscipline
  key_point?: IKeypoint
  main_process?: IMainProcess
  team?: ITeam
  tag_id?: number | null
  tag?: ITag
  documents?: IDocument[]
  project_id?: number
  update_access?: boolean
  delete_access?: boolean
  copy_from_id?: number
  expired_children?: number
  done_children?: number
  open_children?: number
  has_meetings?: boolean
  expired?: boolean
  duration?: number
  created_at?: moment.Moment
  updated_at?: moment.Moment
  user?: ISingleUser
  optional_fields?: IMetaValue[]
  system_type_group_id?: number
  user_defined_values?: IMetaValue[]
  parent?: any
  mile_stone?: any
  dependent_on?: IDelivery[]
  dependent_on_ids?: number[]
  meta_data?: Record<string, IMetaValue>
  dependent_deliveries?: IDependentDeliveries[]
  start_time?: number
  end_time?: number
}

export interface IDependentDeliveries {
  dependent_delivery_id: number
  dependent_on_delivery_id: number
  id: number
  dependent_on_delivery: IDelivery
}

export interface IDeliveryData {
  deliveries: IDelivery[]
  chart_data: any[]
}

export interface IKeypoint {
  id: number
  endTime: string
  baseline?: string
  name: string
  status: string
  description: string
  assumptions: string
  discipline?: IDiscipline
  color?: string
  shape?: string
  record_id: string
  main_process_id?: number
  main_process?: IMainProcess
  discipline_id?: number
  contract_id?: number | null
  team_id?: number | null
  tag_id?: number | null
  tag?: ITag
  improvement_id?: number | null
  mile_stone_id?: number | undefined
  responsible_id?: number | undefined
  documents?: IDocument[]
  user?: ISingleUser
  project_id?: number
  copy_from_id?: number
  duration?: number
  optional_fields?: IMetaValue[]
  system_type_group_id?: number
  start_time?: number
  end_time?: number
}

export interface IComment {
  id: number
  content: string
  created_at: string
  parent_id: number | string
  parent_type: string
  status?: string
  user?: IUserData
  documents: IDocument[]
  pendingFileNames?: string[]
}

export enum ActivityType {
  SYSTEM_NOTIFICATION = 'SYSTEM_NOTIFICATION',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  IMPORT = 'IMPORT',
  CREATE_GROUP = 'CREATE_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  DOCUMENT_DELETE = 'DOCUMENT_DELETE',
  COMMENT = 'COMMENT',
  DELETE = 'DELETE',
  RESPONSIBLE_ASSIGNMENT = 'RESPONSIBLE_ASSIGNMENT',
  COMMENT_MENTION = 'COMMENT_MENTION',
  CREATED_FMI_SUGGESTION = 'CREATED_FMI_SUGGESTION',
  APPROVED_FMI_SUGGESTION = 'APPROVED_FMI_SUGGESTION',
  DECLINED_FMI_SUGGESTION = 'DECLINED_FMI_SUGGESTION',
}

export interface IActivityLog {
  id: number
  changed_by: string
  activity_type: ActivityType
  filedName: string
  oldValue: string
  newValue: string
  created_at: string
  parent_id: number
  parent_type: string
  status: string
  user?: IUserData
  parent?: any
  project_id?: number
  changed_fields?: Array<{
    field_name: string
    old_value: string
    new_value: string
  }>
  title?: string
  model?: string
  is_read?: boolean
  parent_title: string
  document_id?: number
}

export interface IDocument {
  id: number
  fileName: string
  contentType: string
  fileUrl: string
  signed_url?: string
  created_at?: string
  parent_id: number
  parent_type: string
  delete_access: boolean
  user?: IUserData
  updated_at: string
}

export interface ICanvasDuration {
  canvasStartTime: Date
  canvasEndTime: Date
}

export interface ICanvasTime {
  canvasStartTime: number
  canvasEndTime: number
}

export interface IMatrixCellConfig {
  id: string
  uncertaintyType: string
  lValue: number
  cValue: number
  color: string
}

export interface IPPUGoal {
  id: string
  data_type: string
  goal: number
  plan_freeze_period: number
  active: boolean
  calculation_time: string
  calculation_day?: number
  created_at?: string
  updated_at?: string
}

export interface IMatrixData {
  rowSize: number
  columnSize: number
  matrixThreatConfigData: IMatrixCellConfig[]
  matrixPossibilityConfigData: IMatrixCellConfig[]
}

export interface IImprovement {
  id: number
  title: string
  background: string
  current_situation: string
  status: string
  priority: number
  record_id?: string
  description?: string
  measure?: string
  effect?: string
  deadline?: moment.Moment
  improvement_cost?: string
  improvement_effect?: string
  decison_description?: string
  user_id?: number
  responsible_id?: number
  main_process?: IMainProcess
  project_id?: number
  responsible?: IUserData
  user?: IUserData
  created_at?: moment.Moment
  update_access?: boolean
  delete_access?: boolean
  main_process_id?: number
  countermeasures?: string
  plan?: string
  profitability?: string
}
export interface IImprovementsData {
  completed: IImprovement[]
  in_progress: IImprovement[]
  open_improvement: IImprovement[]
}

export interface IEmail {
  to: any
  from: string
  subject?: string
  text?: string
  html?: string
}

export interface IWagonTaskStats {
  done: number
  percent_completed: 0
  total: 1
}
export interface IConstructionWagon {
  id: number
  record_id: string
  title: string
  actual_staffing?: number
  actual_work_hours?: number
  number_of_checklists?: number
  status: string
  status_color?: string
  color?: string
  hand_over_color?: string
  train_color?: string
  vogn_color?: string
  startTime: moment.Moment
  endTime?: moment.Moment
  duration: number
  extended_duration?: number
  construction_train_id?: number
  subject?: string
  contract_id?: number
  discipline_id?: any
  project_id: number
  created_at?: string
  updated_at?: string
  update_access?: boolean
  delete_access?: boolean
  construction_train?: IConstructionTrain
  contract?: IContract
  planned_execution_hours?: number
  control_area?: IControlArea
  control_area_id?: number
  planned_staffing?: number
  change_locomotives_in_group?: boolean
  change_only_future_locomotives_in_group?: boolean
  ignore_weekends?: boolean
  ignore_holidays?: boolean
  checked_in_by?: IUserData
  checked_in_signature?: string
  checked_in_date?: moment.Moment
  checked_out_by?: IUserData
  checked_out_date?: string
  checked_out_signature?: string
  vertical_move?: boolean
  construction_tasks?: IConstructionTask[]
  discipline?: IDiscipline
  check_in?: any
  check_out?: any
  checked_in?: boolean
  checked_out?: boolean
  checked_in_with_issue?: boolean
  checked_out_with_issue?: boolean
  last_checklist_color?: string
  has_next?: boolean
  has_previous?: boolean
  has_checked_in?: boolean
  has_checked_out?: boolean
  task_stats?: IWagonTaskStats
  is_clean?: boolean
  clean_status?: string
  execution_order?: number
  published_at?: string
  single_move?: boolean
}

export interface IConstructionTrain {
  id?: number
  record_id?: string
  title: string
  train_color: string
  status: string
  startTime: moment.Moment
  control_area_id: number
  control_area_group_id?: number | null
  project_id: number
  created_at?: string
  updated_at?: string
  update_access?: boolean
  delete_access?: boolean
  train_type_id?: number
  control_area?: IControlArea
  consider_holidays?: boolean
  change_trains_in_group?: boolean
  change_only_future_trains_in_group?: boolean
  ignore_weekends?: boolean
  ignore_holidays?: boolean
  locomotive?: any
  duration?: number
  planned_execution_days?: number
  planned_execution_hours?: number
  number_of_work_operations?: number
  number_of_locomotives?: number
  only_change_next_trains?: boolean
  is_clean?: boolean
}

export interface IControlAreaGroup {
  id?: number
  title: string
  project_id: number
  created_at?: string
  updated_at?: string
  update_access?: boolean
  delete_access?: boolean
  record_id?: string
}

export interface IProjectHoliday extends IIntervalIndicator {
  id?: number
  project_id: number
  created_at?: string
  updated_at?: string
}

export interface IIntervalIndicator {
  id?: number
  selected?: boolean
  description: string
  start_date: moment.Moment
  end_date: moment.Moment
  color?: string
}

export interface IProejctResponseData {
  holiday_week: IProjectHoliday[]
  weeks: string[]
}

export interface IControlArea {
  id: number
  record_id: string
  title: string
  project_id: number
  total_area: number
  execution_order?: number
  control_area_group_id?: number | null
  rooms?: IRoom[]
  room_ids?: number[]
  created_at: string
  updated_at: string
  update_access?: boolean
  delete_access?: boolean
  documents?: IDocument[]
  control_area_group?: IControlAreaGroup
  area_marker?: string
  confirmed_mc?: string | null
  confirmed_power_on?: string | null
  confirmed_red_rtb?: string | null
  confirmed_yellow_rtb?: string | null
  done_check_lists?: number
  floor_plan?: string | null
  is_deleted?: boolean
  plan_file_url?: string
  planned_end?: string | null
  planned_mc?: string | null
  planned_power_on?: string | null
  planned_red_rtb?: string | null
  planned_start?: string | null
  planned_yellow_rtb?: string | null
  rtb_status?: string | null
  status?: string | null
  total_check_lists?: number
  total_working_hours?: number
  open_issues?: number
  total_issues?: number
  progress?: number
  building?: string
}

export interface IConstructionTask {
  id?: number
  record_id?: string
  title: string
  is_estimate?: boolean
  status: string
  deadline: moment.Moment
  unit: string
  hours_per_unit: number
  total_units: number
  planned_execution_hours: number
  number_of_workers: number
  productivity: number
  construction_locomotive_id: number
  contract_id?: number
  contract?: IContract
  total_work_hours?: number
  discipline_id: number
  project_id: number
  created_at?: string
  updated_at?: string
  update_access?: boolean
  delete_access?: boolean
  construction_locomotive?: IConstructionWagon
  discipline?: IDiscipline
  estimated_duration?: number
  estimated_number_of_staffing?: number
  contractor_comment?: string
  optional_fields?: IMetaValue[]
  user_defined_values?: IMetaValue[]
  system_type_group_id?: number
}

export interface ITrainType {
  id: number
  title: string
  project_id: number
  created_at?: string
  updated_at?: string
  update_access?: boolean
  locomotive_type_ids?: number[]
  locomotive_types?: IWagonType[]
  delete_access?: boolean
  number_of_locomotives?: number
  number_of_work_operations?: number
  planned_execution_hours?: number
}

export interface IWagonType {
  status?: number
  error?(error: any): string
  id?: number
  title: string
  subject: string
  duration: number
  train_type_id?: number
  contract_id: number
  project_id: number
  created_at?: string
  updated_at?: string
  update_access?: boolean
  delete_access?: boolean
  train_type?: ITrainType
  contract?: IContract
  discipline?: IDiscipline
  planned_execution_hours?: number
  planned_staffing?: number
  discipline_id?: number
  construction_task_types?: IConstructionTaskType[]
  construction_task_type_ids?: number[]
  is_clean?: boolean
  clean_status?: string
  description?: string
  average_staffing?: number
  working_hours?: number
  execution_order?: number
}

export interface IConstructionTaskType {
  id?: number
  title: string
  unit: string
  planned_execution_hours: number
  hours_per_unit: number
  minutes_per_unit?: number
  total_units: number
  number_of_workers: number
  locomotive_type_id?: number
  productivity: number
  contract_id?: number
  is_estimate: boolean
  discipline_id: number
  project_id: number
  created_at?: string
  updated_at?: string
  update_access?: boolean
  delete_access?: boolean
  locomotive_type?: IWagonType
  discipline?: IDiscipline
  contract?: IContract
  total_work_hours?: number
}

export interface IConstructionCanvasResponse {
  start: string | null
  end: string | null
  control_areas: IConstructionCanvasControlArea[]
}

export interface IConstructionCanvasControlArea {
  construction_trains: IConstructionCanvasTrain[]
  control_area_group: IControlCanvasAreaGroup
  control_area_group_id: number
  created_at: string
  id: number
  project_id: number
  record_id: string
  title: string
  updated_at: string
  total_area?: number
  this_weeks_locomotives?: any[]
  update_access?: boolean
  delete_access?: boolean
  documents?: IDocument[]
  area_marker?: string
  confirmed_mc?: string | null
  confirmed_power_on?: string | null
  confirmed_red_rtb?: string | null
  confirmed_yellow_rtb?: string | null
  done_check_lists?: number
  floor_plan?: string | null
  is_deleted?: boolean
  plan_file_url?: string
  planned_end?: string | null
  planned_mc?: string | null
  planned_power_on?: string | null
  planned_red_rtb?: string | null
  planned_start?: string | null
  planned_yellow_rtb?: string | null
  rtb_status?: string | null
  status?: string | null
  total_check_lists?: number
  total_working_hours?: number
  open_issues?: number
  total_issues?: number
  progress?: number
  building?: string
}

export interface IControlCanvasAreaGroup {
  created_at: string
  id: number
  project_id: number
  title: string
  updated_at: string
}

export interface IConstructionCanvasTrain {
  locomotives: IConstructionCanvasCart[]
  control_area_id: number
  created_at: string
  id: number
  order: number | null
  project_id: number
  record_id: string | null
  startTime: string | moment.Moment
  status: string
  title: string
  train_color: string
  update_at: string
}

export interface IConstructionCanvasCart {
  border_color: string
  background_color: string
  construction_train_id: number
  contract_id: number
  created_at: string
  duration: number
  id: number
  project_id: number | null
  record_id: string | null
  startTime: string | moment.Moment
  status: string
  title: string
  status_color?: string
  subject?: string
  train_name: string
  contract_name: string
  planned_execution_hours?: number
  planned_staffing?: number
}

export interface IChecklist {
  id: number
  name: string
  color: string
  severity: string
  tasks: ITask[]
  created_at?: string
  updated_at?: string
}

export interface IConstructionChecklist {
  id?: number
  record_id?: string
  control_area_name?: string
  sha?: number
  quality?: number
  number_of_workers?: number
  neatness?: number
  progress?: number
  staffing?: number
  sha_color: string
  quality_color: string
  neatness_color: string
  progress_color: string
  staffing_color: string
  sha_tasks: ITaskData[]
  quality_tasks: ITaskData[]
  neatness_tasks: ITaskData[]
  progress_tasks: ITaskData[]
  staffing_tasks: ITaskData[]
  comment: string
  signed_by?: string
  isNextAreaClean: boolean
  isNextAreaReadyForStart: boolean
  construction_locomotive_id: number
  created_at?: string
  updated_at?: string
  update_access?: boolean
  delete_access?: boolean
  construction_locomotive?: IConstructionWagon
  documents?: IDocument[]
  check_list_task_count?: IChecklistTaskCount
  checklist_color?: string
  all_disciplines_present?: boolean
}

export interface IChecklistTaskCount {
  neatness_checklist: number
  progress_checklist: number
  quality_checklist: number
  sha_checklist: number
  staffing_checklist: number
  neatness_checklist_total: number
  progress_checklist_total: number
  quality_checklist_total: number
  sha_checklist_total: number
  staffing_checklist_total: number
  done: number
  total: number
}

export interface ICheckInOut {
  id?: number
  check_in_by: IUserData
  is_check_in: boolean
  check_in_by_id: number
  check_in_date: string
  cleared_and_vacuumed: ICheckItem
  damage_is_registered: ICheckItem
  no_equipment_left: ICheckItem
  no_outstanding_work: ICheckItem
  safe_to_work_in: ICheckItem
  cleared_and_vacuumed_id: number
  damage_is_registered_id: number
  no_equipment_left_id: number
  no_outstanding_work_id: number
  safe_to_work_in_id: number
  signature: string
  updated_at: string
  actual_work_hours?: number
  actual_staffing?: number
}

export interface ICheckItem {
  color: string
  id: number
  name: string
  tasks: ITaskData[]
  created_at: string
  updated_at?: string
}
export interface ISection {
  id?: number
  record_id?: string
  name: string
  section_number: string
  project_id: number
  floors?: IFloor[]
  created_at?: string
  updated_at?: string
}

export interface IFloor {
  id?: number
  record_id?: string
  name: string
  project_id: number
  floor_number: string
  section_id?: number
  section?: ISection
  created_at?: string
  updated_at?: string
  floors?: any[]
}

export interface IStatTypes {
  open: number
  expired: number
  behind_schedule?: number
  done?: number
}

export interface IProjectPPUStatTypes {
  keypoint: number
  keypoint_goal: number
  delivery: number
  delivery_goal: number
  task: number
  task_goal: number
}

export interface IRiskStatTypes {
  closed_risk_not_occured: number
  closed_risk_occured: number
  expired: number
  open: number
}
export interface IBroadcast {
  text: string
}

export interface IImportItemList {
  title: string
  templateJson: any
  type: string
  path?: string
  label?: string
  reload: () => void
}

export interface IImportWithValidation {
  parentId: number
  reload: () => void
  title: string
  uploadUrl: string
  importTemplate: any
  type: string
  validateMultiple: (
    newValues: Record<string, string | string[] | null>[],
    formattedData?: IValidationResponse,
  ) => Promise<IValidationResponse>
  columns: ImportDataFiled[]
  currentStep?: number
  uploadData: (data: any) => Promise<any>
  useDefinedFiled?: IMetaField[]
  confirmStep?: (
    result: ISystemUploadSummary | IDocumentUploadSummery,
  ) => ReactElement
}

export interface IListFilterFromParent {
  id: string
  value: any[]
  filed: string
  dateValue?: any
}

export interface IPieData {
  name: string
  value: number
  color?: string
}

export interface IBarData {
  dataKey: string
  name: string
  stackId?: string
  fill: string
}

export interface ILineData {
  type: any
  dataKey: string
  name: string
  stroke: string
  strokeDash?: string
}

export interface IAreaData {
  dataKey: string
  name: string
  stroke: string
  fill: string
  type: areaType
}
export interface IExport {
  geniusApiRequest: {
    url: string
    method: string
  }
  name: string
  displayName: string
  columns: string[]
  rows: string[]
}

export interface IContractualBorder {
  id: number
  title: string
  primary_contract: IContract
  secondary_contract: IContract
  primary_contract_id: number | null
  secondary_contract_id: number | null
  secondary_contract_ids: number[] | string[]
  system_ids: number[]
  primary_contract_responsibility: string
  secondary_contract_responsibility: string
  contracts?: IContract[]
  systems?: ISystem[]
  project_id?: number
  border_type: string
  status: string
  created_at: string
  updated_at: string
}

type areaType =
  | 'basis'
  | 'basisClosed'
  | 'basisOpen'
  | 'linear'
  | 'linearClosed'
  | 'natural'
  | 'monotoneX'
  | 'monotoneY'
  | 'monotone'
  | 'step'
  | 'stepBefore'
  | 'stepAfter'
  | undefined

export enum SystemTaskTypes {
  DELIVERY = 'delivery',
  MARKING = 'marking',
  FUNCTION_RESPONSIBLE = 'function_responsible',
  CABLING = 'cabling',
  PROJECT_PLANNING = 'project_planning',
  INTEGRATION = 'integration',
}

export const SystemTaskTypesTranslations = (
  taskType: SystemTaskTypes,
  t: TFunction<'translation', undefined>,
): string => {
  switch (taskType) {
    case SystemTaskTypes.DELIVERY:
      return t('delivery')
    case SystemTaskTypes.MARKING:
      return t('marking')
    case SystemTaskTypes.FUNCTION_RESPONSIBLE:
      return t('function_responsible')
    case SystemTaskTypes.CABLING:
      return t('cabling')
    case SystemTaskTypes.PROJECT_PLANNING:
      return t('project_planning')
    case SystemTaskTypes.INTEGRATION:
      return t('integration')
  }
}

export type Create<
  T extends {
    id: number | string
    record_id?: string
    created_at?: string
    updated_at?: string
  },
> = Optional<T, 'id' | 'record_id' | 'created_at' | 'updated_at'>
export type Changeset = Record<string, [string, string] | [number, number]>
export type IDraft = {
  id: number
  item_type: string
  item_id: number
  event: 'update' | 'create' | 'delete'
  changeset: Changeset
  whodunnit: IUser
}

export interface IUserContractMapper {
  accessExpire: string
  isContractAdmin: boolean
  contract: IContract
  company_name: string
  contractName: string
  contractNumber: string
  contractSize: string
  created_at: string
  id: number
  project_id: number
  record_id: null
  updated_at: string
  discipline: IDiscipline
  color: string
  granted_by: IGrantedBy
  working_hours: number
  isActive: boolean
  role: IRole
  user_id: number
  user_project_mapper_id: number
}

export interface IGrantedBy {
  accessExpire: null
  created_at: string
  created_by_id: number
  email: string
  email_notification: boolean
  firstName: string
  id: number
  image_url: string
  in_active_date: null
  isActive: boolean
  isSystemAdmin: boolean
  language: string
  lastName: string
  organization_id: number
  synchronized: boolean
  telephoneNumber: string
  updated_at: string
}

export interface IHighlight extends Highlight {
  id: string
}

export interface IPdfHighlight {
  id: number
  parent_id: number
  parent_type: string
  highlight: IHighlight
  status: 'resolved' | 'unresolved'
  project_id: number
  created_at: string
  updated_at: string
  comments: IComment[]
}

export interface IPaginateResult<T> {
  pages: number
  total_items: number
  items: T[]
}

export type FilteredPaginatedQuery<T> = ({
  sort,
  filters,
  page,
  pageSize,
  enabled,
}: {
  sort: TableFilter['sort']
  filters: Omit<TableFilter, 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => UseQueryResult<IPaginateResult<T>, Error>

export interface PDFHighlightParentInfo {
  parent_info: Record<string, string | number>
  highlight_id: string
}
