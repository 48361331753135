import moment from 'moment'
import { useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import DocumentMetaDataFields from 'src/document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from 'src/document/types/IMetaData'
import SelectSystemsList from 'src/page/system/SelectSystemForm'
import { getDisciplines } from 'src/service/DisciplineService'
import { getUploadDocURl } from 'src/service/FileUploadService'
import Modal from 'src/ui-elements/modal/Modal'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { deleteFile } from '../../service/DocumentService'
import {
  IDiscipline,
  IDocument,
  IImprovement,
  ITaskData,
  ITaskType,
  IUserData,
} from '../../service/OrgTypes'
import {
  createTask,
  editTask,
  getProjectTaskData,
  getProjectTaskTypes,
} from '../../service/TaskService'
import {
  getProjectDisplineUsers,
  getProjectUsersWithDisciplines,
} from '../../service/UserService'
import {
  getErrorMessage,
  getTaskErrorMessage,
  StructureValidationError,
  ValidationError,
} from '../../service/ValidationErrors'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import { renderDayContents } from '../../utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'
import UploadFile from '../document/UploadFile'
import UploadFileList from '../document/UploadFileList'
import Selector from '../selectors/Selector'

import SystemSelectorWithList from '../system/SystemSelectorWithList'
import { initializeMetaValues, setExistingValues } from '../system/SystemUtil'
import NoFilterIssuesList from './SelectedTasksList'
import TaskSelectorList from './TaskSelectorList'

interface ITaskTypeTaskForm {
  projectId: number
  editingMode: boolean
  task?: ITaskData
  improvementId?: number
  closeModal: () => void
  lastAllowedDeadline?: moment.Moment
  fromCanvas?: boolean
  selectedTaskTypeId?: number
  taskType?: boolean
}

const TaskTypeTaskForm = ({
  projectId,
  improvementId,
  editingMode,
  task,
  closeModal,
  lastAllowedDeadline,
  fromCanvas,
  selectedTaskTypeId,
  taskType,
}: ITaskTypeTaskForm) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'md:flex', 'flex-row', 'my-1'),
    taskWrapper: classNames('px-2', 'flex-1'),
    closedDate: (disabled: boolean) =>
      classNames(
        disabled ? 'border-grey-dark' : 'border-blue-one',
        disabled ? 'bg-grey-light' : '',
        'border-1',
        'py-px',
        'border',
        'rounded',
      ),
  }

  const [taskId] = useState<number>(task ? task.id : 0)
  const [title, setTitle] = useState<string>(task ? task.title : '')
  const [description, setDescription] = useState<string>(
    task ? task.description : '',
  )
  const [status, setStatus] = useState<string>(
    task && task.status ? task.status : 'open',
  )
  const [deadline, setDeadline] = useState<any>(
    task ? moment(task.deadline) : null,
  )
  const [endDate] = useState<moment.Moment>(
    task ? (task.endDate ? moment(task.endDate) : moment()) : moment(),
  )
  const [responsibleId, setResponsibleId] = useState<number>(
    task ? task.responsible_id || 0 : 0,
  )
  const [disciplineId, setDisciplineId] = useState<number>(
    task ? task.discipline_id : 0,
  )
  const [contractId, setContractId] = useState<number | undefined>(
    task ? task.contract_id : 0,
  )
  const [systemId, setSystemId] = useState<number | undefined | null>(
    task ? task.system_id : 0,
  )
  const [taskTypeId, setTaskTypeId] = useState<number | null | undefined>(
    selectedTaskTypeId ? selectedTaskTypeId : task?.task_type_id,
  )
  const [taskTypes, setTaskTypes] = useState<ITaskType[]>(
    task && task.task_type ? [task.task_type] : [],
  )
  const [users, setUsers] = useState<IUserData[]>([])
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [taskTypesLoading, setTaskTypesLoading] = useState<boolean>(false)
  const [deadlineDatePickerFocused, setDeadlineDatePickerFocused] =
    useState<boolean>(false)
  const [datoErrorMessage, setDatoErrorMessage] = useState<string>('')
  const [taskTypeErrorMessage, setTaskTypeErrorMessage] = useState('')
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [responsibleErrorMessage, setResponsibleErrorMessage] =
    useState<string>('')
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const [displineLoading, setDisplineLoading] = useState<boolean>(false)

  const [improvements, setImprovements] = useState<IImprovement[]>([])

  const { t } = useTranslation()
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [showTasksModal, setShowTasksModal] = useState(false)
  const [showSystemsModal, setShowSystemsModal] = useState(false)
  const [relatedTasks, setRelatedTasks] = useState<number[]>([])
  const [relatedSystems, setRelatedSystems] = useState<number[]>([])

  useEffect(() => {
    moment.locale('no')
    reload()
    setStatus('open')
    if (!fromCanvas) {
      setDeadline(null)
    }
  }, [projectId])

  const handleFetchTaskTypes = (taskTypes: ITaskType[]) => {
    setTaskTypes(taskTypes ? taskTypes : [])
    if (taskTypeId && taskTypes) {
      const metaFields = initializeMetaValues(
        taskTypes.find((t: ITaskType) => t.id === taskTypeId)
          ?.optional_fields ?? [],
        'Task',
        task?.id,
      )

      setOptionalFields(
        setExistingValues(task?.optional_fields ?? [], metaFields),
      )
    }
  }

  const reload = () => {
    getProjectTaskData(projectId)
      .then((res: any) => {
        handleFetchTaskTypes(res.task_types)
        setUsers(res.users ? res.users : [])
        setDisciplines(res.disciplines ? res.disciplines : [])
        setImprovements(res.improvements ? res.improvements : [])
      })
      .catch((err) => console.error(err))
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    let error = false
    setLoading(true)
    setDatoErrorMessage('')
    setTitleErrorMessage('')
    setDisciplineErrorMessage('')
    setResponsibleErrorMessage('')

    if (!deadline || !moment(deadline).isValid()) {
      setDatoErrorMessage(getErrorMessage(ValidationError.MISSING_DEADLINE, t))
      error = true
    }

    if (taskType && (!taskTypeId || taskTypeId < 1)) {
      setTaskTypeErrorMessage(t('select_task_type'))
      error = true
    }
    const improvement = improvements.find((u) => u.id === improvementId)
    if (deadline && improvement && improvement.deadline) {
      if (moment(deadline).isAfter(moment(improvement.deadline), 'day')) {
        setDatoErrorMessage(
          getTaskErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
          ) +
            ' (' +
            moment(improvement.deadline).format('L') +
            ')',
        )
        error = true
      }
    }

    if (deadline && lastAllowedDeadline) {
      if (deadline.isAfter(lastAllowedDeadline, 'day')) {
        setDatoErrorMessage(
          getTaskErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_TASK,
            t,
          ) +
            ' (' +
            moment(lastAllowedDeadline).format('L') +
            ')',
        )
        error = true
      }
    }

    if (!title) {
      setTitleErrorMessage(getErrorMessage(ValidationError.MISSING_TITLE, t))
      error = true
    }

    if (!disciplineId || disciplineId === 0) {
      setDisciplineErrorMessage(
        getErrorMessage(ValidationError.MISSING_DISCIPLINE, t),
      )
      error = true
    }

    if (!responsibleId || responsibleId === 0) {
      setResponsibleErrorMessage(
        getErrorMessage(ValidationError.MISSING_RESPONSIBLE, t),
      )
      error = true
    }

    if (!error) {
      const taskData = {
        id: taskId,
        title,
        description,
        status,
        taskType: 'Aksjon',
        deadline: moment(deadline, 'L'),
        responsible_id: responsibleId > 0 ? responsibleId : undefined,
        discipline_id: disciplineId,
        contract_id: contractId,
        system_id: systemId,
        project_id: projectId,
        related_task_ids: relatedTasks,
        related_system_ids: relatedSystems,
        task_type_id: taskTypeId ?? null,
        parent_id: task?.parent_id,
        parent_type: task?.parent_type,
        endDate: status === 'done' ? endDate : undefined,
        documents,
        improvement_id: improvementId,
        optional_fields: optionalFields,
      }

      if (editingMode) {
        await editTask(taskData)
      } else {
        await createTask(taskData)
      }
      if (!createMultiple) {
        closeModal()
      } else {
        setLoading(false)
        setTitle('')
        setDocuments([])
        setCreateMultiple(false)
      }
    } else {
      setLoading(false)
    }
  }

  const onTitleChange = (e: any) => {
    const value = e.target.value
    setTitle(value)
    setTitleErrorMessage('')
  }

  const onDescriptionChange = (e: any) => {
    const value = e.target.value
    setDescription(value)
  }

  const onDeadlineChange = (date: moment.Moment) => {
    setDeadline(date)
    setDatoErrorMessage('')
  }

  const setDeadlineDatepickerFocus = (focused: any) => {
    setDeadlineDatePickerFocused(focused.focused)
  }

  const outSideRangeDate = () => {
    return false
  }

  const getTaskTypes = () => {
    setTaskTypesLoading(true)
    getProjectTaskTypes(projectId).then((res) => {
      handleFetchTaskTypes(res)
      setTaskTypesLoading(false)
    })
  }

  const getUsers = () => {
    setUsersLoading(true)
    if (disciplineId > 0) {
      getProjectDisplineUsers(projectId, disciplineId).then((res) => {
        setUsers(res)
        setUsersLoading(false)
      })
    } else {
      getProjectUsersWithDisciplines(projectId).then((res) => {
        setUsers(res)
        setUsersLoading(false)
      })
    }
  }

  const onDisplineOpen = () => {
    setDisplineLoading(true)
    getDisciplines(projectId).then((res) => {
      setDisciplines(res)
      setDisplineLoading(false)
    })
  }

  const onDisciplineChange = async (value: number) => {
    const discipline = disciplines
      ? disciplines.find((v) => v.id === value)
      : undefined
    if (!responsibleId || responsibleId <= 0) {
      const usersList = await getProjectDisplineUsers(projectId, value)
      setResponsibleId(0)
      setUsers(usersList)
    }

    setDisciplineId(value)
    setContractId(
      discipline && discipline.contract_id ? discipline.contract_id : undefined,
    )

    setDisciplineErrorMessage('')
  }

  const onResponsibleChange = (userId: number) => {
    const selecteduser: IUserData | undefined = users
      .filter((u) => u.id === userId)
      .pop()
    const userDisciplines =
      selecteduser && selecteduser.disciplines
        ? selecteduser.disciplines.filter((d) => d.project_id === projectId)
        : undefined
    const discipline =
      userDisciplines && userDisciplines.length === 1
        ? userDisciplines[0]
        : undefined

    setResponsibleId(userId)
    setDisciplines(userDisciplines ? userDisciplines : [])
    if (discipline) {
      setDisciplineId(discipline.id)
      setContractId(discipline.contract_id)
    }

    setDisciplineErrorMessage('')
    setResponsibleErrorMessage('')
  }

  const onDisplineUserClear = async () => {
    setDisciplineId(0)
    setResponsibleId(0)
    const allProjectDis = await getDisciplines(projectId)
    const allUsers = await getProjectUsersWithDisciplines(projectId)
    setDisciplines(allProjectDis)
    setUsers(allUsers)
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const filesUploaded = (docs: IDocument[]) => {
    setDocuments(documents.concat(docs))
  }

  const updateUploadedFiles = (docs: IDocument[]) => {
    setDocuments(docs)
  }

  const onCloseModal = () => {
    documents.forEach((item) => {
      deleteFile({ file_url: item.fileUrl }).catch((err) => console.error(err))
    })

    setDocuments([])

    closeModal()
  }

  const onTaskTypeChange = (value: number) => {
    setTaskTypeId(value)
    const metaFields = initializeMetaValues(
      taskTypes.find((t) => t.id === value)?.optional_fields ?? [],
      'Task',
      task?.id,
    )
    setOptionalFields(
      setExistingValues(task?.optional_fields ?? [], metaFields),
    )
    setTaskTypeErrorMessage('')
  }

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setOptionalFields(values)
  }

  return (
    <Modal
      show={true}
      title={capFirstLetter(t('add_case'))}
      closeModal={closeModal}
      maxWidth={1000}
    >
      <form className={styleClass.root} onSubmit={onSubmit}>
        <div className={`${styleClass.inputGroup} flex-wrap items-center`}>
          <div className={'w-mx-content flex flex-col'}>
            <div className={'flex'}>
              <label className={'pl-2 text-sm text-gray-700 font-medium my-2'}>
                {capFirstLetter(t('deadline'))} *
              </label>
              {datoErrorMessage ? (
                <label className={'pl-2 text-sm my-2'} style={{ color: 'Red' }}>
                  {datoErrorMessage}
                </label>
              ) : null}
            </div>
            <div className={'pl-2 mb-1 pr-2'}>
              <SingleDatePicker
                firstDayOfWeek={1}
                date={deadline}
                onDateChange={onDeadlineChange}
                renderDayContents={renderDayContents}
                focused={deadlineDatePickerFocused}
                onFocusChange={setDeadlineDatepickerFocus}
                id="Starttidspunkt"
                small={true}
                isOutsideRange={outSideRangeDate}
                showDefaultInputIcon={true}
                noBorder={true}
                numberOfMonths={1}
                displayFormat={() =>
                  moment.localeData('no').postformat('DD.MM.YY')
                }
                hideKeyboardShortcutsPanel={true}
              />
            </div>
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <Input
            label={t('title')}
            block={true}
            value={title}
            onChange={onTitleChange}
            required={true}
            errorMessage={titleErrorMessage}
            autoFocus={true}
          />
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-full md:w-1/2'}>
            <Selector
              items={taskTypes}
              loading={taskTypesLoading}
              onOpenSelectFunction={getTaskTypes}
              selectedItemId={taskTypeId ?? 0}
              onSelect={onTaskTypeChange}
              label={t('task_type')}
              dataFields={['taskTypeName']}
              fontSize={'sm'}
              fontWeight={'bold'}
              required={taskType}
              errorMessage={taskTypeErrorMessage}
              scroll
            />
          </div>
          <div className={'w-full md:w-1/2'}>
            <SystemSelectorWithList
              label={'system'}
              projectId={projectId}
              selectedSystemId={systemId}
              onSystemSelect={setSystemId}
            />
          </div>
        </div>

        <div className={`${styleClass.inputGroup} flex-wrap`}>
          <div className={'w-full lg:w-1/2'}>
            <Selector
              items={disciplines}
              selectedItemId={disciplineId}
              onOpenSelectFunction={onDisplineOpen}
              loading={displineLoading}
              onSelect={onDisciplineChange}
              label={t('discipline')}
              dataFields={['shortName', 'name']}
              required={true}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={disciplineErrorMessage}
              onCancel={onDisplineUserClear}
              cancelButton={true}
            />
          </div>
          <div className={'w-full lg:w-1/2'}>
            <Selector
              items={users}
              selectedItemId={responsibleId}
              onOpenSelectFunction={getUsers}
              onSelect={onResponsibleChange}
              label={t('responsible')}
              dataFields={['firstName', 'lastName']}
              required={true}
              fontSize={'sm'}
              userSelector={true}
              fontWeight={'bold'}
              loading={usersLoading}
              onCancel={onDisplineUserClear}
              errorMessage={responsibleErrorMessage}
              cancelButton={true}
            />
          </div>
        </div>
        {optionalFields.length > 0 && (
          <div>
            <DocumentMetaDataFields
              title={''}
              onFieldsUpdate={onOptionalFieldsUpdate}
              fields={optionalFields}
              required={false}
            />
          </div>
        )}

        <div className={styleClass.inputGroup}>
          <Textarea
            label={t('description')}
            value={description}
            isValid={false}
            autoFocus={false}
            onChange={onDescriptionChange}
            block={true}
          />
        </div>

        <div className="pl-1 py-2">
          <Button
            type={Button.ButtonType.BOUNDARY_WHITE}
            dontSubmit={true}
            onClick={() => setShowTasksModal(true)}
          >
            {t('select_related_tasks')}
          </Button>
        </div>
        <div className="pl-2">
          <NoFilterIssuesList
            key={showTasksModal ? 1 : 2}
            selectedIds={relatedTasks}
          />
        </div>

        {documents && documents.length > 0 && (
          <UploadFileList
            documents={documents}
            updateDocuments={updateUploadedFiles}
          />
        )}

        <UploadFile
          uploadUrl={getUploadDocURl(projectId, 'Task')}
          uploadedDocuments={filesUploaded}
        />

        <ModalFooter>
          <Button onClick={onCloseModal}>{capFirstLetter(t('cancel'))}</Button>
          {editingMode ? (
            <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
              {loading ? <Spinner /> : capFirstLetter(t('update'))}
            </Button>
          ) : (
            <>
              <Button
                type={Button.ButtonType.SECONDARY}
                noTextWrap={true}
                onClick={onMultipleCreate}
                disabled={loading}
              >
                {loading ? <Spinner /> : capFirstLetter(t('add_multiple'))}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                noTextWrap={true}
                onClick={onSingleCreate}
                disabled={loading}
              >
                {loading ? <Spinner /> : capFirstLetter(t('add'))}
              </Button>
            </>
          )}
        </ModalFooter>
      </form>
      {showTasksModal && (
        <Modal
          title={t('related_tasks')}
          closeModal={() => setShowTasksModal(false)}
          maxWidth={1280}
          show={showTasksModal}
        >
          <div className="pl-1 min-h-[68vh]">
            <TaskSelectorList
              projectId={projectId}
              onSelectIds={setRelatedTasks}
              selectedIds={relatedTasks}
            />
          </div>
          <ModalFooter>
            <Button onClick={() => setShowTasksModal(false)}>
              {capFirstLetter(t('cancel'))}
            </Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              noTextWrap={true}
              onClick={() => setShowTasksModal(false)}
            >
              {capFirstLetter(t('save'))}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showSystemsModal && (
        <SelectSystemsList
          projectId={+projectId}
          key={projectId}
          selectSystemIds={relatedSystems}
          onSelectSystems={(ids) => {
            setRelatedSystems(ids)
            setShowSystemsModal(false)
          }}
          closeModal={() => setShowSystemsModal(false)}
        />
      )}
    </Modal>
  )
}

export default TaskTypeTaskForm
