import { IFileContainer } from 'src/document/types/IFileContainer'
import {
  IPaginatedTestCases,
  IPaginatedTests,
  IPaginatedTestTypes,
  ITest,
  ITestChecklist,
  ITestDocument,
  ITestExecution,
  ITestParticipant,
  ITestSetup,
  ITestType,
  ISystem,
  IUserData,
} from 'src/service/OrgTypes'
import { request } from 'src/service/service-utils'

export const createTest = (
  projectId: number,
  test: Partial<ITest>,
): Promise<ITest> => {
  return request(`/projects/${projectId}/tests`, 'POST', test)
}

export const editTest = (test: Partial<ITest>): Promise<ITest> => {
  return request(`tests/${test.id}`, 'PUT', test)
}

export const deleteTest = (test: ITest): Promise<ITest> => {
  return request(`tests/${test.id}`, 'Delete', {}, true)
}

export const deleteBulkTests = (
  projectId: number,
  data: number[],
): Promise<any> => {
  return request(`projects/${projectId}/tests/remove_multiple`, 'POST', {
    ids: data,
  })
}

export const createTestType = (
  projectId: number,
  testType: ITestType,
): Promise<ITest> => {
  return request(`/projects/${projectId}/test_types`, 'POST', testType)
}

export const editTestType = (
  testType: Partial<ITestType>,
): Promise<ITestType> => {
  return request(`test_types/${testType.id}`, 'PUT', testType)
}

export const deleteTestType = (testType: ITestType): Promise<ITestType> => {
  return request(`test_types/${testType.id}`, 'Delete', {}, true)
}

export const deleteBulkTestType = (
  projectId: number,
  data: number[],
): Promise<any> => {
  return request(`projects/${projectId}/test_types/remove_multiple`, 'POST', {
    ids: data,
  })
}

export const getTestType = (testTypeId: number): Promise<ITestType> => {
  return request(`test_types/${testTypeId}`, 'GET')
}

export const swapOrderTestType = (
  testTypeId: number,
  executionOrder: number,
): Promise<IPaginatedTestTypes> => {
  return request(`test_types/${testTypeId}/change_order`, 'Put', {
    sequence: executionOrder,
  })
}

export const getProjectTestTypesTree = (projectId: number): Promise<any> => {
  return request(`project/${projectId}/test_types/test_type_tree`, 'POST')
}

export const getProjectTestTypeHierarchyTree = (
  projectId: number,
): Promise<any> => {
  return request(`project/${projectId}/test_type_hierarchy_tree`, 'GET')
}

export const getProjectTestHierarchyTree = (
  projectId: number,
): Promise<any> => {
  return request(`projects/${projectId}/test_hierarchy_tree`, 'GET')
}

export const getAllProjectTestTypes = (
  projectId: number,
): Promise<ITestType[]> => {
  return request(`projects/${projectId}/test_types`, 'GET')
}

export const getTest = (testId: number): Promise<any> => {
  return request(`tests/${testId}`, 'GET')
}

export const getSystemTests = (
  projectId: number,
  systemId: number,
  filter?: any,
): Promise<ITest[]> => {
  return request(
    `/projects/${projectId}/systems/${systemId}/tests`,
    'POST',
    filter,
  )
}

export const getControlAreaTests = (
  projectId: number,
  controlAreaId: number,
): Promise<ITest[]> => {
  return request(
    `/projects/${projectId}/control_areas/${controlAreaId}/tests`,
    'GET',
  )
}

export const getRoomTests = (
  projectId: number,
  roomId: number,
): Promise<ITest[]> => {
  return request(`/projects/${projectId}/rooms/${roomId}/tests`, 'GET')
}

export const addTestToSystems = (
  projectId: number,
  testIds: number[],
  systemId: number,
): Promise<ITest> => {
  return request(
    `projects/${projectId}/systems/${systemId}/add_tests`,
    'POST',
    { test_ids: testIds },
  )
}

export const addTestToControlAreas = (
  projectId: number,
  testIds: number[],
  controlAreaId: number,
): Promise<ITest> => {
  return request(
    `projects/${projectId}/control_areas/${controlAreaId}/add_tests`,
    'POST',
    { test_ids: testIds },
  )
}

export const addTestToRooms = (
  projectId: number,
  testIds: number[],
  roomId: number,
): Promise<ITest> => {
  return request(`projects/${projectId}/rooms/${roomId}/add_tests`, 'POST', {
    test_ids: testIds,
  })
}

export const deleteTestFromSystem = (
  projectId: number,
  systemId: number,
  testIds: number[],
): Promise<ITest> => {
  return request(
    `projects/${projectId}/systems/${systemId}/remove_tests`,
    'POST',
    { test_ids: testIds },
    true,
  )
}

export const deleteTestFromControlArea = (
  projectId: number,
  controlAreaId: number,
  testIds: number[],
): Promise<ITest> => {
  return request(
    `projects/${projectId}/control_areas/${controlAreaId}/remove_tests`,
    'POST',
    { test_ids: testIds },
    true,
  )
}

export const deleteTestFromRoom = (
  projectId: number,
  roomId: number,
  testIds: number[],
): Promise<ITest> => {
  return request(
    `projects/${projectId}/rooms/${roomId}/remove_tests`,
    'POST',
    { test_ids: testIds },
    true,
  )
}

export const getAllTests = (projectId: number): Promise<ITest[]> => {
  return request(`projects/${projectId}/tests`, 'GET')
}

export const getProjectTests = (
  projectId: number,
  page: number,
  filter?: any,
  perPage?: number,
): Promise<IPaginatedTests> => {
  return request(
    `projects/${projectId}/tests/pages/${page}/${perPage ? perPage : 100000}`,
    'Post',
    filter,
  )
}

export const getTestsForTestType = (
  testTypeId: number,
  page: number,
  filter?: any,
  perPage?: number,
): Promise<IPaginatedTests> => {
  return request(
    `test_types/${testTypeId}/tests/pages/${page}/${
      perPage ? perPage : 100000
    }`,
    'Post',
    filter,
  )
}

export const getTestDependenciesForTest = (
  projectId: number,
  testId: number,
  page: number,
  filter?: any,
  perPage?: number,
): Promise<IPaginatedTests> => {
  return request(
    `projects/${projectId}/tests/${testId}/test_dependencies/pages/${page}/${
      perPage ? perPage : 100000
    }`,
    'Post',
    filter,
  )
}

export const getAllProjectTests = (projectId: number): Promise<ITest[]> => {
  return request(`projects/${projectId}/tests`, 'GET')
}

export const getTestCases = (testId: number): Promise<IPaginatedTestCases> => {
  return request(`/tests/${testId}/test_cases/pages/0/0`, 'POST')
}

export const getTestRelatedTestCases = (
  testId: number,
  page: number,
  filter?: any,
  perPage?: number,
): Promise<any> => {
  return request(
    `/tests/${testId}/test_cases/pages/${page}/${perPage ? perPage : 50}`,
    'Post',
    filter,
  )
}

export const getProjectTestCases = (
  projectId: number,
  page: number,
  filter?: any,
  perPage?: number,
): Promise<any> => {
  return request(
    `/projects/${projectId}/test_cases/pages/${page}/${perPage ? perPage : 50}`,
    'Post',
    filter,
  )
}

export const addTestDependency = (
  testId: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/tests/${testId}/test_dependencies`,
    'POST',
    dependencies,
    true,
  )
}

export const removeTestDependency = (
  testId: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/tests/${testId}/test_dependencies/remove`,
    'POST',
    { dependent_on_test: dependencies },
    true,
  )
}

export const getTestTDisciplineCanvas = (
  projectId: number,
  filters: any,
): Promise<any> => {
  return request(
    `/projects/${projectId}/test_discipline_canvas`,
    'POST',
    filters,
  )
}

export const getTestTypeTestCanvas = (
  projectId: number,
  filters: any,
): Promise<any> => {
  return request(`/projects/${projectId}/test_type_canvas`, 'POST', filters)
}

export const resetTestDependency = (
  testId: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/tests/${testId}/reset_test_dependencies`,
    'POST',
    dependencies,
    true,
  )
}

export const unplannedTests = (
  projectId: number,
  filters: any,
): Promise<any> => {
  return request(`/projects/${projectId}/unplanned_tests`, 'POST', filters)
}

export const updateTestDocument = (
  projectId: number,
  testDocId: number,
  testDocument: NullablePartial<ITestDocument>,
): Promise<ITestDocument> => {
  return request(
    `/projects/${projectId}/test_documents/${testDocId}`,
    'PUT',
    testDocument,
  )
}

export const massTestDocumentUpdate = (
  projectId: number,
  testDocIds: number[],
  testDocument: NullablePartial<ITestDocument>,
): Promise<void> => {
  return request(`/projects/${projectId}/test_documents/mass_update`, 'POST', {
    test_document_ids: testDocIds,
    test_document: testDocument,
  })
}

export const getTestDocumentsForTest = (
  testId: number,
): Promise<ITestDocument[]> => {
  return request(`/tests/${testId}/test_documents`, 'GET')
}

export const updateTestSetup = (
  projectId: number,
  testSetupId: number,
  testSetup: NullablePartial<ITestSetup>,
): Promise<ITestSetup> => {
  return request(
    `projects/${projectId}/test_setups/${testSetupId}`,
    'PUT',
    testSetup,
  )
}

export const getTestSetupForTest = (testId: number): Promise<ITestSetup[]> => {
  return request(`/tests/${testId}/test_setups`, 'GET')
}

export const getTestExecutionReport = (
  projectId: number,
  id: number,
): Promise<ITestSetup[]> => {
  return request(
    `/projects/${projectId}/test_executions/${id}/test_report`,
    'GET',
  )
}

export const getTestSetupStatus = (
  testId: number,
): Promise<{ approved_test_setups: number; rejected_test_setups: number }> => {
  return request(`/tests/${testId}/test_setup_status`, 'GET')
}

export const getTestParticipants = (testId: number): Promise<any> => {
  return request(`/tests/${testId}/single_test_participants`, 'GET')
}

export const getTestExecutionParticipants = (
  testExecutionId: number,
): Promise<ITestParticipant[]> => {
  return request(
    `/test_executions/${testExecutionId}/single_test_participants`,
    'GET',
  )
}

export const getCandidateTestExecutionParticipants = (
  projectId: number,
  testExecutionId: number,
): Promise<IUserData[]> => {
  return request(
    `/projects/${projectId}/test_executions/${testExecutionId}/candidate_test_participants`,
    'GET',
  )
}

export const editTestParticipant = (
  participant: ITestParticipant,
): Promise<ITestParticipant> => {
  return request(
    `/single_test_participants/${participant.id}`,
    'PUT',
    participant,
  )
}

export const deleteTestParticipant = (
  participantId: number,
): Promise<ITestParticipant> => {
  return request(
    `/single_test_participants/${participantId}`,
    'DELETE',
    {},
    true,
  )
}

export const sendInviteToTestParticipant = (
  ids: number | number[],
  sendInvite: boolean,
  projectId: number,
  invitationSubject?: string,
  invitationBody?: string,
  data_type?: string,
): Promise<ITestParticipant> => {
  return request(
    `projects/${projectId}/reminders/send`,
    'POST',
    {
      ids,
      subject: invitationSubject,
      body: invitationBody,
      data_type,
    },
    true,
  )
}

export const sendInviteToTestExecutionParticipant = (
  projectId: number,
  testId: number,
  testExecutionId: number,
  lang: string,
  invitationSubject?: string,
  invitationBody?: string,
  to?: { userId?: number; email: string; id: number }[],
): Promise<ITestParticipant> => {
  return request(
    `/projects/${projectId}/tests/${testId}/test_executions/${testExecutionId}/send_test_invitation`,
    'PUT',
    {
      invitation_subject: invitationSubject,
      invitation_text: invitationBody,
      lang,
      test_participant_ids: to?.map((item) => item.id),
      timeZoneOffset: new Date().getTimezoneOffset(),
    },
    true,
  )
}

export const createMultipleTestParticipant = (
  testId: number,
  participants: any[],
): Promise<any> => {
  return request(`/tests/${testId}/multiple_test_participants`, 'POST', {
    participants,
  })
}

export const getSystemsUnderTest = (
  projectId: number,
  testId: number,
): Promise<ISystem[]> => {
  return request(`projects/${projectId}/tests/${testId}/systems`, 'GET')
}

export const getFilteredSystemsUnderTest = (
  projectId: number,
  testId: number,
  filter?: any,
): Promise<ISystem[]> => {
  return request(
    `projects/${projectId}/tests/${testId}/systems_filtered`,
    'POST',
    filter,
  )
}

export const getSystemIdsUnderTest = (
  projectId: number,
  testId: number,
): Promise<{
  target_system_ids: number[]
  supporting_system_ids: number[]
}> => {
  return request(`projects/${projectId}/tests/${testId}/systems/ids`, 'GET')
}

export const getRoomsUnderTest = (
  projectId: number,
  testId: number,
): Promise<any> => {
  return request(`projects/${projectId}/tests/${testId}/rooms`, 'GET')
}

export const getControlAreasUnderTest = (
  projectId: number,
  testId: number,
): Promise<any> => {
  return request(`projects/${projectId}/tests/${testId}/control_areas`, 'GET')
}

export const addSystemUnderTest = (
  projectId: number,
  testId: number,
  systemIds: number[],
  target: boolean,
): Promise<any> => {
  return request(`/projects/${projectId}/tests/${testId}/add_systems`, 'POST', {
    system_ids: systemIds,
    is_main_system: target,
  })
}

export const addRoomsUnderTest = (
  projectId: number,
  testId: number,
  roomIds: number[],
): Promise<any> => {
  return request(`/projects/${projectId}/tests/${testId}/add_rooms`, 'POST', {
    room_ids: roomIds,
  })
}

export const addControlAreasUnderTest = (
  projectId: number,
  testId: number,
  controlAreasIds: number[],
): Promise<any> => {
  return request(
    `/projects/${projectId}/tests/${testId}/add_control_areas`,
    'POST',
    {
      control_area_ids: controlAreasIds,
    },
  )
}

export const removeSystemsFromTest = (
  projectId: number,
  testId: number,
  system_ids: number[],
): Promise<any> => {
  return request(
    `/projects/${projectId}/tests/${testId}/remove_systems`,
    'POST',
    { system_ids: system_ids },
    false,
  )
}

export const removeRoomsFromTest = (
  projectId: number,
  testId: number,
  room_ids: number[],
): Promise<any> => {
  return request(
    `/projects/${projectId}/tests/${testId}/remove_rooms`,
    'POST',
    { room_ids: room_ids },
    false,
  )
}

export const removeControlAreasFromTest = (
  projectId: number,
  testId: number,
  control_area_id: number[],
): Promise<any> => {
  return request(
    `/projects/${projectId}/tests/${testId}/remove_control_areas`,
    'POST',
    { control_area_ids: control_area_id },
    false,
  )
}

export const getTestExecutions = (
  projectId: number,
  testId: number,
): Promise<ITestExecution[]> => {
  return request(
    `/projects/${projectId}/tests/${testId}/test_executions`,
    'GET',
  )
}

export const getTestExecution = (
  testExecutionId: number,
): Promise<ITestExecution> => {
  return request(`/test_executions/${testExecutionId}`, 'GET')
}

export const getCurrentTestExecution = (
  projectId: number,
  testId: number,
): Promise<ITestExecution> => {
  return request(
    `/projects/${projectId}/tests/${testId}/current_test_execution`,
    'GET',
  )
}

export const createTestExecution = (
  projectId: number,
  testId: number,
  testExecution: ITestExecution,
): Promise<ITestExecution> => {
  return request(
    `/projects/${projectId}/tests/${testId}/test_executions`,
    'POST',
    testExecution,
  )
}

export const editTestExecution = (
  projectId: number,
  testId: number,
  testExecution: Partial<ITestExecution>,
): Promise<any> => {
  return request(
    `/projects/${projectId}/tests/${testId}/test_executions/${testExecution.id}`,
    'PUT',
    testExecution,
  )
}

export const sendForApproval = (
  projectId: number,
  testId: number,
  testExecutionId: number,
  approvalIds: number[],
): Promise<any> => {
  return request(
    `/projects/${projectId}/tests/${testId}/test_executions/${testExecutionId}/send_for_signing`,
    'PUT',
    {
      test_approval_ids: approvalIds,
    },
  )
}

export const deleteTestExecution = (testExecutionId: number): Promise<any> => {
  return request(`/test_executions/${testExecutionId}`, 'DELETE', {})
}

export const updateSystemTestMappers = (
  projectId: number,
  systemMapperId: number,
  data: any,
) => {
  return request(
    `/projects/${projectId}/system_test_mappers/${systemMapperId}`,
    'PUT',
    data,
  )
}

export const addFileContainersToTest = (
  projectId: number,
  testId: number,
  fileContainerIds: number[],
): Promise<IFileContainer> => {
  return request(
    `/projects/${projectId}/tests/${testId}/add_file_containers`,
    'POST',
    { file_container_ids: fileContainerIds },
  )
}

export const removeFileContainersFromTest = (
  projectId: number,
  testId: number,
  fileContainerIds: number[],
): Promise<ITest> => {
  return request(
    `projects/${projectId}/tests/${testId}/remove_file_containers`,
    'POST',
    { file_container_ids: fileContainerIds },
    true,
  )
}

export const getDomainTestChecklists = (
  projectId: number,
  domain: string,
): Promise<ITestChecklist[]> => {
  return request(`/projects/${projectId}/${domain}/checklists`, 'GET')
}

export const updateTestCheckList = (
  projectId: number,
  checklistId: number,
  checklist: Partial<ITestChecklist>,
): Promise<ITestChecklist> => {
  return request(
    `projects/${projectId}/checklists/${checklistId}/`,
    'PUT',
    checklist,
  )
}

export const createTestCheckList = (
  projectId: number,
  checklist: Partial<ITestChecklist>,
) => {
  return request(`/projects/${projectId}/checklists`, 'POST', checklist)
}

export const createTestCheckListItem = (
  projectId: number,
  checkListId: number,
  checklistItem: any,
) => {
  return request(
    `/projects/${projectId}/checklists/${checkListId}/checklist_items`,
    'POST',
    checklistItem,
  )
}

export const updateTestCheckListItem = (
  checklistItemId: number,
  header: string,
  value: string,
) => {
  return request(`checklist_items/${checklistItemId}/`, 'PUT', {
    [header]: value,
  })
}

export const deleteTestChecklist = (
  projectId: number,
  checklistId: number,
): Promise<any> => {
  return request(
    `/projects/${projectId}/checklists/${checklistId}`,
    'Delete',
    {},
    true,
  )
}

export const deleteTestChecklistItem = (
  checklistItemId: number,
): Promise<any> => {
  return request(`/checklist_items/${checklistItemId}`, 'Delete', {}, true)
}

export const updateChecklistItemOrder = (
  checklistItemId: string | number,
  order: number,
) => {
  return request(`checklist_items/${checklistItemId}/order`, 'PUT', {
    order,
  })
}

export const addSignatureToTestExecution = (
  projectId: number,
  testExecutionId: number,
  signature: string,
): Promise<any> => {
  return request(
    `projects/${projectId}/parents/TestExecution/${testExecutionId}/signatures`,
    'POST',
    {
      signature,
    },
  )
}

export const getTestDocumentStatus = (
  testId: number,
): Promise<{ finished_documents: number; unfinished_documents: number }> => {
  return request(`tests/${testId}/test_document_status`, 'GET')
}

export const createTestDocumentTypes = (
  testDocumentTypesData: Omit<ITestDocument, 'id'>,
  projectId: number,
): Promise<ITestDocument> => {
  return request(
    `projects/${projectId}/test_document_types`,
    'POST',
    testDocumentTypesData,
  )
}

export const updateTestDocumentTypes = (
  testDocumentTypes: NullablePartial<ITestDocument>,
  projectId: number,
): Promise<ITestDocument> => {
  return request(
    `projects/${projectId}/test_document_types/${testDocumentTypes.id}`,
    'PUT',
    testDocumentTypes,
  )
}

export const getProjectTestDocumentTypes = (
  projectId: number,
): Promise<ITestDocument[]> => {
  return request(`projects/${projectId}/test_document_types`, 'GET')
}

export const deleteTestDocumentTypes = (testDocumentTypes: ITestDocument) => {
  return request(
    `/test_document_types/${testDocumentTypes.id}`,
    'DELETE',
    {},
    true,
  )
}

export const createTestSetupTypes = (
  testSetupTypesData: Omit<ITestSetup, 'id'>,
  projectId: number,
): Promise<ITestSetup> => {
  return request(
    `projects/${projectId}/test_setup_types`,
    'POST',
    testSetupTypesData,
  )
}

export const updateTestSetupTypes = (
  testSetupTypes: NullablePartial<ITestSetup>,
  projectId: number,
): Promise<ITestSetup> => {
  return request(
    `projects/${projectId}/test_setup_types/${testSetupTypes.id}`,
    'PUT',
    testSetupTypes,
  )
}

export const getProjectTestSetupTypes = (
  projectId: number,
): Promise<ITestSetup[]> => {
  return request(`projects/${projectId}/test_setup_types`, 'GET')
}

export const deleteTestSetupTypes = (testSetupTypes: ITestSetup) => {
  return request(`/test_setup_types/${testSetupTypes.id}`, 'DELETE', {}, true)
}

export const deleteBulkTestCases = (
  projectId: number,
  ids: number[],
): Promise<any> => {
  return request(
    `projects/${projectId}/test_cases/remove_multiple`,
    'DELETE',
    {
      ids: ids,
    },
    true,
  )
}

export const deleteBulkTestCaseGroups = (
  projectId: number,
  ids: number[],
): Promise<any> => {
  return request(
    `projects/${projectId}/test_case_groups/remove_multiple`,
    'DELETE',
    {
      ids: ids,
    },
    true,
  )
}
