import Attachment from '@icons/attachment.svg'
import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AttachmentsInspectorSectionTableWithUpload from 'src/components/inspector-section-tables/AttachmentsInspectorSectionTableWithUpload'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import { IMetaValue } from 'src/document/types/IMetaData'
import { useTaskDetailPageProp } from 'src/hooks/useTaskDetailPageProp'
import { getAllProjectDeliveries } from 'src/service/DeliveryService'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import {
  IConstructionWagon,
  IControlArea,
  IImprovement,
  ITask,
  ITaskData,
} from 'src/service/OrgTypes'
import { getProjectProcesses } from 'src/service/ProcessService'
import { getProjectRiskList } from 'src/service/RiskService'
import { getProjectRooms } from 'src/service/RoomService'
import { getSystems } from 'src/service/SystemService'
import { actionStatus } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { editTask, getProjectTaskTypes, getTask } from 'src/service/TaskService'
import { getProjectTestCases } from 'src/service/TestService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import { getErrorMessage, ValidationError } from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter, classNames } from 'src/utility/utils'
import InspectorSectionTable from '../../document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from '../../document/components/Inspector/InspectorSections'
import history from '../../history'
import ChangeLog from '../changelog/Changelog'
import Comments from '../comment/Comments'
import CopyUrl from '../copy/CopyUrl'
import ImprovementPanel from '../improvement/improvement-panel/ImprovementPanel'
import QrModal from '../qr/QrModal'
import RiskInspectorPanel from '../risk/RiskInspectorPanel'
import { getMetaDataValues, loadMetaValues } from '../system/SystemUtil'

interface ITaskInspectorPanel {
  taskId: number
  open: boolean
  onClose: () => void
  projectId: number
  hideSystem?: boolean
  notFound?: (id: number) => void
  onUpdate?: (t: ITask) => void
  defaultIndex?: number
  cascaded?: boolean
  readonly?: boolean
  module?: 'planning'
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
}

const TaskInspectorPanel: React.FC<ITaskInspectorPanel> = ({
  taskId,
  open,
  onClose,
  projectId,
  hideSystem,
  notFound,
  onUpdate,
  defaultIndex = 0,
  cascaded,
  readonly,
  module,
}) => {
  const { t } = useTranslation()
  const [taskData, setTaskData] = useState<ITaskData>({} as ITaskData)
  const [loading, setLoading] = useState<boolean>(true)
  const [ControlAreas, setControlAreas] = useState<IControlArea[]>([])
  const [wagons, setWagons] = useState<IConstructionWagon[]>([])
  const projectContext = useContext(ProjectContext)
  const [projectEndDate, setProjectEndDate] = useState<moment.Moment>(moment())
  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<ITaskData>(setTaskData, taskData)
  const [improvements, setImprovments] = useState<IImprovement[]>([])
  const [qrString, setQrString] = useState<string>('')
  const [showQrModal, setQrModal] = useState<boolean>(false)
  const [showImprovementPanel, setShowImprovementPanel] =
    useState<boolean>(false)
  const [openRiskInspectionPanel, setOpenRiskInspectionPanel] =
    useState<boolean>(false)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const { currentProject } = projectContext.state

  const loadTask = () => {
    setLoading(true)
    getTask(projectId, taskId).then((res) => {
      if (res.status === 404 && notFound) {
        notFound(taskId)
      }
      setTaskData(res)
      setProjectEndDate(moment(currentProject.endDate))
      setControlAreas(res.control_area ? [res.control_area] : [])
      setWagons(
        res.construction_locomotive ? [res.construction_locomotive] : [],
      )

      setImprovments(res.improvement ? [res.improvement] : [])
      setLoading(false)

      const metaData = getMetaDataValues(res.meta_data)
      setOptionalFields(
        loadMetaValues(
          taskId,
          'Task',
          res.task_type?.optional_fields ?? [],
          metaData,
        ),
      )
      setLoading(false)
    })
  }

  useEffect(() => {
    loadTask()
  }, [taskId, projectId])

  const onStatusSelect = () => {
    const updatedtask = { ...taskData, status: 'done' }
    editTask(updatedtask).then((t) => {
      loadTask()
      onUpdate?.(t)
    })
  }

  const parentIsMeeting = (): boolean => {
    return taskData.parent && taskData.parent.meeting_series_id
  }

  const isEndTimeValid = (dealineVal: moment.Moment) => {
    const parent = taskData.parent

    if (taskData && (!dealineVal || !moment(dealineVal).isValid())) {
      return t('select_w_param', { param: t('deadline') })
    }

    const improvement = improvements.find(
      (u) => u.id === taskData.improvement_id,
    )
    if (improvement) {
      if (moment(dealineVal).isAfter(moment(improvement.deadline), 'day')) {
        return (
          t(
            'deadline_for_the_task_must_be_before_the_deadline_for_the_corresponding_improvement',
          ) +
          ' (' +
          moment(improvement.deadline).format('L') +
          ')'
        )
      }
    }
    if (
      parent &&
      dealineVal &&
      ![
        'CheckInParameter',
        'ChecklistParameter',
        'ConstructionLocomotive',
        'ControlArea',
      ].includes(parent)
    ) {
      const endTime = parent === 'Risk' ? parent.deadline : parent.endTime
      const message =
        parent === 'Risk'
          ? t('deadline_must_be_within_the_risk_deadline') + ' ('
          : t('deadline_must_be_within_the_delivery_deadline') + ' ('
      if (endTime && moment(dealineVal).isAfter(moment(endTime), 'day')) {
        return message + moment(endTime).format('L') + ')'
      }

      if (
        taskData &&
        parent === 'Project' &&
        taskData.parent_id === projectId
      ) {
        if (moment(dealineVal).isAfter(moment(projectEndDate), 'day')) {
          return (
            t('deadline_must_be_within_the_project_deadline') +
            ' (' +
            moment(projectEndDate).format('L') +
            ')'
          )
        }
      }
    }
    return
  }

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4 -mt-2'}>
      {optionalFields.length > 0 && (
        <MetaDataFieldsForPage
          onFieldsUpdate={() => onTaskUpdate(taskData as ITask)}
          fields={optionalFields}
          required={false}
          editMode={false}
          inspectorPanel={true}
        />
      )}
    </div>
  )

  const getRows = (): IInspectorSection[] => [
    {
      name: t('additional_information'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      hidden: optionalFields?.length === 0,
      content: getMetaDataContent(),
      overflowVisible: true,
    },
    {
      name: capFirstLetter(t('attachments')),
      icon: <Attachment />,
      content: (
        <AttachmentsInspectorSectionTableWithUpload
          parentId={taskId}
          parentType="Task"
          readonly={false}
        />
      ),
    },
    {
      name: t('related_tasks_original'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('title'),
            t('status'),
            t('deadline'),
            t('task_type'),
          ]}
          rowsData={
            taskData?.related_tasks
              ? taskData?.related_tasks.map((issue) => {
                  return {
                    cells: [
                      issue?.record_id ?? '',
                      issue.title,
                      t(`${issue.status}`),
                      moment(issue.deadline).format('L'),
                      t(`${issue?.task_type?.taskTypeName ?? ''}`),
                    ],
                    id: issue.id,
                  }
                })
              : []
          }
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: (
        <Comments parentType="Task" parentId={taskId} readonly={readonly} />
      ),
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentType="Task" parentId={taskId} />,
    },
  ]

  const onTaskUpdate = (t: ITask) => {
    loadTask()
    onUpdate?.(t)
  }

  const onChangeInput = (update: Partial<ITaskData>) => {
    if (taskData?.id && projectId) {
      const allUpdates = getChangesForUpdate(update)
      setTaskData({ ...taskData, ...allUpdates })
      editTask({
        ...convertUndefinedToNull(allUpdates),
        id: taskId,
        project_id: projectId,
      }).then((t) => {
        onTaskUpdate(t)
      })
    }
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const copyURL =
      url + '?modal=task&id=' + taskData.id + '&project=' + projectId
    setQrString(copyURL)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setQrModal((n) => !n)
  }

  const extraButtons = (): JSX.Element => {
    return (
      <div className="flex flex-row justify-end items-center">
        {taskData.status && taskData.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}
        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <CopyUrl
            params={{
              modal: 'task',
              id: taskData.id,
            }}
          />
        </span>
        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <a title={t('generate_qr_code')} className="flex items-center">
            <Icon
              style={{ width: 20, height: 20 }}
              icon={Icon.IconType.QR}
              onClick={toggleQrModal}
            />
          </a>
        </span>
        {parent === 'Risk' ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={() => setOpenRiskInspectionPanel(true)}
          >
            {t('see_risk')}
          </Button>
        ) : null}
        {false && parentIsMeeting() ? (
          <Button size={Button.ButtonSize.SMALL} onClick={gotoMeeting}>
            {t('see_meeting')}
          </Button>
        ) : null}
        {taskData.improvement_id ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={toggleImprovementPanel}
          >
            {t('see_improvement_measures')}
          </Button>
        ) : null}
      </div>
    )
  }

  const gotoMeeting = () => {
    if (taskData.parent) {
      const meetingID = taskData.parent.meeting_id
      const meetingSeriesId = taskData.parent.meeting_series_id
      if (meetingID && meetingSeriesId) {
        history.push(`/meeting-series/${meetingSeriesId}/meetings/${meetingID}`)
      } else if (meetingSeriesId) {
        history.push(`/meeting-series/${meetingSeriesId}`)
      }
    }
  }

  const toggleImprovementPanel = () => {
    setShowImprovementPanel((v) => !v)
  }

  const parent = useMemo(() => {
    return taskData?.parent_type ?? ''
  }, [taskData])

  const taskDetailPageProp = useTaskDetailPageProp(taskData, module)

  return (
    <FixedPane
      title={
        taskData?.title
          ? `${taskData?.record_id} - ${taskData?.title}`
          : t('loading...')
      }
      show={open}
      onClose={() => onClose()}
      className={'w-[600px]'}
      cascaded={cascaded}
      detailPageData={taskDetailPageProp}
    >
      {taskData.id ? (
        <div className={styleClass.root}>
          {extraButtons()}
          <InlineComponentsWrapper loading={loading} padding="left">
            <TextInlineInputCompontent
              label={t('title')}
              value={taskData?.title ?? ''}
              onValueSubmitted={(newValue) => {
                if (newValue) onChangeInput({ title: newValue })
              }}
              validate={(value) => {
                if (value === undefined || value === '') {
                  return getErrorMessage(ValidationError.MISSING_TITLE, t)
                }
                return
              }}
            />

            <SelectorInlineInputComponent
              items={actionStatus(t)}
              label={t('status')}
              getItemLabel={(stat) => stat?.name}
              initialItem={{
                id: taskData?.status ?? '',
                name:
                  actionStatus(t).find(
                    (statData) => statData.id === taskData?.status,
                  )?.name ?? '',
              }}
              validate={() => {
                if (
                  taskData.parent &&
                  taskData.parent.status &&
                  (parent === 'Risk' || parent === 'Delivery')
                ) {
                  if (
                    taskData.parent.status === 'done' &&
                    taskData.status !== 'done'
                  ) {
                    const message =
                      parent === 'Risk'
                        ? t('associated_risk_is_closed')
                        : t('associated_delivery_is_closed')
                    return message
                  }
                }
                return
              }}
              selectedId={taskData?.status}
              onValueSubmitted={(stat) => {
                onChangeInput({ status: stat })
              }}
              inspectorPanel={true}
            />

            <DateTimeInlineInputComponent
              label={t('deadline')}
              selectedTime={`${taskData?.deadline}`}
              onValueSubmitted={(deadlineValue) => {
                onChangeInput({ deadline: moment(deadlineValue) })
              }}
              validate={(value) => {
                if (value) {
                  return isEndTimeValid(value)
                }
                return
              }}
              inspectorPanel={true}
            />

            <TextInlineInputCompontent
              label={t('duration_days')}
              value={`${taskData?.duration ?? 0}`}
              onValueSubmitted={(newValue) => {
                if (newValue)
                  onChangeInput({ duration: parseInt(`${newValue}`, 10) })
              }}
              validate={(newValue) => {
                if (newValue?.length && isNaN(+newValue)) {
                  return t('must_be_a_number')
                }
                if (newValue && +newValue < 0) {
                  return getErrorMessage(ValidationError.NEGATIVE_DURATION, t)
                }
                return undefined
              }}
            />

            <SelectorInlineInputComponent
              getItems={() => getProjectDisciplines(projectId)}
              label="discipline"
              initialItem={taskData?.discipline}
              getItemLabel={(discipline) =>
                `${discipline?.shortName} - ${discipline?.name}`
              }
              validate={(value) => {
                if (value === undefined)
                  return t('fill_out_w_param', {
                    param: t('discipline'),
                  })
                return
              }}
              selectedId={taskData?.discipline_id}
              onValueSubmitted={(discipline_id) => {
                addChangesToAppendWhenKeyUpdates('responsible_id', {
                  discipline_id,
                })
              }}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() =>
                taskData?.discipline_id
                  ? getDisplineUsers(taskData?.discipline_id)
                  : getProjectUsersWithDisciplines(projectId)
              }
              label="responsible"
              getItemLabel={(responsible) =>
                `${responsible?.firstName} ${responsible?.lastName}`
              }
              initialItem={taskData?.responsible}
              validate={(value) => {
                if (value === undefined)
                  return t('fill_out_w_param', {
                    param: t('responsible'),
                  })
                return
              }}
              selectedId={taskData?.responsible_id ?? 0}
              onValueSubmitted={(responsible_id) => {
                onChangeInput({ responsible_id })
              }}
              dependencies={[taskData?.discipline_id]}
              inspectorPanel={true}
            />
            <SelectorInlineInputComponent
              label={'contract'}
              disabled={true}
              selectedId={taskData?.contract_id ?? ''}
              getItemLabel={(contract) =>
                `${contract?.contractNumber} - ${contract?.contractName}`
              }
              initialItem={taskData?.contract}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() => getProjectProcesses(projectId)}
              label={t('main_process')}
              getItemLabel={(process) =>
                `${process?.record_id} - ${process?.name}`
              }
              initialItem={taskData?.main_process}
              selectedId={taskData?.main_process_id}
              onValueSubmitted={(val) => {
                onChangeInput({ main_process_id: val })
              }}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() => getProjectTags(projectId)}
              label={t('type')}
              getItemLabel={(tag) => tag?.name}
              initialItem={taskData?.tag}
              selectedId={taskData?.tag_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ tag_id: val })
              }}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() => getProjectTaskTypes(projectId)}
              label={t('task_type')}
              getItemLabel={(taskType) => taskType?.taskTypeName}
              initialItem={taskData?.task_type}
              selectedId={taskData?.task_type_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ task_type_id: val })
              }}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() =>
                getFilteredProjectImprovementsWithPagination(projectId).then(
                  (res) => res.improvements,
                )
              }
              label={t('improvement')}
              getItemLabel={(improvement) =>
                `${improvement?.record_id} - ${improvement?.title}`
              }
              initialItem={taskData?.improvement}
              selectedId={taskData?.improvement_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ improvement_id: val })
              }}
              inspectorPanel={true}
            />

            {parent === 'Delivery' && (
              <SelectorInlineInputComponent
                getItems={() => getAllProjectDeliveries(projectId)}
                label={t('delivery')}
                getItemLabel={(parent) =>
                  `${parent?.record_id} - ${parent?.name}`
                }
                initialItem={taskData?.parent}
                selectedId={taskData?.parent_id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ parent_id: val })
                }}
                inspectorPanel={true}
              />
            )}

            {parent === 'Topic' && (
              <SelectorInlineInputComponent
                getItems={() => getAllProjectDeliveries(projectId)}
                label={t('delivery')}
                getItemLabel={(delivery) =>
                  delivery ? `${delivery?.record_id} - ${delivery?.name}` : ''
                }
                initialItem={taskData.delivery}
                selectedId={taskData.delivery?.id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ delivery_id: val })
                }}
                cancelButton={true}
                inspectorPanel={true}
              />
            )}

            {parent === 'Risk' && (
              <SelectorInlineInputComponent
                getItems={() => getProjectRiskList(projectId)}
                label={t('risk')}
                getItemLabel={(item) => `${item?.record_id} - ${item?.name}`}
                initialItem={taskData?.parent}
                selectedId={taskData?.parent_id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ parent_id: val })
                }}
                disabled={true}
                inspectorPanel={true}
              />
            )}

            {parent === 'TestCase' && (
              <SelectorInlineInputComponent
                getItems={() => getProjectTestCases(projectId, 1, {}, 50)}
                label={t('test_case')}
                getItemLabel={(item) => item.name}
                initialItem={taskData?.parent}
                selectedId={taskData?.parent_id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ parent_id: val })
                }}
                disabled={true}
                inspectorPanel={true}
              />
            )}

            {taskData.control_area_id && taskData?.control_area ? (
              <SelectorInlineInputComponent
                items={ControlAreas}
                label={t('control_area')}
                getItemLabel={(controlArea) =>
                  `${controlArea?.record_id ?? ''} - ${controlArea?.title}`
                }
                initialItem={taskData?.control_area}
                selectedId={taskData?.control_area_id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ control_area_id: val })
                }}
                disabled={true}
                inspectorPanel={true}
              />
            ) : null}

            {taskData.construction_locomotive_id ? (
              <SelectorInlineInputComponent
                items={wagons}
                label={t('wagon')}
                getItemLabel={(locomotive) =>
                  `${locomotive?.record_id ?? ''} - ${locomotive?.title}`
                }
                initialItem={taskData?.construction_locomotive}
                selectedId={taskData?.construction_locomotive_id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ construction_locomotive_id: val })
                }}
                disabled={true}
                inspectorPanel={true}
              />
            ) : null}

            <TextInlineInputCompontent
              label={t('description')}
              value={taskData?.description ?? ''}
              onValueSubmitted={(newValue) => {
                if (newValue) onChangeInput({ description: newValue })
              }}
              textArea={true}
            />

            {taskData.taskType === 'Aksjon' && !hideSystem && (
              <SelectorInlineInputComponent
                getItems={() => getSystems(projectId)}
                label={t('system')}
                getItemLabel={(system) =>
                  `${system?.record_id} ${system?.name}`
                }
                initialItem={taskData?.system}
                selectedId={taskData?.system_id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ system_id: val })
                }}
                inspectorPanel={true}
              />
            )}
            {taskData.taskType === 'Aksjon' && !hideSystem && (
              <SelectorInlineInputComponent
                getItems={() => getProjectRooms(projectId)}
                label={t('room')}
                getItemLabel={(room) =>
                  `${room?.room_name} ${room?.functional_room_number}`
                }
                initialItem={taskData?.location}
                selectedId={taskData?.location_id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ location_id: val })
                }}
                inspectorPanel={true}
              />
            )}
            <TextInlineInputCompontent
              label={t('reporter')}
              value={`${taskData?.reporter?.firstName} ${taskData?.reporter?.lastName}`}
              disabled={true}
            />
            <DateTimeInlineInputComponent
              label="created_at"
              selectedTime={taskData?.created_at}
              onValueSubmitted={() => {}}
              disabled={true}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label="updated_at"
              selectedTime={taskData?.updated_at}
              onValueSubmitted={() => {}}
              disabled={true}
              inspectorPanel={true}
            />
          </InlineComponentsWrapper>
          <InspectorSections defaultIndex={defaultIndex} sections={getRows()} />
          {openRiskInspectionPanel &&
            parent === 'Risk' &&
            taskData.parent_id && (
              <RiskInspectorPanel
                riskId={taskData.parent_id}
                open={openRiskInspectionPanel}
                onClose={() => setOpenRiskInspectionPanel(false)}
                onUpdate={() => loadTask()}
              />
            )}

          {showQrModal && (
            <QrModal
              show={showQrModal}
              close={toggleQrModal}
              title={`${taskData?.record_id} - ${taskData?.title}`}
              value={qrString}
            />
          )}

          {showImprovementPanel && taskData.improvement_id && (
            <ImprovementPanel
              projectId={projectId}
              improvementId={taskData.improvement_id}
              onClose={toggleImprovementPanel}
              show={showImprovementPanel}
            />
          )}
        </div>
      ) : (
        <span />
      )}
    </FixedPane>
  )
}
export default TaskInspectorPanel
