import { getProjectTrainTypes } from 'src/service/TrainTypeService'
import { getAllProjectWagonTypes } from 'src/service/WagonTypeService'
import { concatinateValuesForTable } from 'src/utility/Utility'
import DocumentTypeService from '../document/services/DocumentTypeService'
import FolderService from '../document/services/FolderService'
import { IDocumentType } from '../document/types/IFlow'
import { ISingleFilter } from '../ui-elements/list/ListContextProvider'
import { getProjectConstructionTrains } from './ConstructionTrainService'
import { getProjectConstructionWagons } from './ConstructionWagonService'
import { getProjectContracts } from './ContractService'
import {
  getProjectControlAreaGroups,
  getProjectControlAreas,
} from './ControlAreaService'
import { getAllProjectDeliveries } from './DeliveryService'
import { getProjectDisciplines } from './DisciplineService'
import { getFilteredProjectImprovementsWithPagination } from './ImprovementsService'
import { getProjectMilestones } from './MilestoneService'
import { ISystemFMI, ISystemFMIGroup, ITaskType, ITrainType } from './OrgTypes'
import {
  getProjectKeypoints,
  getProjectPhases,
  getProjectProcesses,
  getProjectTheams,
} from './ProcessService'
import {
  getProjectRiskGroups,
  getProjectRiskList,
  getRiskAreas,
  getRiskGroupRiskList,
} from './RiskService'
import { getProjectRoles } from './RoleService'
import { getProjectRooms } from './RoomService'
import { getProjectFloors, getProjectSections } from './SectionService'
import {
  getProjectSystemFMIGroups,
  getProjectSystemFMIsOnly,
  getProjectSystemMMIsOnly,
  getProjectSystemTypes,
  getProjectSystemTypesOnly,
  getSystems,
  getSystemsTypeUniques,
} from './SystemService'
import { getStatusesOnlyForType } from './SystemStatusService'
import { getProjectSystemTypeGroup } from './SystemTypeGroupService'
import { getProjectTags } from './TagService'
import { getProjectTaskTypes } from './TaskService'
import { getAllProjectTests, getAllProjectTestTypes } from './TestService'
import { getProjectTestSystemGroupOnly } from './TestSystemGroupService'
import { getProjectTestWorkGroupOnly } from './TestWorkGroupService'
import { getProjectUsers } from './UserService'

export const userFilterServices = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const users = await getProjectUsers(projectId)
  return users.map((user) => {
    return Object.assign(
      {},
      {
        value: user.id.toString(),
        name: `${user.firstName} ${user.lastName}`,
        active: false,
      },
    )
  })
}

export const disciplineFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const disciplines = await getProjectDisciplines(projectId)
  return disciplines.map((discipline) => {
    return Object.assign(
      {},
      {
        value: discipline.id.toString(),
        name: concatinateValuesForTable(discipline.shortName, discipline.name),
        active: false,
      },
    )
  })
}

export const roleFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const roles = await getProjectRoles(projectId)
  return roles.map((role) => {
    return Object.assign(
      {},
      {
        value: role.id.toString(),
        name: role.roleName,
        active: false,
      },
    )
  })
}

export const contractFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const contracts = await getProjectContracts(projectId)
  return contracts.map((contract) => {
    return Object.assign(
      {},
      {
        value: contract.id.toString(),
        name: concatinateValuesForTable(
          contract.contractNumber,
          contract.contractName,
        ),
        active: false,
      },
    )
  })
}

export const processFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const processes = await getProjectProcesses(projectId)
  return processes.map((process: any) => {
    return Object.assign(
      {},
      {
        value: process.id.toString(),
        name: process.name,
        active: false,
      },
    )
  })
}

export const theamFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const theams = await getProjectTheams(projectId)
  return theams.map((theam: any) => {
    return Object.assign(
      {},
      {
        value: theam.id.toString(),
        name: theam.name,
        active: false,
      },
    )
  })
}

export const milestoneFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const milestones = await getProjectMilestones(projectId)
  return milestones.map((milestone: any) => {
    return Object.assign(
      {},
      {
        value: milestone.id.toString(),
        name:
          milestone.name && milestone.name.length > 20
            ? `${milestone.name.substring(0, 20)}...`
            : milestone.name,
        active: false,
      },
    )
  })
}

export const phaseFilterSercice = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const phases = await getProjectPhases(projectId)
  return phases.map((phase: any) => {
    return Object.assign(
      {},
      {
        value: phase.id.toString(),
        name: phase.phaseName,
        active: false,
      },
    )
  })
}

export const keypointFilterSercice = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const keypoints = await getProjectKeypoints(projectId)
  return keypoints.map((keypoint: any) => {
    return Object.assign(
      {},
      {
        value: keypoint.id.toString(),
        name: keypoint.name,
        active: false,
      },
    )
  })
}

export const deliveryFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const deliverys = await getAllProjectDeliveries(projectId)
  return deliverys.map((delivery: any) => {
    return Object.assign(
      {},
      {
        value: delivery.id.toString(),
        name: delivery.name,
        active: false,
      },
    )
  })
}

export const roomFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const rooms = await getProjectRooms(projectId)
  return rooms.map((room: any) => {
    return Object.assign(
      {},
      {
        value: room.id.toString(),
        name: room.room_name,
        active: false,
      },
    )
  })
}

export const documentTypeFilterService = async (): Promise<ISingleFilter[]> => {
  const documentTypes = await DocumentTypeService.getProjectDocumentTypes()
  return documentTypes.map((documentType: IDocumentType) => {
    return Object.assign(
      {},
      {
        value: documentType.id.toString(),
        name: documentType.name,
        active: false,
      },
    )
  })
}

export const systemFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const systems = await getSystems(projectId)
  return systems.map((system: any) => {
    return Object.assign(
      {},
      {
        value: system.id.toString(),
        name: `${system.record_id} - ${system.name}`,
        active: false,
      },
    )
  })
}

export const systemMmiFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const systemMmi = await getProjectSystemMMIsOnly(projectId)
  return systemMmi.map((testType: any) => {
    return Object.assign(
      {},
      {
        value: testType.id.toString(),
        name: testType.name,
        active: false,
      },
    )
  })
}

export const systemFmiFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const systemFmi = await getProjectSystemFMIsOnly(projectId)
  return systemFmi.map((systemFmi: ISystemFMI) => ({
    value: systemFmi.value.toString(),
    name: systemFmi.value.toString(),
    active: false,
  }))
}

export const systemFmiGroupFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const systemFmiGroup = await getProjectSystemFMIGroups(projectId)
  return systemFmiGroup.map((systemFmiGroup: ISystemFMIGroup) => ({
    value: systemFmiGroup.id.toString(),
    name: systemFmiGroup.name,
    active: false,
  }))
}
export const systemTypeFilterService = async (
  projectId: number,
  systemSettingId?: number,
): Promise<ISingleFilter[]> => {
  const systemTypes = systemSettingId
    ? await getProjectSystemTypesOnly(projectId, systemSettingId)
    : await getProjectSystemTypes(projectId)
  return systemTypes.map((systemType: any) => {
    return Object.assign(
      {},
      {
        value: systemType.id.toString(),
        name: systemType.type_code + ' ' + systemType.name,
        active: false,
      },
    )
  })
}

export const systemTypeUniqueFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const systemTypeUniques = await getSystemsTypeUniques(projectId)
  return systemTypeUniques.map((systemTypeUnique: any) => {
    return Object.assign(
      {},
      {
        value: systemTypeUnique.id.toString(),
        name: systemTypeUnique.type_code + ' ' + systemTypeUnique.name,
        active: false,
      },
    )
  })
}

export const systemTypeGriupFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const systemTypeGroups = await getProjectSystemTypeGroup(projectId)
  return systemTypeGroups.map((systemTypeGroup: any) => {
    return Object.assign(
      {},
      {
        value: systemTypeGroup.id.toString(),
        name: systemTypeGroup.name,
        active: false,
      },
    )
  })
}

export const folderFilterService = async (
  _projectId: number,
): Promise<ISingleFilter[]> => {
  const folders = await FolderService.getFolders()
  return folders.map((folder: any) => {
    return Object.assign(
      {},
      {
        value: folder.id.toString(),
        name: folder.name,
        active: false,
      },
    )
  })
}

export const statusFilterService = async (
  projectId: number,
  parentType: string,
): Promise<ISingleFilter[]> => {
  const systemStatus = await getStatusesOnlyForType(projectId, parentType)
  return systemStatus.map((testType: any) => {
    return Object.assign(
      {},
      {
        value: testType.id.toString(),
        name: testType.name,
        active: false,
      },
    )
  })
}

export const testSystemGroupFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const testSystemGroup = await getProjectTestSystemGroupOnly(projectId)
  return testSystemGroup.map((testType: any) => {
    return Object.assign(
      {},
      {
        value: testType.id.toString(),
        name: testType.record_id,
        active: false,
      },
    )
  })
}

export const testWorkGroupFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const testWorkGroup = await getProjectTestWorkGroupOnly(projectId)
  return testWorkGroup.map((testType: any) => {
    return Object.assign(
      {},
      {
        value: testType.id.toString(),
        name: testType.record_id,
        active: false,
      },
    )
  })
}

export const testTypeFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const testTypes = await getAllProjectTestTypes(projectId)
  return testTypes.map((testType: any) => {
    return Object.assign(
      {},
      {
        value: testType.id.toString(),
        name: testType.name,
        active: false,
      },
    )
  })
}

export const testFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const tests = await getAllProjectTests(projectId)
  return tests.map((test: any) => {
    return Object.assign(
      {},
      {
        value: test.id.toString(),
        name: test.name,
        active: false,
      },
    )
  })
}

export const riskGroupFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const riskGroups = await getProjectRiskGroups(projectId)
  return riskGroups.map((riskGroup: any) => {
    return Object.assign(
      {},
      {
        value: riskGroup.id.toString(),
        name: riskGroup.title,
        active: false,
      },
    )
  })
}

export const riskFilterService = async (
  projectId: number,
  riskGroupId?: number,
): Promise<ISingleFilter[]> => {
  const risks = riskGroupId
    ? await getRiskGroupRiskList(projectId, riskGroupId)
    : await getProjectRiskList(projectId)
  return risks.map((risk: any) => {
    return Object.assign(
      {},
      {
        value: risk.id.toString(),
        name: concatinateValuesForTable(risk.record_id, risk.title),
        active: false,
      },
    )
  })
}

export const riskAreaFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const riskAreas = await getRiskAreas(projectId)
  return riskAreas.map((riskArea: any) => {
    return Object.assign(
      {},
      {
        value: riskArea.id.toString(),
        name: riskArea.name,
        active: false,
      },
    )
  })
}

export const sectionsFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const sections = await getProjectSections(projectId)
  return sections.map((section: any) => {
    return Object.assign(
      {},
      {
        value: section.id.toString(),
        name: section.name,
        active: false,
      },
    )
  })
}

export const floorsFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const floors = await getProjectFloors(projectId)
  return floors.map((floor: any) => {
    return Object.assign(
      {},
      {
        value: floor.id.toString(),
        name: floor.name,
        active: false,
      },
    )
  })
}

export const trainsFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const trains = await getProjectConstructionTrains(projectId)
  return trains.map((train: any) => {
    return Object.assign(
      {},
      {
        value: train.id.toString(),
        name: train.title,
        active: false,
      },
    )
  })
}

export const trainTypesFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const trains = await getProjectTrainTypes(projectId)
  return trains.map((train: ITrainType) => {
    return Object.assign(
      {},
      {
        value: train.id.toString(),
        name: train.title,
        active: false,
      },
    )
  })
}

export const wagonsFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const wagons = await getProjectConstructionWagons(projectId)
  return wagons.map((wagon: any) => {
    return Object.assign(
      {},
      {
        value: wagon.id.toString(),
        name: wagon.title,
        active: false,
      },
    )
  })
}

export const wagonTypesFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const wagons = await getAllProjectWagonTypes(projectId)
  return wagons.map((wagon: any) => {
    return Object.assign(
      {},
      {
        value: wagon.id.toString(),
        name: wagon.title,
        active: false,
      },
    )
  })
}

export const tagsFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const tags = await getProjectTags(projectId)
  return tags.map((tag: any) => {
    return Object.assign(
      {},
      {
        value: tag.id.toString(),
        name: tag.name,
        active: false,
      },
    )
  })
}

export const taskTypesFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const taskTypes = await getProjectTaskTypes(projectId)
  return taskTypes.map((taskType: ITaskType) => {
    return Object.assign(
      {},
      {
        value: taskType.id.toString(),
        name: taskType.taskTypeName,
        active: false,
      },
    )
  })
}

export const controlAreaGroupFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const controlAreaGroups = await getProjectControlAreaGroups(projectId)
  return controlAreaGroups.map((controlAreaGroup: any) => {
    return Object.assign(
      {},
      {
        value: controlAreaGroup.id.toString(),
        name: controlAreaGroup.title,
        active: false,
      },
    )
  })
}

export const controlAreaFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const controlAreas = await getProjectControlAreas(projectId)
  return controlAreas.map((controlArea: any) => {
    return Object.assign(
      {},
      {
        value: controlArea.id.toString(),
        name: controlArea.title,
        active: false,
      },
    )
  })
}

export const improvementFilterService = async (
  projectId: number,
): Promise<ISingleFilter[]> => {
  const improvements = await getFilteredProjectImprovementsWithPagination(
    projectId,
  ).then((res) => res.improvements)
  return improvements.map((improvement: any) => {
    return Object.assign(
      {},
      {
        value: improvement.id.toString(),
        name: improvement.title,
        active: false,
      },
    )
  })
}
